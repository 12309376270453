<div fxFlex="500px" fxLayout="column" fxLayoutAlign="start" class="side-nav">
    <div class="header" fxLayout="row" fxFlex="auto" fxLayoutAlign="space-between center" fxLayoutGap="30px">
        <div>Create Project</div>
        <div (click)="close()" class="cp"><img src="../../../assets/image/svg/close.svg" alt="" width="12px"></div>
    </div>
    <div class="body custom-scroll-thin" fxLayout="column" fxLayoutGap="15px">
        <form [formGroup]="projectCreateForm" fxLayout="column" fxLayoutGap="15px">
            <!-- <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center center">
                <img class="cp" src="../../../../assets/image/svg/create-project-logo.svg" width="60px">
                <div class="title">Project Logo</div>
            </div> -->

            
            <div fxLayout="row" fxLayoutGap="16px">
                <div fxLayout="column" fxLayoutAlign="start start" fxFlex="" fxLayoutGap="8px">
                    <div class="title">Client Name</div>                   
                    <!-- <mat-form-field appearance="outline">
                        <input formControlName="title" placeholder="Client Name" matInput>
                    </mat-form-field> -->
                    <div [ngClass]="{'border-error': projectCreateForm.get('client_id').touched && projectCreateForm.get('client_id').invalid}" class="client-dropdown">
                        <ng-select [items]="clientList | sortBy:'updated_at':'desc'" bindLabel="name" bindValue="id" placeholder="Select Client" formControlName="client_id" ></ng-select>
                    
                    </div>
                    
                </div>
            </div>

            <div fxLayout="row" fxLayoutGap="16px">
                <div fxLayout="column" fxLayoutAlign="start start" fxFlex="" fxLayoutGap="8px">
                    <div class="title">Project Name</div>
                    <!-- <mat-form-field appearance="outline">
                        <mat-select formControlName="project_name" placeholder="Select Status">
                            <mat-option>1</mat-option>
                            <mat-option>2</mat-option>
                            <mat-option>3</mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    <mat-form-field appearance="outline">
                        <input formControlName="title" placeholder="Project Name" matInput>
                    </mat-form-field>
                    <div *ngIf="errorMessage" class="error-message">{{errorMessage}}</div>
                </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="start start" fxFlex="auto" fxLayoutGap="8px">
                <div class="title">Description</div>
                <textarea formControlName="description" placeholder="Optional" [maxlength]="formValidation.max_length.description"></textarea>
                <!-- <div class="msg-error">
                    Maximum allowed limit is 250 character.
                </div> -->
            </div>
            <!-- <div fxLayout="row" fxLayoutGap="16px">
                <div fxLayout="column" fxLayoutAlign="start start" fxFlex="" fxLayoutGap="8px">
                    <div class="title">Members</div>
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="members" placeholder="Select Status">
                            <mat-option>1</mat-option>
                            <mat-option>2</mat-option>
                            <mat-option>3</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div> -->



        </form>
        <div fxLayout="column" fxLayoutAlign="start start" fxFlex="auto" fxLayoutGap="8px" class="single-row">
            <div fxLayout="row" fxFlex="auto" fxLayoutAlign="end center" class="single-row" fxLayoutGap="8px">
                <a class="button" fxLayout="column" fxFlex="100px" fxLayoutAlign="center center" (click)="cancel()"> CANCEL </a>
                <a class="button fill" fxLayout="column" fxFlex="100px" fxLayoutAlign="center center" (click)="createProject()"> ADD </a>

            </div>

        </div>


    </div>
</div>
<!-- <ah-spinner *ngIf="requestInProgress" [config]="{fullscreen: true}"></ah-spinner> -->