
<div class="date-range-picker" *ngIf="config && !showPicker">
  <input
  type="text"
  ngxDaterangepickerMd
  [(ngModel)]="selectedDate"
  [showCustomRangeLabel]="true"
  [alwaysShowCalendars]="true"
  [ranges]="ranges"
  [showClearButton]="config.showClearButton"
  [showCancel]="config.showCancelButton"
  [linkedCalendars]="true"
  [isTooltipDate]="isTooltipDate"
  [isInvalidDate]="isInvalidDate"
  [showDropdowns]="false"
  [locale]="{ applyLabel: config.applyButtonText, format: 'DD-MM-YYYY'}"
  [keepCalendarOpeningWithRange]="true"
  [showRangeLabelOnInput]="true"
  [autoApply]="config.showApplyButton"
  class="form-control"
  opens="right"
  (datesUpdated)="datesUpdated($event)"
  placeholder="Select please..." />
</div>

<ng-container *ngIf="showPicker">
  <!-- <ngx-daterangepicker-material [isTooltipDate]="isTooltipDate"
  [showRangeLabelOnInput]="true" [showCustomRangeLabel]="true" [startDate]="selectedDate?.startDate"
  [endDate]="selectedDate?.endDate" [autoApply]="false" [locale]="{ applyLabel: config.applyButtonText, format: 'DD-MM-YYYY'}" [alwaysShowCalendars]="true"
  [ranges]="ranges" (choosedDate)="pickerDatesUpdated($event)">
  </ngx-daterangepicker-material> -->

  <ngx-daterangepicker-material
            (datesUpdated)="pickerDatesUpdated($event)"
            [showCustomRangeLabel]="config.showCustomRangeLabel"
            [alwaysShowCalendars]="true"
            [ranges]="ranges"
            [linkedCalendars]="true"
            [isTooltipDate]="isTooltipDate"
            [isInvalidDate]="isInvalidDate"
            [keepCalendarOpeningWithRange]="false"
            [showRangeLabelOnInput]="config.showCustomRangeLabel"
            [autoApply]="false"
            [startDate]="selectedDate?.startDate"
            [endDate]="selectedDate?.endDate"
            [locale]="{ applyLabel: config.applyButtonText, format: 'DD-MM-YYYY'}"></ngx-daterangepicker-material>
</ng-container>
