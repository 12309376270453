import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SortableHeaderDirective } from './sortable-header/sortable-header.directive';
import { EstimateTimeFormatDirective } from './format/estimate-time-format.directive';
import { AlphaNumericOnlyDirective } from './input-restrict/alpha-numeric-only.directive';
import { CountdownDirective } from './countdown/countdown.directive';
import { CharacterCountDirective } from './character-count/character-count.directive';
import { AnchormeDirective } from './anchorme/anchorme.directive';
import { ClickOutsideDirective } from './click-outside/click-outside.directive';
import { TableColumnResizeDirective } from './table-column-resize/table-column-resize.directive';
import { DropAssetDirective } from './drag-drop/drop-asset.directive';
import { ClickStopPropagationDirective } from './click-stop-propagation.directive';
import { TimeDurationDirective } from './time-duration/time-duration.directive';
import { AutoresizeDirective } from './autoresize/autoresize.directive'



@NgModule({
  declarations: [
    SortableHeaderDirective,
    EstimateTimeFormatDirective,
    AlphaNumericOnlyDirective,
    CountdownDirective,
    CharacterCountDirective,
    AnchormeDirective,
    ClickOutsideDirective,
    TableColumnResizeDirective,
    DropAssetDirective,
    ClickStopPropagationDirective,
    TimeDurationDirective,
    AutoresizeDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SortableHeaderDirective,
    EstimateTimeFormatDirective,
    AlphaNumericOnlyDirective,
    CountdownDirective,
    CharacterCountDirective,
    AnchormeDirective,
    ClickOutsideDirective,
    DropAssetDirective,
    TableColumnResizeDirective,
    ClickStopPropagationDirective,
    TimeDurationDirective,
    AutoresizeDirective
  ]
})
export class DirectivesModule { }
