import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-policy',
  templateUrl: './password-policy.component.html',
  styleUrls: ['./password-policy.component.scss']
})
export class PasswordPolicyComponent implements OnInit {

  @Input() passwordRules: any;
  constructor() { }

  ngOnInit(): void {
  }

}
