import { Injectable, OnDestroy } from '@angular/core';
import { DataStateService } from '../data-state.service';
import { PlanningAppState } from 'src/app/shared/interface/PlanningAppState';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlanningStateService extends DataStateService<PlanningAppState> implements OnDestroy {

  private subscriptions: Subscription[] = [];

  sprintList$: Observable<any[]>;
  panelIndex$: Observable<any[]>;

  stateData: PlanningAppState = {
    taskList: [],
    taskIds: [],
    sprintList: [],
    sprintIds: [],
    isForceRefresh: false,
    panelIndex: [],
    isTaskUpdate: false
  }

  constructor() {
    super();
    const initialState: PlanningAppState = this.stateData;
    this.state$ = new BehaviorSubject<PlanningAppState>(initialState);
    this.sprintList$ = this.select('sprintList');
    this.panelIndex$ = this.select('panelIndex');
  }

  addToSprint(data: any, count?: number): void {
    const existRecords = this.selectSnapshot('sprintList');
    const newInfo = this.processTaskList(data);
    let newRecords = this.verifyIdExist(newInfo);
    this.setState({ sprintList: [...existRecords, ...newRecords] });
    // console.log(data[0]);
    this.recordOnTop(data[0]);
    this.setForceRefresh(true);
  }

  processTaskList(newData) {
    const sprintInfo = newData.map(sprint => {
      const task_info = this.getSprintTask(sprint.task_list);
      return { ...sprint, task_info: task_info };
    }).filter(x => x.status !== 'COMPLETED');
    return sprintInfo;
  }

  setOrUpdatePanelIndex() {
    const sprintInfo = this.selectSnapshot('sprintList');
    const panelIndex = sprintInfo.map((item, index) => { return index });
    this.set('panelIndex', panelIndex);
  }

  setToSprint(data: any): void {
    const newSprint = this.processTaskList(data);
    this.set('sprintList', newSprint);
    this.setOrUpdatePanelIndex();
  }

  updateToSprint(sprint): void {
    const existRecord = this.selectSnapshot('sprintList');
    const taskList = this.getSprintTask(sprint.task_list);
    let updatedSprint = sprint;
    updatedSprint['task_info'] = taskList;
    this.setState({
      sprintList: existRecord.map((x) => (x.sprint_id === updatedSprint.sprint_id ? updatedSprint : x)),
    });
    this.recordOnTop(sprint);
  }

  recordOnTop(data: any): void {
    let sprintList = this.selectSnapshot('sprintList');
    const taskIndex = sprintList.findIndex(x => x.sprint_id == data.sprint_id);
    if (taskIndex > -1) {
      sprintList.splice(taskIndex, 1);
      sprintList.unshift(data);
      this.set('sprintList', sprintList);
      // this.setForceRefresh(true);
    }
    this.setOrUpdatePanelIndex();
  }

  verifyIdExist(data: any) {
    const existingRowIds = this.selectSnapshot('sprintIds');
    let dataList = [];
    let dataListIds = [];
    for (let _data of data) {
      if (!existingRowIds.includes(_data.sprint_id)) {
        dataList.push(_data);
        dataListIds.push(_data.sprint_id)
      }
    }
    this.setState({ sprintIds: [...existingRowIds, ...dataListIds] });
    return dataList;
  }

  deleteTaskEstimation(taskId, estimationId) {
    const sprintList = this.selectSnapshot('sprintList');
    const updatedSprints = sprintList.map(sprint => {
      const task = sprint.task_list?.find(task => task.task_info.id === taskId);

      if (task) {
        const taskIndex = sprint.task_list.indexOf(task);
        const existingEstimationIndex = task.task_estimation.findIndex(estimation => estimation.id === estimationId);
        if (existingEstimationIndex > -1) {
          task.task_estimation.splice(existingEstimationIndex, 1);
        }
        const taskInfo = sprint.task_info[taskIndex];

        const existingEstimationInfoIndex = taskInfo.task_estimation.findIndex(estimation => estimation.id === estimationId);
        if (existingEstimationInfoIndex > -1) {
          taskInfo.task_estimation.splice(existingEstimationInfoIndex, 1);
        }
      }
      // console.log(sprint);
      return sprint;
    });
    this.setState({ 'sprintList': updatedSprints });
  }

  updateTaskEstimation(taskId, newEstimation) {
    const sprintList = this.selectSnapshot('sprintList');

    const updatedSprints = sprintList.map(sprint => {
      const task = sprint.task_list?.find(task => task.task_info.id === taskId);

      if (task) {
        const taskIndex = sprint.task_list.indexOf(task);

        if (!task.task_estimation) {
          task.task_estimation = [];
        }

        const existingEstimationIndex = task.task_estimation.findIndex(estimation => estimation.id === newEstimation.id);

        if (existingEstimationIndex > -1) {
          task.task_estimation[existingEstimationIndex] = newEstimation;
        } else {
          task.task_estimation.push(newEstimation);
        }

        const taskInfo = sprint.task_info[taskIndex];

        if (!taskInfo.task_estimation) {
          taskInfo.task_estimation = [];
        }

        const existingInfoEstimationIndex = taskInfo.task_estimation.findIndex(estimation => estimation.id === newEstimation.id);

        if (existingInfoEstimationIndex > -1) {
          taskInfo.task_estimation[existingInfoEstimationIndex] = newEstimation;
        } else {
          taskInfo.task_estimation.push(newEstimation);
        }
        // console.log(taskInfo);
      }

      return sprint;
    });
    this.setState({ 'sprintList': updatedSprints });
  }


  updateTaskToSprint(sprintId: string, taskList: any[]) {
    const currentData = this.selectSnapshot('sprintList');
    let updatedData = [...currentData];
    const newTaskList = this.getSprintTask(taskList);
    const sprintIndex = updatedData.findIndex(sprint => sprint.sprint_id === sprintId);
    if (sprintIndex !== -1) {
      updatedData[sprintIndex].task_list = taskList;
      updatedData[sprintIndex].task_info = newTaskList;
    }
    // console.log(updatedData);
    this.setState({ 'sprintList': updatedData });

  }

  addTaskToSprint(sprintId: string, taskList: any[]) {
    const currentData = this.selectSnapshot('sprintList');
    let updatedData = [...currentData];
    const newTaskList = this.getSprintTask(taskList);
    const sprintIndex = updatedData.findIndex(sprint => sprint.sprint_id === sprintId);
    // if (sprintIndex !== -1) {
    //   updatedData[sprintIndex].task_list = [...updatedData[sprintIndex].task_list, ...taskList];
    //   updatedData[sprintIndex].task_info = [...updatedData[sprintIndex].task_info, ...newTaskList];
    // }
    if (sprintIndex !== -1) {
      // Filter out existing task IDs in the sprint
      const existingTaskIds = updatedData[sprintIndex].task_list.map(task => task.id);
      const uniqueTaskList = taskList.filter(task => !existingTaskIds.includes(task.id));

      // Add unique tasks to the sprint
      updatedData[sprintIndex].task_list = [...updatedData[sprintIndex].task_list, ...uniqueTaskList];
      updatedData[sprintIndex].task_info = [...updatedData[sprintIndex].task_info, ...newTaskList];
    }

    // console.log(updatedData);
    this.setState({ 'sprintList': updatedData });

  }

  updateIndividualTaskStatus(sprintId: string, taskInfo: any) {
    const currentData = this.selectSnapshot('sprintList');
    let updatedData = [...currentData];
    const sprintIndex = updatedData.findIndex(sprint => sprint.sprint_id === sprintId);
    if (sprintIndex !== -1) {
      updatedData[sprintIndex].task_list = updatedData[sprintIndex].task_list.map(task => {
        if (task.task_info.id === taskInfo.id) {
          return { ...task, task_info: { ...task.task_info, task_status_info: taskInfo.task_status_info } };
        }
        return task;
      });
      updatedData[sprintIndex].task_info = updatedData[sprintIndex].task_info.map(task => {
        if (task.id === taskInfo.id) {
          return { ...task, task_status_info: taskInfo.task_status_info };
        }
        return task;
      });
    }

    // console.log(updatedData);
    this.setState({ 'sprintList': updatedData });
    this.setTaskUpdateFlag(true);
  }

  setTaskUpdateFlag(status: boolean) {
    this.set('isTaskUpdate', status);
  }

  setForceRefresh(status: boolean) {
    this.set('isForceRefresh', status);
  }

  getSprintTask(taskList: any[]) {
    let processTask = [];
    if (taskList?.length > 0) {
      processTask = taskList.map(task => ({ ...task.task_info, ...{ 'task_members': task.task_members }, ...{ 'task_estimation': task.task_estimation } }));
    }
    return processTask;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
