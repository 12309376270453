import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[ahCharacterCount]'
})
export class CharacterCountDirective {

  constructor(private _el: ElementRef, private _renderer: Renderer2) {}
  text: any;
	//class name
	@Input() ahCharacterCount?: string
  formGroup: any;
  ngOnInit() {
		// console.log(this.ahCharacterCount)
    this.formGroup = this._el.nativeElement.closest('.'+this.ahCharacterCount);
    let divElement = this._renderer.createElement('small');
		let text = this._renderer.createText(
      // String(this._el.nativeElement.value.length) +
      //   ' / ' +
      //   String(this._el.nativeElement.maxLength) +
      //   ` characters left`
			''
    );
    this._renderer.addClass(divElement, 'text-right');
    this._renderer.addClass(divElement, 'text-muted');
    this._renderer.addClass(divElement, 'show-remaining-count');
    this._renderer.appendChild(divElement, text);
    this._renderer.appendChild(this.formGroup, divElement);
  }
  @HostListener('ngModelChange', ['$event']) onKeyUp(value: any) {
    let leftChars =
      this._el.nativeElement.maxLength - this._el.nativeElement.value.length;
			if(this._el.nativeElement.value.length >= 0){
				this.formGroup.querySelectorAll('small')[0].innerHTML =
				String(this._el.nativeElement.value.length) +
				' / ' +
				String(leftChars) +
				` character${leftChars > 1 ? 's' : ''} left`;
			} else {
				this.formGroup.querySelectorAll('small')[0].innerHTML = '';
			}
  }
	@HostListener("blur", ["$event.target.value"]) onBlur(value: any) {
		this.formGroup.querySelectorAll('small')[0].innerHTML = '';
	}

}
