import { Directive, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { TimezoneService } from 'src/app/core/services/timezone/timezone.service';

@Directive({
  selector: '[ahCountdown]'
})
export class CountdownDirective {

  @Input() ahCountdown: any;
	@Input() timeOutElementRef: any;
  @Output() newItemEvent: EventEmitter<any> = new EventEmitter<any>()
	timer: number;
	countDownTimer: any;

	constructor(private timezone: TimezoneService, private ngZone: NgZone) { }

	ngOnInit() {
		this.dateToSeconds(this.ahCountdown);
    // this.timer = Number(this.ahCountdown);
    // this.startTimer();

	}

	dateToSeconds(ahCountdown: any) {
		let d = this.timezone.utcToLocal(ahCountdown);
		let now = this.timezone.currentDate();
		this.timer = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
		// Start the tiemr
		this.startTimer();
	}

	startTimer() {
		let timer = this.timer;
		this.ngZone.runOutsideAngular(() => {
			this.countDownTimer = setInterval(() => {
				const datas = this.secondsToDhms(timer);
        if(this.timeOutElementRef){
          this.timeOutElementRef.textContent = datas;
        }
				if (timer > 0) {
					timer -= 1;
				} else {
					clearInterval(timer);
				}
			}, 1000);
		});
	}

	secondsToDhms(totalSeconds: number | undefined) {
		let padZero = (value: number) => String(value).padStart(2, "0");

		totalSeconds = Number(totalSeconds);
		const dTemp = totalSeconds / 3600;
		const hTemp = dTemp % 24;
		const mTemp = (hTemp * 3600) % 3600;

		const d = Math.floor(dTemp / 24);
		const h = Math.floor(hTemp);
		const m = Math.floor(mTemp / 60);
		const s = Math.floor(mTemp % 60);

		const dDisplay = d > 0 ? padZero(d) + (d == 1 ? " Day, " : " Days, ") : "";
		const hDisplay = h > 0 ? padZero(h) + ":" : "";
		const mDisplay = padZero(m) + ":";
		const sDisplay = padZero(s);

		return `${dDisplay}${hDisplay}${mDisplay}${sDisplay}`;
		// return `${totalSeconds}`;
	}

	ngOnDestroy(): void {
		this.removeTimer();
	}

	private removeTimer() {
		if (this.timer) {
			this.timer = null;
		}
	}


}
