<div fxLayout="row" class="tab " fxLayoutGap="8px">
  <!-- <div class="tab-menu" [class.active]="viewMode=='no-update'" (click)="viewMode='no-update'">No Update </div>
  <div class="tab-menu" [class.active]="viewMode=='overview'" (click)="viewMode='overview'">Overview </div>
  <div class="tab-menu" [class.active]="viewMode=='eta'" (click)="viewMode='eta'">Estimate </div>
  <div class="tab-menu" [class.active]="viewMode=='comments'" (click)="viewMode='comments'">Comments </div>
  <div class="tab-menu" [class.active]="viewMode=='timelog'" (click)="viewMode='timelog'">Timelog </div>
   -->
  <div *ngFor="let nav of navList">
    <div class="tab-menu" *ngIf="nav.is_show" [ngClass]="{'active' : currentViewMode == nav.code}" (click)="selectCurrentSidebar(nav.code)">
      {{nav.name}}
    </div>
  </div>

</div>
