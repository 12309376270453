<div class="search main-dashboard-body-card-boxes">
    <div fxLayout="column" fxLayoutGap="8px" class="search-box">
        <div fxLayout="row" fxLayoutAlign="start center" class="box-top" fxLayoutGap="5px">
            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="12px" fxFlex="auto" class="cp" (click)="navigateToTimeLog(taskInfo.task.id)">
              <!-- <div fxLayout="column" fxFlex="30px" class="box-top-name" fxLayoutAlign="center center" style="background: #1E78CE;">
              </div> -->
              <ngx-avatar size="32" [value]="taskInfo.task.title | firstletter"></ngx-avatar>

                <div fxLayout="column" fxFlex="200px" class="box-top-task" fxLayoutAlign="start start">
                    <div class="box-top-task-title">{{taskInfo.task.task_id}} - {{taskInfo.task.entity_info?.name}}</div>
                    <div class="box-top-task-name">
                        <div class="name">{{taskInfo.task.title}}</div>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxFlex="14px" class="box-top-img cp" fxLayoutAlign="start start">
                <!-- <img src="../../../assets/image/svg/play-btn.svg" alt="" width="14px"> -->
                <img src="../../../../assets/image/svg/play-btn.svg" alt="" width="16px" *ngIf="!timerState || timerState.task_id !== taskInfo.task.id" (click)="initiateTask(taskInfo.task, 'start')">
                <img src="../../../../assets/image/svg/stop-btn.svg" width="29px" *ngIf="timerState && timerState.task_id === taskInfo.task.id" (click)="initiateTask(taskInfo.task, 'stop')">


            </div>
        </div>
    </div>
</div>
