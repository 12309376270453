import { PipesModule } from './../pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { MaterialModule } from '../material.module';
import { SpinnerComponent } from './loader/spinner/spinner.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { ToastrComponent } from './toastr/toastr.component';
import { PasswordPolicyComponent } from './password-policy/password-policy.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '../directives/directives.module';
import { TimePickerComponent } from './time-picker/time-picker.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { TaskCreatePopupComponent } from './task-create-popup/task-create-popup.component';
import { TimelogCreatePopupComponent } from './timelog-create-popup/timelog-create-popup.component';
import { InputAutocompleteComponent } from './input-autocomplete/input-autocomplete.component';
import { RouterModalComponent } from './modal/router-modal/router-modal.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { UserProfileImageEditorComponent } from './user-profile-image-editor/user-profile-image-editor.component';
import { ProjectCreatePopupComponent } from './project-create-popup/project-create-popup.component';
import { SearchResultItemComponent } from './search-result-item/search-result-item.component';
import { TaskSearchComponent } from './task-search/task-search.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { CalenderPopupComponent } from './calender-popup/calender-popup.component';
import { SelectFiltersComponent } from './select-filters/select-filters.component';
import { NoDataViewComponent } from './no-data-view/no-data-view.component';
import { ProfilePictureComponent } from './profile-picture/profile-picture.component';
import { SingleSelectDropdownComponent } from './single-select-dropdown/single-select-dropdown.component';
import { SearchComponent } from './search/search.component';
import { AttachementComponent } from './attachement/attachement.component';
import { AttachmentComponent } from './attachment/attachment.component';
import { CalenderHeaderComponent } from './calender-header/calender-header.component';
import { AttachmentPopupComponent } from './attachment-popup/attachment-popup.component';
import { ColumnModifyComponent } from './column-modify/column-modify.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RangePickerPopupComponent } from './range-picker-popup/range-picker-popup.component';
import { SprintCreatePopupComponent } from './sprint-create-popup/sprint-create-popup.component';
import { TaskCreateComponent } from './task-create/task-create.component';
import { UserComponent } from './user/user.component';
import { AvatarModule } from 'ngx-avatar';
import { PlanningSideBarComponent } from './planning-side-bar/planning-side-bar.component';
import { TaskViewComponent } from './task-view/task-view.component';
import { TaskDetailViewComponent } from './task-detail-view/task-detail-view.component';
import { SprintSidebarHeaderComponent } from './sprint-sidebar-header/sprint-sidebar-header.component';
import { EstimationComponent } from './estimation/estimation.component';
import { TaskOverviewComponent } from './task-overview/task-overview.component';
import { PlanningTimeLogViewComponent } from './planning-time-log-view/planning-time-log-view.component';
import { MultiSelectDropdownComponent } from './multi-select-dropdown/multi-select-dropdown.component';
import { TimelogPopupViewComponent } from './timelog-popup-view/timelog-popup-view.component';
import { TimeLogComponent } from './time-log/time-log.component'
import { RouterModule } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import { LineLoaderComponent } from './loader/line-loader/line-loader.component';
import { DateRangeNavigatorComponent } from './date-range-navigator/date-range-navigator.component';
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';


@NgModule({
  declarations: [
    DatePickerComponent,
    SpinnerComponent,
    AlertDialogComponent,
    PasswordPolicyComponent,
    ToastrComponent,
    TimePickerComponent,
    TaskCreatePopupComponent,
    TimelogCreatePopupComponent,
    InputAutocompleteComponent,
    RouterModalComponent,
    ChangePasswordComponent,
    ImageCropperComponent,
    UserProfileImageEditorComponent,
    ProjectCreatePopupComponent,
    SearchResultItemComponent,
    TaskSearchComponent,
    DateRangePickerComponent,
    CalenderPopupComponent,
    SelectFiltersComponent,
    NoDataViewComponent,
    ProfilePictureComponent,
    SingleSelectDropdownComponent,
    SearchComponent,
    AttachementComponent,
    AttachmentComponent,
    CalenderHeaderComponent,
    AttachmentPopupComponent,
    ColumnModifyComponent,
    RangePickerPopupComponent,
    SprintCreatePopupComponent,
    TaskCreateComponent,
    UserComponent,
    PlanningSideBarComponent,
    TaskDetailViewComponent,
    TaskViewComponent,
    SprintSidebarHeaderComponent,
    EstimationComponent,
    TaskOverviewComponent,
    PlanningTimeLogViewComponent,
    MultiSelectDropdownComponent,
    TimelogPopupViewComponent,
    TimeLogComponent,
    LineLoaderComponent,
    DateRangeNavigatorComponent,
    DeleteConfirmationComponent

  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    NgxMaterialTimepickerModule,
    PipesModule,
    ImageCropperModule,
    NgSelectModule,
    NgxDaterangepickerMd.forRoot(),
    AvatarModule,
    RouterModule,
    DragDropModule,
    NgxPermissionsModule,
  ],
  exports: [
    DatePickerComponent,
    SpinnerComponent,
    AlertDialogComponent,
    PasswordPolicyComponent,
    ToastrComponent,
    TimePickerComponent,
    RouterModalComponent,
    ChangePasswordComponent,
    ImageCropperComponent,
    UserProfileImageEditorComponent,
    TaskSearchComponent,
    SearchResultItemComponent,
    DateRangePickerComponent,
    SelectFiltersComponent,
    NoDataViewComponent,
    ProfilePictureComponent,
    CalenderPopupComponent,
    SelectFiltersComponent,
    NoDataViewComponent,
    SingleSelectDropdownComponent,
    SearchComponent,
    AttachementComponent,
    AttachmentComponent,
    ColumnModifyComponent,
    RangePickerPopupComponent,
    InputAutocompleteComponent,
    SprintCreatePopupComponent,
    TaskCreateComponent,
    UserComponent,
    PlanningSideBarComponent,
    TaskDetailViewComponent,
    TaskViewComponent,
    SprintSidebarHeaderComponent,
    EstimationComponent,
    TaskOverviewComponent,
    PlanningTimeLogViewComponent,
    MultiSelectDropdownComponent,
    TimelogPopupViewComponent,
    TimeLogComponent,
    LineLoaderComponent,
    DeleteConfirmationComponent
  ]
})
export class ComponentsModule { }
