import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { BoardApiService } from 'src/app/core/services/api/board-api/board-api.service';
import { PlanningStateService } from 'src/app/core/services/data/planning-state/planning-state.service';
import { AppToastrService } from 'src/app/core/services/toastr/app-toastr.service';
import { BoardStateService } from 'src/app/core/services/data/board-state/board-state.service';
import { PermissionService } from 'src/app/core/services/permission/permission.service';

@Component({
  selector: 'ah-task-detail-view',
  templateUrl: './task-detail-view.component.html',
  styleUrls: ['./task-detail-view.component.scss']
})
export class TaskDetailViewComponent implements OnInit {

  subscriptions: Subscription[] = [];
  requestInProgress: boolean = false;
  statusList: any[];
  selectedTaskStatus: any;
  private selectedOptionsChangeSubject = new Subject<any[]>();
  cloneTask: any = null;

  @Input() sprintInfo: any;
  @Input() taskInfo: any;
  @Input() viewFrom: any;
  @Output() closeSprint = new EventEmitter();
  viewMode: any = 'OVERVIEW';
  totalDuration = '00:00';
  timeLogOpen: boolean = false;

  navList = [
    {
      "code": "ACTIVITY",
      "name": "Activity",
      "is_show": false
    },
    {
      "code": "OVERVIEW",
      "name": "Overview",
      "is_show": true
    },
    {
      "code": "ESTIMATION",
      "name": "Estimate",
      "is_show": true
    },
    {
      "code": "COMMENT",
      "name": "Comments",
      "is_show": false
    },
    {
      "code": "TIMELOG",
      "name": "Timelog",
      "is_show": true
    }
  ];

  cloneTaskInfo: any;
  taskCreateForm: FormGroup;
  showSelect: boolean = false;
  hasPermission: boolean = false;

  constructor(
    private fb: FormBuilder,
    private boardApi: BoardApiService,
    private toast: AppToastrService,
    private planningState: PlanningStateService,
    private boardState: BoardStateService,
    private permissionService: PermissionService,
  ) { }

  ngOnInit(): void {
    // console.log(this.sprintInfo);
    if(this.viewFrom == 'DASHBOARD'){
      this.viewMode = 'TIMELOG';
    }
    this.processTask();
    // this.listStatus();

    const moduleEntries = Object.entries(this.permissionService.getPermission());
    // console.log(moduleEntries);
    this.hasPermission = moduleEntries.some(([key, value]) => {
      return key === 'planning';
    });
    if(this.viewFrom == 'DASHBOARD'){
      this.navList = this.navList.map(item => {
        if (item.code === 'ESTIMATION' && !this.hasPermission) {
          item.is_show = false;
        }
        return item;
      });
    }

    const projectDelay = this.selectedOptionsChangeSubject
      .pipe(debounceTime(300)) // Adjust the debounce time (in milliseconds) as needed
      .subscribe((newOptions: any) => {
        let payload = {
          "task_id": newOptions.id,
          "type": 'STATUS',
          "value": this.selectedTaskStatus
        }
        this.cloneTask = newOptions;
        // console.log(newOptions, this.selectedTaskStatus, payload);
        this.updateTaskStatus(payload);
      });
    this.setSubscription(projectDelay);

    const newTimeLog = this.planningState.select('isTaskUpdate').subscribe(res => {
      // console.log(res);
      if(!res){
        return;
      }
      const sprints = this.planningState.selectSnapshot('sprintList');
      const sprintIndex = sprints.findIndex(x=>x.sprint_id == this.sprintInfo.sprint_id);
      if(sprintIndex > -1){
        const taskInfo = sprints[sprintIndex].task_list.find(y=>y.id == this.taskInfo.id);
        this.taskInfo = taskInfo
        // console.log(taskInfo, this.taskInfo);
        this.planningState.setTaskUpdateFlag(false);
        this.processTask();
      }
    });
    this.setSubscription(newTimeLog);
    const TaskUpdate = this.boardState.select('isSideBarUpdate').subscribe(res => {
      // console.log(res);
      if(!res){
        return;
      }
      this.boardState.setIsSideBarUpdate(false);
      const taskList = this.boardState.selectSnapshot('taskList');
      const taskInfo = taskList.find(x=>x.task.id == this.taskInfo.task_info.id);
      this.taskInfo = {
        ...this.taskInfo,
        task_info: {
          ...this.taskInfo.task_info,
          ...taskInfo?.task
        }
      };
      this.processTask();
    });
    this.setSubscription(TaskUpdate);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.taskInfo && !changes.taskInfo.firstChange) {
      this.processTask();
    }
  }

  processTask() {
    this.cloneTaskInfo = this.taskInfo.task_info;
    if(this.viewFrom == 'DASHBOARD'){
      // this.cloneTaskInfo =  {...this.taskInfo}
      this.cloneTaskInfo = {
        ...this.cloneTaskInfo, ...{ 'members': this.taskInfo?.task_info.members.map(x => x.user) || [] }
      }
    } else {
      this.cloneTaskInfo = {
        ...this.cloneTaskInfo, ...{ 'members': this.taskInfo?.task_members.map(x => x.user) || [] }
      }
    }
    // console.log(this.cloneTaskInfo, this.taskInfo);
    this.taskCreateForm = this.fb.group({
      title: [this.cloneTaskInfo.title, [Validators.required, Validators.maxLength(150)]],
    });
  }



  get f() {
    return this.taskCreateForm.controls;
  }

  setCurrentViewMode(event) {
    this.viewMode = event;
  }

  close(status = false) {
    this.closeSprint.emit(status);
  }

  onSelectedOptionsChange(newOptions: any[], menuTrigger: MatMenuTrigger) {
    this.selectedOptionsChangeSubject.next(newOptions);
    if (menuTrigger) {
      menuTrigger.closeMenu();
    }
  }

  listStatus() {
    let payload = {
      "filters": {
        "entity_type": "TASK",
        "show_all": true
      }
    }
    const statusList = this.boardApi.listStatus(payload).subscribe(
      {
        next: (res) => {
          // console.log(res);
          this.requestInProgress = false;
          this.statusList = res.data.status;
        },
        error: (error) => {
          this.requestInProgress = false;
          // this.toast.error(error.message)
        },
      });
    this.setSubscription(statusList);
  }

  updateTaskStatus(payload) {
    this.requestInProgress = true;
    const createTask = this.boardApi.taskPartialUpdate(payload).subscribe(
      {
        next: (res) => {
          // console.log(res);
          this.requestInProgress = false;
          // console.log(res.data);
          const Task = res.data;
          if(this.viewFrom == 'PLANNING'){
            this.planningState.updateIndividualTaskStatus(this.sprintInfo.sprint_id, Task.task);
          } else {
            this.boardState.updateToBoard(Task);
          }
          this.toast.success('Task status updated successfully');
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(createTask);
  }

  totalDurationEmit(event){
    this.totalDuration = event;
  }

  timeLogOpenEmit(event){
    this.timeLogOpen = event;
  }

  openTimeLog(){
    this.timeLogOpen = true;
  }

  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
