import { Component,  EventEmitter,  Input, Output, SimpleChanges, OnChanges, OnDestroy, ViewChild, ElementRef,TemplateRef, ViewContainerRef, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ProjectCreatePopupComponent } from 'src/app/shared/components/project-create-popup/project-create-popup.component';
import { ProjectClientItem,  TransformedProjectClientItem } from '../../interface/ClientView';
import { Overlay, OverlayRef, OverlayConfig  } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';


@Component({
  selector: 'ah-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss'],
})
export class MultiSelectDropdownComponent implements OnChanges, OnDestroy {

  @ViewChild('trigger', { static: true }) trigger: ElementRef;
  @ViewChild('dropdownContent', { static: false }) dropdownContent: TemplateRef<any>;
  
  private overlayRef: OverlayRef | null = null;
  
  @Output() selectedOptions = new EventEmitter<any>();
  @Output() createClicked = new EventEmitter<any>();

 
  @Input() itemList: ProjectClientItem = [];
  transformedItemList: TransformedProjectClientItem[] = [];

  @Input() displayText: string = 'placeholder';
  @Input() selectedItem: string = null;
  @Input() bindLabelText: string = 'title';
  @Input() bindValueText: string = 'id';
  @Input() placeholder: string = 'Search Clients or Projects';
  @Input() notFoundText: string = 'No data found';
  @Input() isError: boolean = false;
  @Input() showCreate: boolean = false;
  @Input() showItem: boolean = false;

  @Input() projects: any[];
  @Input() tasks: any[];
  @Input() isTag: boolean = true;
  @Input() isFromUntagged: boolean = false;

  subscriptions: Subscription[] = [];
  showCreateOption: boolean = false;
  nameText: string = null;
  isdropdown : boolean = false;
  showDropdown: boolean = false;
  private openDropdownAfterViewInit = false;

  constructor(
    private dialog: MatDialog,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef
    ) {}  

  ngOnChanges(changes: SimpleChanges) {     

    if (changes.showItems?.currentValue) {     
        this.toggleSelection(true);   
    }

    if (changes.itemList) {
      
      
      this.transformedItemList = this.itemList.map((item) => ({
        ...item,
        type: this.capitalizeFirstLetter(item.type),
      }));    
      // console.log(this.transformedItemList);      
    }
  }   

  toggleDown() {
    this.isdropdown = !this.isdropdown;    
  }
  capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  isTransitionFromProjectsToTask(index: number): boolean {   

    if (index <= 0 || index >= this.transformedItemList.length) return false;
     const clientLength = this.transformedItemList.filter(x=>x.type.toUpperCase() == 'CLIENTS')
     const projectLength = this.transformedItemList.filter(x=>x.type.toUpperCase() == 'PROJECTS')     
     return (clientLength.length  == index ) 
    
  }
 

  displayTextFn() {
    const expenseExist = this.itemList.find(
      (x) => x[this.bindValueText] === this.selectedItem
    );
    return expenseExist ? expenseExist[this.bindLabelText] : '';
  }

  toggleSelection(event) {
    this.showItem = event;   
  }

  optionSelected(event) {
    this.toggleSelection(false);
    this.selectedOptions.emit(event);
    // console.log(event, this.selectedItem);
    this.closeDropdown();
  }

  openCreate() {
    this.dialog.open(ProjectCreatePopupComponent, {
      panelClass: 'ah-add-task',
      maxWidth: '500px',
      maxHeight: '700px',
      width: '100%',
      disableClose: true,
      autoFocus: false,
      data: {
        // task_id: this.taskId
      },
    });
    this.closeDropdown();
  }

  create() {
    if (this.nameText?.length > 3) {
      this.createClicked.emit(this.nameText);
      this.nameText = null;
    }
  }

  clear() {
    this.showCreateOption = false;
  }  

  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }

  // Adding cdk overlay
  
  openDropdown(): void {
    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      panelClass: this.isFromUntagged ? ['multi-select-overlay-class', 'untagged-class'] : ['multi-select-overlay-class'],
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo(this.trigger)
        .withPositions([{
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }])
    });

    this.overlayRef = this.overlay.create(overlayConfig);
    const dropdownPortal = new TemplatePortal(this.dropdownContent, this.viewContainerRef,{ $implicit: this.transformedItemList });
    this.overlayRef.attach(dropdownPortal);

    this.overlayRef.backdropClick().subscribe(() => this.closeDropdown());
  }

  closeDropdown(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
