import { Injectable } from '@angular/core';
import {
	BreakpointObserver,
	Breakpoints,
	BreakpointState,
} from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class BreakpointService {
	private viewports = [
		Breakpoints.XSmall,
		Breakpoints.Small,
		Breakpoints.Medium,
		Breakpoints.Large,
		Breakpoints.XLarge
	];

	constructor(private breakpointObserver: BreakpointObserver) {}

	getBreakpoint(): Observable<any> {
		return this.breakpointObserver.observe(this.viewports).pipe(
			map((state: BreakpointState) => {
				const isPortrait = this.breakpointObserver.isMatched(
					'(orientation: portrait)'
				);
				const isLandscape = this.breakpointObserver.isMatched(
					'(orientation: landscape)'
				);

				let breakpoint_meta = {
					size: null,
					viewport: null,
					portrait: isPortrait,
					landscape: isLandscape,
				};
				if (state.breakpoints[Breakpoints.XSmall]) {
					breakpoint_meta = {
						...breakpoint_meta,
						size: 'XS',
						viewport: Breakpoints.XSmall,
					};
				}
				if (state.breakpoints[Breakpoints.Small]) {
					breakpoint_meta = {
						...breakpoint_meta,
						size: 'SM',
						viewport: Breakpoints.Small,
					};
				}
				if (state.breakpoints[Breakpoints.Medium]) {
					breakpoint_meta = {
						...breakpoint_meta,
						size: 'MD',
						viewport: Breakpoints.Medium,
					};
				}
				if (state.breakpoints[Breakpoints.Large]) {
					breakpoint_meta = {
						...breakpoint_meta,
						size: 'LG',
						viewport: Breakpoints.Large,
					};
				}
				if (state.breakpoints[Breakpoints.XLarge]) {
					breakpoint_meta = {
						...breakpoint_meta,
						size: 'XL',
						viewport: Breakpoints.XLarge,
					};
				}
				return breakpoint_meta;
			})
		);
	}
}
