import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ah-timelog-popup-view',
  templateUrl: './timelog-popup-view.component.html',
  styleUrls: ['./timelog-popup-view.component.scss']
})
export class TimelogPopupViewComponent implements OnInit {

  @ViewChild('modal', {static:true}) modal;
  taskId: string = '';
  projectId: string = '';
  startDate: string = '';
  endDate: string = '';
  subscriptions: Subscription[] = [];
  routeParamType: string = null;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
   const paramsList = this.activatedRoute.params.subscribe(params => {
      this.taskId  = params['task_id'];
      this.projectId = params['project_id'];

    });
    const queryParams = this.activatedRoute.queryParams.subscribe(params => {
      this.startDate = params['startDate'];
      this.endDate = params['endDate'];
      if(params['type']){
        this.routeParamType = params['type'];
        if(params['type'] === 'PROJECT'){
          this.projectId = this.taskId;
          this.taskId = null;
        }
      }
    });
    this.setSubscription(paramsList )
    this.setSubscription(queryParams )
  }

  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
