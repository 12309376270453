import { DirectivesModule } from './directives/directives.module';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PipesModule } from './pipes/pipes.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { ComponentsModule } from './components/components.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { AvatarModule } from 'ngx-avatar';

const moduleList = [
  MaterialModule,
  ComponentsModule,
  FlexLayoutModule,
  FormsModule,
  ReactiveFormsModule,
  DirectivesModule,
  NgxMaterialTimepickerModule,
  InfiniteScrollModule,
  NgSelectModule,
  NgxDaterangepickerMd,
  AvatarModule
];

const declarationList = [
];

@NgModule({
  declarations: [...declarationList,],
  imports: [
    PipesModule,
    CommonModule,
    ...moduleList
  ],
  exports: [
    PipesModule,
    NgxPermissionsModule,
    ...moduleList,
    ...declarationList,
  ],
  providers: [
    DatePipe,
  ],
})
export class SharedModule { }
