import { EchoConfig } from 'ngx-laravel-echo';
import { environment } from 'src/environments/environment';

export const echoConfig: EchoConfig = {
  userModel: environment.SOCKET_PREFIX + '_database_private-',
  notificationNamespace: 'App\\Notifications',
  options: {
    broadcaster: 'socket.io',
    enabledTransports: ['ws', 'wss'],
    host: environment.SOCKET_URL,
    disableStats: false,
    authEndpoint: "/broadcasting/auth"
  }
};
