
<div #trigger (click)="openDropdown()" fxLayout="row" fxFlex="100" class="expense-category" ahClickOutside (appOutsideClick)="toggleSelection($event)" [ngClass]="{'input-error-msg' : isError}">
    <button  class="category cp">{{ (selectedItem) ? displayTextFn() : displayText}}</button>
    
        <ng-template #dropdownContent>           
                <div class="show-category" (click)="$event.stopPropagation()">                    
                    <ng-select class="main-ng-select" [items]="transformedItemList" [groupBy]="'type'" [isOpen]="showItem" [bindLabel]="'name'" appendTo=".popup-to-multi-select" [bindValue]="'id'" [placeholder]="placeholder"  [notFoundText]="notFoundText" [(ngModel)]="selectedItem" (close)="toggleSelection(false)" (change)="optionSelected($event)">
                    
                        <ng-container >
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">            
                                <b><span class="project-name-main bold" (click)="toggleDown()">{{item.type}}</span></b>                 
                            </ng-template>
        
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" >
                                <b><span class="project-name-main active"  >{{item.name}}</span></b>
                            </ng-template>   
                        
                        </ng-container>                                       
        
                    </ng-select> 
                 
                    <div class="popup-to-multi-select custom-scroll-thin">     
                                      
                    </div> 
                    <div class="create" [ngClass]="{'hide' : showCreateOption}" (click)="openCreate()" *ngIf="showCreate">
                        + Create New
                    </div>                  
                </div>                
                
        </ng-template>   
</div>



