<div class="calender-picker">
  <div class="inline-calendar-card" *ngIf="!showMonthPicker">
    <mat-calendar #calendar [selected]="selectedDate" (selectedChange)="onDateSelected($event)"
     [maxDate]="today" (click)="$event.stopPropagation()">
    </mat-calendar>
  </div>
  <div class="inline-calendar-card" *ngIf="showMonthPicker">
    <mat-calendar #calendar startView="year" [headerComponent]="calenderHeader" (monthSelected)="chosenMonthHandler($event)"
     [maxDate]="today" (click)="$event.stopPropagation()">
    </mat-calendar>
  </div>
</div>
