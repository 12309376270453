import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { UploadFileValidationService } from 'src/app/core/services/file/upload-file-validation.service';
import { CommonUtilsService } from 'src/app/core/services/helper/common-utils.service';
import { AppToastrService } from 'src/app/core/services/toastr/app-toastr.service';
import { fileToDataUrl } from 'src/app/plugins/utils/fileToDataUrl';
import { nid } from 'src/app/plugins/utils/getId';
import { UploadAsset } from '../../interface/UploadAsset';
import { ASSET_MIMETYPE } from 'src/app/data/asset-config/asset-config';

@Component({
  selector: 'ah-attachement',
  templateUrl: './attachement.component.html',
  styleUrls: ['./attachement.component.scss']
})
export class AttachementComponent implements OnInit {
  @Output() emitAction = new EventEmitter();
  @Output() emitProgress = new EventEmitter();


  @Input() attachmentReference: string = null;
  @Input() showFileName: boolean = true;
  @Input() showFileCount: boolean = true;
  @Input() isView: boolean = false;

  @Output() emitAttachment = new EventEmitter();
  @ViewChild('file', { static: false }) file: ElementRef;

  subscriptions: Subscription[] = [];

  uploadedAttachmentsProgress = [];
  @Input() uploadedAttachments:UploadAsset[] = [];
  avgUploaded: number = 0;
  maxUploadLimit: number = 5;
  maxUploadSize: number = 10; //mb
  showAttachmentProgress: boolean = false;
  allowedMimeTypes = ASSET_MIMETYPE;
  renameId: string = null;
  existingAssets = [];
  isCompleted: boolean = false;
  showCloseButton = false;

  constructor(
    private toast: AppToastrService,
    private commonUtils: CommonUtilsService,
    private fileValidate: UploadFileValidationService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.existingAssets = [...this.uploadedAttachments]
    if(this.isView && this.uploadedAttachments.length > 0){
      this.showCloseButton = true;
    }
    // this.uploadedAttachments = [];
    // this.uploadedAttachmentsProgress = [];
  }

  showRename(event, fileId){
    event.stopPropagation();
    this.renameId = fileId;
  }



  ngOnChanges(changes: SimpleChanges) {
    if(changes?.uploadedAttachments?.currentValue){
      if(this.uploadedAttachments.length > 0){
        this.showAttachmentProgress = true;
        this.isCompleted = true;
      }
    }
  }

  onDrop(files: FileList): void {
    this.generateFileUploadPayload(files);
  }

  browse() {
    this.file.nativeElement.click();
  }

  async onSelectFile(event): Promise<void> {
    const files = event.target.files;
    this.avgUploaded = 0;
    if (files.length > this.maxUploadLimit) {
      return this.toast.error('Maximum upload limit is ' + this.maxUploadLimit);
    }
    this.generateFileUploadPayload(files);
    // console.log(files);
  }

  mbToByteConversion(size){
		return size*1024*1024;
	}

  async generateFileUploadPayload(files) {
    this.showCloseButton = false;
    this.emitProgress.emit({'progress':'INPROGRESS'});
    this.isCompleted = false;
    const payload = [];
    for (let i = 0; i < files.length; i++) {
      const validate = this.fileValidate.validateFile(files[i], this.allowedMimeTypes.map(x => { return x.mime }));
      if (validate) {
        this.toast.error('Only files with following extensions are allowed: ' + this.allowedMimeTypes.map(x => { return x.name }).join(', '));
        return;
      }
      if(files[i].size >= this.mbToByteConversion(this.maxUploadSize)){
        return 	this.toast.error('Maximum allowed size is '+this.maxUploadSize+' mb');
      }
      this.showProgressBar();
      let _file = files[i];
      let mimeType = files[i].type.split('/');
      mimeType = mimeType[mimeType.length - 1].toLowerCase();

      const _nid = nid();

      const placeholderImage = this.allowedMimeTypes.filter(x => x.mime.toUpperCase() == mimeType.toUpperCase());
      payload.push(
        {
          file_ref_id: _nid,
          file_type: 'FILE',
          file_data: _file,
          file_name: _file.name.split('.')[0],
          file_format: (placeholderImage.length > 0 ) ? placeholderImage[0].ext: mimeType.toUpperCase()
        }
      );
      const reader: any = await fileToDataUrl(files[i]).catch((e) => Error(e));
      // const placeholderImage = this.allowedMimeTypes.filter(x => x.mime.toUpperCase() == mimeType.toUpperCase());

      this.uploadedAttachments.push({
        id: _nid,
        'src': reader as string,
        'asset_info': null,
        'status': true,
        'in_progress': true,
        'total': 100,
        'completed': 1,
        'file_name': _file.name,
        'size': this.getFileSizeInMB(_file),
        'placeholderImage': placeholderImage[0]?.src,
        'attachment_id': _nid
      });
      this.uploadedAttachmentsProgress.push({
        id: _nid,
        'total': 100,
        'completed': 1,
      })
    }
    // console.log(this.uploadedAttachments);
    // this.calculateAvgUpload(true);
    this.uploadAssets(payload);
    // this.upload();
  }

  uploadAssets(formData) {
    const createAssetRequest = this.commonUtils.signedUpload("OTHERS", formData)
      .subscribe((res: any) => {
        const {
          ref_id, progress, is_completed, error, failed, success, is_uploaded,
          data: {
            data: { assets } = { assets: [{ asset: null }] }
          } = {}
        } = res;

        // Return the error message back.
        if (error || failed) {
          return;
        }
        if (is_completed) {
          this.resetNativeElement();
          this.calculateAvgUpload(true);
          return;
        }
        if (!(success && is_uploaded) && !progress) return;
        let asset_index;
        asset_index = this.uploadedAttachments.findIndex(up => up.id == ref_id);
        let progress_index = this.uploadedAttachmentsProgress.findIndex(up => up.id == ref_id);
        let { asset } = assets[0];
        if (asset_index > -1) {
          let attachAsset;
          attachAsset = this.uploadedAttachments[asset_index];

          let _attachAsset = {};
          if (progress) {
            _attachAsset = { completed: (progress >= 100) ? 99 : progress }
          } else {
            _attachAsset = { asset_info: asset, completed: 100 }
          }
          if (progress) {
            this.uploadedAttachments[asset_index]['completed'] = _attachAsset['completed'];
          } else {
            this.uploadedAttachments[asset_index] = { ...attachAsset, ..._attachAsset };
          }
        }

        if (progress_index > -1) {
          const attachProgress = this.uploadedAttachmentsProgress[progress_index];
          let _attachProgress = {};
          if (progress) {
            this.uploadedAttachmentsProgress[progress_index]['completed'] = (progress >= 100) ? 99 : progress;
          } else {
            _attachProgress = { completed: 100 }
            this.uploadedAttachmentsProgress[progress_index] = { ...attachProgress, ..._attachProgress };
          }
        }
        this.calculateAvgUpload(false);
      }, err => {

      });
    this.setSubscription(createAssetRequest);
  }

  resetNativeElement() {
    this.file.nativeElement.value = null;
  }

  calculateAvgUpload(isComplete) {
    let total = 0
    let completed = 0
    if (this.uploadedAttachmentsProgress.length > 0) {
      for (let val of this.uploadedAttachmentsProgress) {
        completed += val['completed']
        total += 1
      }
      this.avgUploaded = Math.round(completed / (total))
    } else {
      this.avgUploaded = 100
    }
    if (this.avgUploaded == 100) {
      if (this.uploadedAttachments) {
        let get_active_list = [];
        for (let item of this.uploadedAttachments) {
          if (item['status'] == true) {
            get_active_list.push(item)
          }
        }
        this.uploadedAttachments = get_active_list
      }
    }
    if (isComplete) {
      this.emitProgress.emit({'progress':'COMPLETED'});
      // console.log(this.uploadedAttachments);
      this.isCompleted = true;
      this.upload();

    }
  }


  getFileSizeInMB(file: File): string {
    const fileSizeInBytes = file.size;
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
    return fileSizeInMB.toFixed(2);
  }

  showProgressBar() {
    this.showAttachmentProgress = true;
  }

  deleteImage(event, fileId) {
    event.stopPropagation();
    const index = this.uploadedAttachments.findIndex(x => x.attachment_id == fileId);
    if (index > -1) {
      this.uploadedAttachments.splice(index, 1);
      this.upload(true, fileId);
      if(this.uploadedAttachments.length == 0){
        this.showAttachmentProgress = false;
      }
    }
  }

  close(status = false) {
    this.emitAction.emit({ 'close': true, 'save': status, 'is_delete': false, 'asset': this.uploadedAttachments, 'attachment_id': null })
  }

  upload(isDelete = false, id = null) {
    this.renameId = null;
    this.emitAction.emit({ 'close': false, 'save': false, 'is_delete': isDelete, 'asset': this.uploadedAttachments, 'attachment_id': id  })
  }

  isAlreadyupload(file){
    return (typeof file.attachment_id == 'number');
  }

  openFileInNewTab(fileUrl: string): void {
    const link = this.renderer.createElement('a');
    this.renderer.setAttribute(link, 'href', fileUrl);
    this.renderer.setAttribute(link, 'target', '_blank');
    link.click();
  }

  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }


}
