import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ah-task-overview',
  templateUrl: './task-overview.component.html',
  styleUrls: ['./task-overview.component.scss']
})
export class TaskOverviewComponent implements OnInit {

  @Input() taskId: string = '';
  @Input() taskName: string = '';
  @Input() taskInfo: any  = {};
  @Input() sprintInfo: any  = {};
  @Output() closeTaskCreate = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
  }

  closeTask(event){
    this.closeTaskCreate.emit(event);
  }

}
