<div class="password-rules-container">
  <div class="password-rule" [ngClass]="(passwordRules.errors?.required || passwordRules.errors?.hasSmallCase) ? 'fail' : 'pass'">
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
      <!-- <span [class]="passwordRules.errors?.hasLowerCase ? 'text-red' : 'text-green'">One lowercase character</span> -->
      <img
        [src]="(passwordRules.errors?.required || passwordRules.errors?.hasSmallCase) ? '../../../../assets/image/svg/policy-error.svg' : '../../../../assets/image/svg/policy-tick.svg'"
        width="12px">
      <span>One lowercase character</span>
    </div>
  </div>
  <div class="password-rule" [ngClass]="(passwordRules.errors?.required || passwordRules.errors?.hasCapitalCase) ? 'fail' :'pass'">
    <!-- <span [class]="(passwordRules.errors?.hasCapitalCase)  ? 'text-red' : 'text-green'">One uppercase character</span> -->
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">

      <img
        [src]="(passwordRules.errors?.required || passwordRules.errors?.hasCapitalCase) ? '../../../../assets/image/svg/policy-error.svg' : '../../../../assets/image/svg/policy-tick.svg'"
        width="12px">
      <span>One uppercase character</span>
    </div>
  </div>
  <div class="password-rule" [ngClass]="(passwordRules.errors?.required || passwordRules.errors?.hasNumber) ? 'fail' :'pass'">
    <!-- <span [class]="passwordRules.errors?.hasNumber ? 'text-red' : 'text-green'">One number</span> -->
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">

      <img
        [src]="(passwordRules.errors?.required || passwordRules.errors?.hasNumber) ? '../../../../assets/image/svg/policy-error.svg' : '../../../../assets/image/svg/policy-tick.svg'"
        width="12px">
      <span>One number</span>
    </div>
  </div>
  <div class="password-rule" [ngClass]="(passwordRules.errors?.required || passwordRules.errors?.hasSpecialCharacters) ? 'fail' :'pass'">
    <!-- <span [class]="passwordRules.errors?.hasSpecialCharacters ? 'text-red' : 'text-green'">One special character</span> -->
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">

      <img
        [src]="(passwordRules.errors?.required || passwordRules.errors?.hasSpecialCharacters) ? '../../../../assets/image/svg/policy-error.svg' : '../../../../assets/image/svg/policy-tick.svg'"
        width="12px">
      <span>One special character</span>
    </div>
  </div>
  <div class="password-rule" [ngClass]="(passwordRules.errors?.required || passwordRules.errors?.minlength) ? 'fail' :'pass'">
    <!-- <span [class]="passwordRules.errors?.minlength ? 'text-red' : 'text-green'">8 characters minimum</span> -->
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">

      <img
        [src]="(passwordRules.errors?.required || passwordRules.errors?.minlength) ? '../../../../assets/image/svg/policy-error.svg' : '../../../../assets/image/svg/policy-tick.svg'"
        width="12px">
      <span>8 characters minimum</span>
    </div>
  </div>
  <!-- <div class="password-rule" [class.fail]="passwordRules.errors?.maxlength" [class.pass]="!passwordRules.errors?.maxlength">
    <span [class]="passwordRules.errors?.maxlength ? 'text-red' : 'text-green'">Contains maximum of 25 characters.</span>
  </div> -->
</div>
