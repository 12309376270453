import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ProfileApiService } from 'src/app/core/services/api/profile-api/profile-api.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommonUtilsService } from 'src/app/core/services/helper/common-utils.service';
import { ImageValidateService } from 'src/app/core/services/image-validate/image-validate.service';
import { AppToastrService } from 'src/app/core/services/toastr/app-toastr.service';
import { nid } from 'src/app/plugins/utils/getId';

@Component({
  selector: 'ah-user-profile-image-editor',
  templateUrl: './user-profile-image-editor.component.html',
  styleUrls: ['./user-profile-image-editor.component.scss']
})
export class UserProfileImageEditorComponent implements OnInit {

  @ViewChild('profileFile', { static: true }) file: ElementRef;
  private subscriptions: Subscription[] = [];
  selectedFile: any;
  url: any;
  userInfo: any;
  profileImgUrl: any;
  fileName: string;
  cropAttributes: any;
  requestInProgress: boolean = false;
  uploadInProgress: boolean = true;

  constructor(
    private toast: AppToastrService,
    private imageValidate: ImageValidateService,
    private dialogRef: MatDialogRef<UserProfileImageEditorComponent>,
    private authService: AuthService,
    public dialog: MatDialog,
    private commonUtils: CommonUtilsService,
    private profileApi: ProfileApiService
  ) { }

  ngOnInit(): void {
    this.userInfo = this.authService.getUserInfo();
    this.profileImgUrl = this.userInfo.profile_image?.preview_url;
  }

  browse() {
    this.file.nativeElement.click();
  }

  onSelectFile(event) {
    const validate = this.imageValidate.validateImage(event.target);
    if (validate) {
      this.toast.error('Only files with following extensions are allowed: ' + this.imageValidate.extensions.join(','));
      return;
    }

    this.cropAttributes = {
      file: event,
      shape: 'round',
      ratio: 1 / 1,
      type: 'profile',
      width: 256,
    };
  }

  preparePayload(event) {
    const header = event.split(';base64,')[0];
    const _nid = nid();
    const payload = [
      {
        file_ref_id: _nid,
        file_type: 'BASE64',
        file_data: event,
        file_name: 'profile_image',
        file_format: header.split('data:')[1].split('/')[1].toUpperCase()
      }
    ]
    this.uploadPost(payload);
    this.requestInProgress = true;
  }

  uploadPost(formdata) {
    this.requestInProgress = true;
    this.cropAttributes = null;
    const createAssetRequest = this.commonUtils.signedUpload("others", formdata)
      .subscribe((res: any) => {
        const {
          ref_id, progress, is_completed, error, failed, success, is_uploaded,
          data: {
            data: { assets } = { assets: [{ asset: null }] }
          } = {}
        } = res;

        // Return the error message back.
        if (error || failed) {
          this.requestInProgress = false;
          this.toast.success('Unable to upload, try after sometime.');
          return;
        }

        if (is_completed) {
          this.requestInProgress = false;
          return;
        }

        if (!(success && is_uploaded) && !progress) return;

        if (!progress) {
          // initiate the update profile image api.
          const { asset } = assets[0];
          this.profileImgUrl = asset.full_url;
          console.log(asset);
          // this.updateProfile(asset);
        }
      });

    this.setSubscription(createAssetRequest);
  }

  updateProfile(asset) {
    let payload = {
      "profile_image": asset.id
    };
    const updateProfile = this.profileApi.updateProfile(payload).subscribe((data) => {
      this.profileImgUrl = data.data.user.profile_image.full_url;
      this.authService.setUserInfo(data.data.user)
      this.toast.success('Profile picture updated successfully');
      this.requestInProgress = false;
      this.file.nativeElement = null;
      this.close();
    },
      (error) => {
        const { message } = error;
        this.toast.error(message);
        this.requestInProgress = false;
      });
    this.setSubscription(updateProfile);
  }

  close() {
    this.dialogRef.close();
  }

  setSubscription(request) {
    this.subscriptions.push(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
