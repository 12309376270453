<ng-container #resultContainer>

  <div fxLayout="row" fxFlex="299px" fxFlex.xs="180px" fxFlex.sm="220px" class="sub-nav-search" fxLayoutAlign="start center" [formGroup]="taskSearchForm">
    <input class="sub-nav-search-input" placeholder="Search this board" formControlName="keyword" />
    <div class="sub-nav-search-image cp" (click)="resetSearch()">
      <!-- <img src="../../../../assets/image/svg/close-black.svg" /> -->
      <img
        [src]="f.keyword.value ? '../../../../assets/image/svg/close-black.svg' : '../../../../assets/image/svg/search.svg'"
        width="10px" [ngClass]="f.keyword.value ? 'close' : 'search'">

    </div>
  </div>
  <ng-container *ngIf="f.keyword.value && (taskList$ | async)?.value.length > 0">
    <ul class="custom-scroll-thin">
      <!-- {{(taskList$ | async).value.length > 0 }} -->
      <li *ngFor="let task of (taskList$ | async)?.value | sortBy:'last_accessed_at':'desc'">
        <ah-search-result-item (click)="$event.stopPropagation()" [taskInfo]="task"
          (taskEvent)="initiateTask($event)"></ah-search-result-item>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngIf="(taskList$ | async)?.loading">
    <ul class="custom-scroll-thin active" fxLayout="row" fxLayoutAlign="center center">
      loading...
    </ul>
  </ng-container>
  <ng-container *ngIf="!(taskList$ | async)?.loading && !searchResult && f.keyword.value?.length >= 3">
    <ul class="custom-scroll-thin active" fxLayout="row" fxLayoutAlign="center center">
      Search Result not found
    </ul>
  </ng-container>
</ng-container>
