<div fxLayout="row" fxFlex="100" class="expense-category" ahClickOutside (appOutsideClick)="toggleSelection($event)" [ngClass]="{'input-error-msg' : isError}">
    <button class="ctgry cp">{{ (selectedItem) ? displayTextFn() : displayText}}</button>
    <ng-container *ngIf="showItem" class="cdk-overlay-container">
        <div class="show-category">
            <ng-select [items]="itemList | sortBy: bindLabelText: 'asc'" [isOpen]="showItem" [bindLabel]="bindLabelText" [bindValue]="bindValueText" [placeholder]="placeholder" appendTo=".popup-to-show" [notFoundText]="notFoundText" [(ngModel)]="selectedItem" (close)="toggleSelection(false)" (change)="optionSelected($event)">
            </ng-select>
            <div class="popup-to-show custom-scroll-thin"></div>
            <div class="create" [ngClass]="{'hide' : showCreateOption}" (click)="openCreate()" *ngIf="showCreate">
                + Create New
            </div>
            <div fxLayout="row" class="create-new" fxLayoutGap="10px" *ngIf="showCreateOption">
                <div fxLayout="column" fxFlex="calc(80 - 10px)">
                    <input placeholder="Enter Expense Name" [(ngModel)]="nameText" />
                </div>
                <div fxLayout="row" fxFlex="20" fxLayoutGap="4px" fxLayoutAlign="end center">
                    <button class="save cp" (click)="create()" fxLayout="row" fxLayoutAlign="end center">
            <mat-icon>check_circle</mat-icon>
          </button>
                    <button class="cancel cp" (click)="clear()" fxLayout="row" fxLayoutAlign="end center">
            <mat-icon>cancel</mat-icon>
          </button>
                </div>


            </div>
        </div>
    </ng-container>
</div>
