import { WebsocketService } from './core/services/websocket/websocket.service';
import { PermissionService } from './core/services/permission/permission.service';
import { ReferenceName } from './shared/enum/reference-name.enum';
import { Component } from '@angular/core';
import { CommonLoggerService } from './core/services/logger/common-logger.service';
import { environment } from 'src/environments/environment';
import { CommonUtilsService } from './core/services/helper/common-utils.service';
import { Subscription } from 'rxjs';
import { AuthService } from './core/services/auth/auth.service';
import { CommonStorageService } from './core/services/storage/common-storage.service';
import { AuthDataService } from './core/services/data/auth-data/auth-data.service';
import { NavigationEnd, Router } from '@angular/router';
import { CommonDataService } from './core/services/data/common-data/common-data.service';
// import { SocketService } from './core/services/socket/socket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'timesheet-app';

  subscriptions: Subscription[] = [];
  localCheckInterval = null;
  private userId: string=this.auth.getUserId();

  constructor(
    private commonUtils: CommonUtilsService,
    private auth: AuthService,
    private commonStorage: CommonStorageService,
    private permissionService: PermissionService,
    private authState: AuthDataService,
    private websocket: WebsocketService,
    private router: Router,
    private commonState: CommonDataService
    // private socket: SocketService,
  ) {

    // console.log(version);
    if (environment.production) {
      CommonLoggerService.enableProductionMode();
    }
    if(this.auth.isLoggedInStatus() && !this.permissionService.getPermission()){
      this.permissionService.addPermission(['TASK_MANAGEMENT']);
    }

    this.router.events.subscribe((event: NavigationEnd) => {
			if (event instanceof NavigationEnd) {
        this.commonState.siteNavtoggleStaus(true);
      }
    });
    // this.permissionService.loadPermissions(this.flattenModules(this.permission));
    // if(this.commonStorage.get(ReferenceName.TASK_TIMER)){
      // this.commonUtils.startTimer();
    // }
    const authOsb = this.authState.loggedInStatus$.subscribe(res => {
      if (res) {
        this.joinUserChannel();
      } else {
        this.leaveUserChannel();
      }
    });
    this.setSubscription(authOsb);
  }

  clearLocalInterval() {
		clearInterval(this.localCheckInterval);
	}

	joinUserChannel() {
    // this.websocket.connect('wss://ws-ap2.pusher.com/app/690e05e2adcc51f8998a');
    this.websocket.connect();
	}

	leaveUserChannel() {
    if(this.websocket){
      this.websocket.disconnect();
    }
	}

  setSubscription(request: Subscription): void {
		this.subscriptions.push(request);
  }

	ngOnDestroy() {
		this.leaveUserChannel();
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}
}
