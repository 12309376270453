import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { TimezoneService } from 'src/app/core/services/timezone/timezone.service';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {

  constructor(private timezone: TimezoneService) { }

  transform(value: any, format: string) {

    if (!value) { return ''; }
    if (!format) { format = 'shortDate'; }

    const checkFormat = 'YYYY-MM-DD';

    const isValidFormat = moment(value, checkFormat, true).isValid();
    let dateInput;
    if(isValidFormat){
      dateInput = moment(new Date(value)).format('YYYY-MM-DD HH:mm:ss');
    } else {
      dateInput = value;
    }

    let dateConvert = this.timezone.utcToLocalISO(dateInput)

    return formatDate(dateConvert, format, 'en-US');
}

}
