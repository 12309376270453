import { Injectable, NgZone } from '@angular/core';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { CommonStorageService } from '../storage/common-storage.service';
import { ReferenceName as refName } from '../../../shared/enum/reference-name.enum';

@Injectable({
  providedIn: 'root'
})
export class TimerService {

  private subscriptions: Subscription[] = [];
  private startTime: number;
  private timerId: any = null;
  public elapsedTime: any;
  private isTimerActive: boolean = false;

  private _mySubj: Subject<{ [k: string]: any }>
    = new Subject<{ [k: string]: any }>();

  public data$: Observable<{ [k: string]: any }> = this._mySubj.asObservable();

  constructor(
    private zone: NgZone,
    private commonStorage: CommonStorageService,
  ) {
   }

  ngOnInit() {
  }

  start(payload) {
    // console.log(payload, this.elapsedTime, this.isTimerActive);
    if(this.elapsedTime || this.isTimerActive){
      return {startTimestamp: this.startTime, endTimestamp: '', payload};
    }
    if (payload.timestamp) {
      this.startTime = parseInt(payload.timestamp);
    } else {
      this.startTime = new Date().getTime();
    }
    this.commonStorage.set(refName.TASK_TIMER, payload);
    this.isTimerActive = true;
    // this.zone.runOutsideAngular(() => {
      this.timerId = interval(1000).subscribe(() => {
        this.zone.run(() => {
          const elapsedTime = new Date().getTime() - this.startTime;
          this.elapsedTime = this.secondsToDhms(elapsedTime);
          this._mySubj.next(this.elapsedTime);
          // console.log(this.elapsedTime);
        });
      });
    // });
    return {startTimestamp: this.startTime, endTimestamp: '', payload};
  }

  secondsToDhms(totalSeconds) {
    let padZero = value => String(value).padStart(2, "0");

    totalSeconds = Number(totalSeconds);
    const dTemp = totalSeconds / (1000 * 60 * 60);
    const hTemp = dTemp % 24;
    const mTemp = (hTemp * 3600) % 3600;

    const d = Math.floor(dTemp / 24);
    const h = Math.floor(hTemp);
    const m = Math.floor(mTemp / 60);
    const s = Math.floor(mTemp % 60);

    const dDisplay = d > 0 ? padZero(d) + (d == 1 ? " Day, " : " Days, ") : "";
    const hDisplay = h > 0 ? padZero(h) + ":" : "";
    const mDisplay = padZero(m) + ":";
    const sDisplay = padZero(s);
    return `${dDisplay}${hDisplay}${mDisplay}${sDisplay}`;
  }

  stop() {
    // console.log(this.timerId);
    if(this.timerId){
      this.timerId.unsubscribe();
    }
    this.elapsedTime = null;
    this.isTimerActive = false;
    this._mySubj.next(null);
    // this._mySubj.complete();
    this.commonStorage.remove(refName.TASK_TIMER);
  }

  getStopTimer(){
    let timeLogObj = this.commonStorage.get(refName.TASK_TIMER);
    return {startTimestamp: this.startTime, endTimestamp: new Date().getTime(), payload:timeLogObj, current_end_time: true };
  }

  setSubscription(request) {
    this.subscriptions.push(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
