import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../../http-client/http-client.service';

@Injectable({
  providedIn: 'root'
})
export class TimelogApiService {

  constructor(private httpService: HttpClientService) { }

  timesheetList(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/timesheet/list', payload);
  }

  deleteTimeSheet(id: string): Observable<any>{
    return this.httpService.delete(environment.BASE_URL + '/v1/timesheet/delete/' + id, null);
  }
}
