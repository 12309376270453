import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'ah-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {
  @Input() cropAttributes: any;
  @Output() cropImage: EventEmitter<any> = new EventEmitter();
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  shape: boolean = false;
  ratio: number = 4 / 3;
  width: number = 250;


  constructor(
    private dialogRef: MatDialogRef<ImageCropperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.fileChangeEvent(this.cropAttributes.file);
    this.shape = (this.cropAttributes.shape == 'round') ? true : false;
    this.ratio = this.cropAttributes.ratio;
    this.width = this.cropAttributes.width;
  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    // console.log(event);
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    this.showCropper = true;
    // console.log('Image loaded');
  }
  crop() {
    this.cropImage.emit(this.croppedImage);
  }
  close() {
    this.dialogRef.close();
  }

  cropperReady(event) {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

}
