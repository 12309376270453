export const environment = {
  production: true,
  BASE_URL: "https://api.stage.appyhrs.com/api",
  SOCKET_URL: "",
  SOCKET_KEY: "",
  SOCKET_PREFIX: "",
  SOCKET_IO_URL: "",
  PUSHER_CLUSTER: "ap2",
  PUSHER_KEY: "690e05e2adcc51f8998a",
};
