<div fxFlex="500px" fxFlex.xs="100%" fxFlex.sm="100%" fxLayout="column" fxLayoutAlign="start" class="side-nav">
    <div class="header" fxLayout="row" fxFlex="auto" fxLayoutAlign="space-between center" fxLayoutGap="30px">
        <div>{{(!timeLogId) ? 'Create' : 'Update'}} Timelog</div>
        <div (click)="close(false)" class="cp"><img src="../../../assets/image/svg/close.svg" alt="" width="12px"></div>
    </div>
    <div class="body custom-scroll-thin" fxLayout="column" fxLayoutGap="0px">
        <!-- [formGroup]="timeLogCreateForm" -->
        <form fxFlex="auto" [formGroup]="timeLogCreateForm" fxLayout="column" fxLayoutGap="16px">

            <div *ngIf="type !== 'CLIENT' && (!hasPermission)" fxLayout="column" fxLayoutAlign="start start" fxFlex="auto" fxLayoutGap="8px">
                <div class="title">Task</div>
                <!-- <input formControlName="task" placeholder="Task Name" matInput> -->
                <!-- <mat-form-field appearance="outline">
                    <mat-label>Task Name</mat-label>
                    <mat-select formControlName="task_id" placeholder="Select Task">
                        <mat-option *ngFor="let task of taskList | sortBy:'last_accessed_at':'desc'" [value]="task.id">{{task.title}}</mat-option>
                    </mat-select>
                </mat-form-field> -->
                <ng-select [items]="taskList | sortBy:'last_accessed_at':'desc'" bindLabel="title" bindValue="id" placeholder="Select Task" formControlName="task_id" (clear)="taskCleared()"></ng-select>
            </div>
            <div fxLayout="row" fxLayoutGap="16px">
                <div fxLayout="column" fxLayoutAlign="start start" fxFlex="calc(50% - 8px)" fxLayoutGap="8px">
                    <div class="title">Projects</div>
                    <!-- <mat-form-field appearance="outline">
                        <mat-label>Projects</mat-label>
                        <mat-select formControlName="entity_id" placeholder="Select Projects">
                            <mat-option *ngFor="let project of projectList | sortBy:'updated_at':'desc'" [value]="project.id">{{project.name}}</mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    <ng-select [items]="projectList | sortBy:'updated_at':'desc'" bindLabel="name" bindValue="id" placeholder="Select Projects" formControlName="entity_id" (change)="projectChanged($event)"></ng-select>
                    <div class="msg-error" *ngIf="f.entity_id.touched && f.entity_id.errors?.required">
                        Project is Required
                    </div>
                </div>
            </div>
            <div *ngIf="type == 'CLIENT' || hasPermission"  fxLayout="column" fxLayoutAlign="start start" fxFlex="auto" fxLayoutGap="8px" >
                <div class="title">Clients</div>
                <!-- <input formControlName="task" placeholder="Task Name" matInput> -->
                <!-- <mat-form-field appearance="outline">
                    <mat-label>Task Name</mat-label>
                    <mat-select formControlName="task_id" placeholder="Select Task">
                        <mat-option *ngFor="let task of taskList | sortBy:'last_accessed_at':'desc'" [value]="task.id">{{task.title}}</mat-option>
                    </mat-select>
                </mat-form-field> -->
                <ng-select [items]="clientList | sortBy:'updated_at':'desc'" bindLabel="name" bindValue="id" placeholder="Select Client" formControlName="client_id" (clear)="clientCleared()" (open)="clientDropdownOpen()"></ng-select>
            </div>
            <div fxLayout="row" fxLayoutGap="16px">
                <div fxLayout="column" fxLayoutAlign="start start" fxFlex="calc(50% - 12px)" fxLayoutGap="8px">
                    <div class="title">Start Date</div>
                    <ah-date-picker (dateChange)="datePickerChange($event, 'start_date')" [config]="{ placeholder: 'Start Date', currentValue: f.start_date?.value, type:'date', maxDate: maxEndDate }"></ah-date-picker>
                </div>
                <div fxLayout="column" fxLayoutAlign="end start" fxFlex="calc(50% - 12px)" fxLayoutGap="8px">
                  <!-- <div class="title">End Date</div>
                  <ah-date-picker (dateChange)="datePickerChange($event, 'end_date')" [config]="{ placeholder: 'End Date', currentValue: f.end_date?.value, maxDate: maxEndDate, type:'date', minDate: f.start_date.value }"></ah-date-picker>
                  <div class="msg-error" *ngIf="f.end_date?.errors?.required">
                    End date is required.
                  </div> -->
                  <div class="checkbox">
                    <!-- <mat-checkbox [disabled]="checkDisabled" formControlName="is_next_day">Is next day</mat-checkbox> -->
                    <mat-checkbox formControlName="is_next_day">Is next day</mat-checkbox>
                  </div>
              </div>

            </div>
            <div fxLayout="row" fxLayoutGap="16px">
                <!-- <div fxLayout="column" fxLayoutAlign="start start" fxFlex="calc(50% - 12px)" fxLayoutGap="8px">
                    <div class="title">End Date</div>
                    <ah-date-picker (dateChange)="datePickerChange($event, 'end_date')" [config]="{ placeholder: 'End Date', currentValue: f.end_date?.value, maxDate: maxEndDate, type:'date', minDate: f.start_date.value }"></ah-date-picker>
                    <div class="msg-error" *ngIf="f.end_date?.errors?.required">
                      End date is required.
                    </div>
                </div> -->
                <div fxLayout="column" fxLayoutAlign="start start" fxFlex="calc(25% - 12px)" fxLayoutGap="8px">
                  <div class="title">Start Time</div>
                  <ah-time-picker (fromTime)="timePickerChange($event, 'start_time')" [config]="startTimeConfig"></ah-time-picker>
              </div>
                <div fxLayout="column" fxLayoutAlign="start start" fxFlex="calc(25% - 12px)" fxLayoutGap="8px">
                    <div class="title">End Time</div>
                    <ah-time-picker (fromTime)="timePickerChange($event, 'end_time')" [config]="endTimeConfig"></ah-time-picker>
                    <div class="msg-error" *ngIf="f.end_time?.errors?.required">
                      End time is required.
                    </div>
                </div>
            </div>
            <div class="msg-error" *ngIf="f.end_time?.errors?.isGreater">
              End time should greater than start time.
            </div>
            <div fxLayout="row" fxLayoutGap="16px">
              <div fxLayout="column" fxLayoutAlign="start start" fxFlex="calc(50% - 8px)" fxLayoutGap="8px">
                <div class="title">Category</div>
                <!-- <mat-form-field appearance="outline">
                    <mat-select formControlName="category" placeholder="Select Category">
                        <mat-option *ngFor="let category of categoryList" [value]="category.id">{{category.name}}</mat-option>
                    </mat-select>
                </mat-form-field> -->
                <ng-select [items]="categoryList" bindLabel="name" bindValue="id" placeholder="Select Category" formControlName="category"></ng-select>
            </div>
                <div fxLayout="column" fxLayoutAlign="start start" fxFlex="calc(50% - 8px)" fxLayoutGap="8px">
                    <div class="title">Scope</div>
                    <!-- <mat-form-field appearance="outline">
                        <mat-select formControlName="scope" placeholder="Select">
                            <mat-option *ngFor="let scope of scopeList" [value]="scope.id">{{scope.name}}</mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    <ng-select [items]="scopeList" bindLabel="name" bindValue="id" placeholder="Select Scope" formControlName="scope"></ng-select>
                </div>
                <!-- <div fxLayout="column" fxLayoutAlign="start start" fxFlex="calc(50% - 8px)" fxLayoutGap="8px">
                    <div class="title">Label</div>
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="entity_id" placeholder="Select">
                            <mat-option *ngFor="let project of projectList" [value]="project.id">{{project.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->
            </div>


            <div fxLayout="column" fxLayoutAlign="start start" fxFlex="auto" fxLayoutGap="8px">
                <div class="title">Description</div>
                <textarea formControlName="description"></textarea>
            </div>
            <div fxLayout="column" fxLayoutAlign="start start" fxFlex="auto" fxLayoutGap="8px">
                <div class="msg-error" *ngIf="f.description.touched && f.description.errors?.required">
                    Description is required.
                </div>
                <div class="msg-warning" *ngIf="f.description.touched && f.description.value?.length < 15 && f.description.value?.length > 0">
                  Please provide a more detailed description
                </div>
                <div class="msg-error" *ngIf="f.description.touched && f.description.errors?.maxlength">
                    Maximum allowed limit is 250 characters.
                </div>
            </div>

        </form>
        <div fxLayout="column" fxLayoutAlign="start start" fxFlex="auto" fxLayoutGap="8px" class="single-row">
            <div fxLayout="row" fxFlex="auto" fxLayoutAlign="end center" class="single-row" fxLayoutGap="8px">
              <div fxLayout="column" fxLayoutAlign="start start" fxFlex="auto" fxLayoutGap="8px">
                <div class="title">Duration: {{totalDuration}}</div>
            </div>
            <a class="button fill" fxLayout="column" fxFlex="100px" fxLayoutAlign="center center" *ngIf="timerState.details_id && !timerState.end_time" (click)="deleteTimeLog()">
              {{ !timeLogId ? 'Delete' : 'Discard'}}
            </a>
                <a class="button fill" fxLayout="column" fxFlex="100px" fxLayoutAlign="center center" (click)="createTask()">
                  {{ !timeLogId ? 'Save' : 'Update'}}
            </a>
            </div>
        </div>


    </div>
</div>
<ah-spinner *ngIf="requestInProgress" [config]="{fullscreen: true}"></ah-spinner>
<ng-template #deleteDialog let-data>
    <div class="timesheet" fxLayout="row" fxLayoutAlign="center center">
        <div class="timesheet-app">
            <mat-dialog-content>
                <div fxLayout="column" fxLayoutAlign="start start" class="text-center mt-1">
                    <p class="mt-2">
                        Are you sure you want to delete?
                    </p>
                </div>
            </mat-dialog-content>
            <mat-dialog-actions align="center">
                <div class="mt-3">
                    <button class="timesheet-btn solid blue-bg btn-color button create" matDialogClose (click)="closeDeleteConfirmation(timerState.details_id)">
            Yes
          </button> &nbsp;&nbsp;
                    <button class="timesheet-btn outline btn-color button create" matDialogClose (click)="closeDeleteConfirmation(null)">
            No
          </button>
                </div>
            </mat-dialog-actions>
        </div>
    </div>
</ng-template>
