import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { UploadAsset } from '../../interface/UploadAsset';

@Component({
  selector: 'ah-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent implements OnInit {

  @Input() showFileName: boolean = true;
  @Input() showFileCount: boolean = true;
  @Input() isView: boolean = false;
  @Input() uploadedAttachments: UploadAsset[] = [];
  @Output() emitAttachment = new EventEmitter();
  @Output() emitProgress = new EventEmitter();

  subscriptions: Subscription[] = [];

  showAttachmentProgress: boolean = false;
  closePopup:string = 'COMPLETED';

  constructor(
  ) { }

  ngOnInit(): void {
    console.log(this.showFileName, this.showFileCount);
  }

  closeAttachmentProgress(event){
    console.log(event)
    this.uploadedAttachments = event.asset;
    if(event.close){
      this.showAttachmentProgress = false;
      this.emitAttachment.emit(this.uploadedAttachments);
    }
  }

  showProgressBar() {
    this.showAttachmentProgress = true;
  }

  toggleProgressBar(event){
    if(event  || this.closePopup ==  'COMPLETED'){
      this.showAttachmentProgress = event;
    }
  }

  progress(event){
    this.closePopup = event.progress;
    console.log(event);
    this.emitProgress.emit(event);
  }

  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
