import { Directive, Input, ElementRef, Renderer2  } from '@angular/core';

@Directive({
  selector: '[ahTimeDuration]'
})
export class TimeDurationDirective {
  @Input() timeFormat: string = 'h:m'
  @Input() set ahTimeDuration(minutes: number) {
    this.updateElementText(minutes);
  }
 @Input() perDay : number = 8;
  constructor(private el: ElementRef, private renderer: Renderer2) { }

  private updateElementText(minutes: number) {
    
    const formattedTimeDuration = this.convertMinutesToTimeDuration(minutes);
   
    this.renderer.setProperty(this.el.nativeElement, 'textContent', formattedTimeDuration);
  }

  private convertMinutesToTimeDuration(minutes: number): string {
    if (!minutes || isNaN(Number(minutes)) || Number(minutes) < 0) {
      return "00:00:00";
  }
  
    const minutesPerHour = 60;
    const minutesPerDay = minutesPerHour * this.perDay;
    const minutesPerWeek = minutesPerDay * 7;

    let hours = Math.floor(minutes / minutesPerHour);
    let remainingMinutes = minutes % minutesPerHour;
   

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(remainingMinutes).padStart(2, '0');

    const timeformatArray = this.timeFormat.split(':')

    return `${formattedHours + timeformatArray[0]}:${formattedMinutes + timeformatArray[1]}`;

  }

}
