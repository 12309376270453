import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BoardApiService } from 'src/app/core/services/api/board-api/board-api.service';
import { AppToastrService } from 'src/app/core/services/toastr/app-toastr.service';
import { Subscription } from 'rxjs';
import { FORM_VALIDATION } from 'src/app/data/form-validation-config';
import { Client } from '../../interface/master-data';

@Component({
  selector: 'ah-project-create-popup',
  templateUrl: './project-create-popup.component.html',
  styleUrls: ['./project-create-popup.component.scss']
})
export class ProjectCreatePopupComponent implements OnInit, OnDestroy {
  clientList: Client[];
  projectCreateForm: FormGroup;
  errorMessage: string;

  subscriptions: Subscription[] = [];

  formValidation = FORM_VALIDATION;

  constructor(
    public dialogRef: MatDialogRef<ProjectCreatePopupComponent>,
    private fb: FormBuilder,
    private boardApiService: BoardApiService,
    private toast: AppToastrService,
  ) { }
  

  ngOnInit(): void {
    this.projectCreateForm = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(this.formValidation.max_length.title) ]],
      description: ['', Validators.maxLength(this.formValidation.max_length.description)],
      members:[''],
      client_id: [null, [Validators.required]] 
    });

    this.getClientSummary();
  }

  close() {
    this.dialogRef.close(status);
  }

  getClientSummary() {
    const payload = {
      filters: {       
        timesheet_filters: {
          start_date:"",
          end_date: "",
        },
        show_all: true,
      },
    };       
  
    const clientList = this.boardApiService.getClientSummary(payload).subscribe({
      next: (res) => {                  
           this.clientList = res.data.clients      
      },
      error: (error) => {
        console.error(error);
      },
    });
    this.setSubscription(clientList);
  }

  createProject() {

    const formData = this.projectCreateForm.value;
 

    if (!formData.title || !formData.client_id ) {
      this.toast.error('Please fill in all required fields.');
      return; 
    }

    let payload = {
      "type":null,
      "client_id":formData.client_id,
      "name":formData.title,
      "description": formData.description,
      "status":"ACTIVE",
      "members": formData.members
    }
   
   const projectCreate = this.boardApiService.createProject(payload).subscribe(
      {
        next: (res) => {         
          this.toast.success('Project Created successfully.');
          this.dialogRef.close('success'); 
          console.log(res);
          
        },
        error: (error) => {
          console.error(error);
          this.toast.error(error.message);
          this.errorMessage = error.message;
        },
      });
      this.setSubscription(projectCreate);
  } 
  cancel(): void {
    this.dialogRef.close();
}

setSubscription(request: Subscription): void {
  this.subscriptions.push(request);
}

ngOnDestroy(): void {
  this.subscriptions.forEach((subscription) => subscription.unsubscribe());
}

}
