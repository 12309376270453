import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import moment from 'moment';
import { DateRange, TimePeriod } from 'ngx-daterangepicker-material/daterangepicker.component';
import dayjs, { Dayjs } from 'dayjs/esm';
import { CommonUtilsService } from 'src/app/core/services/helper/common-utils.service';

interface DateRangePickerConfig {
  'minDate'?: any;
  'maxDate'?: any;
  'placeholder'?: string;
  'currentStartValue'?: any;
  'currentEndValue'?: any;
  'showClearButton'?: boolean;
  'clearButtonText'?: string;
  'showCancelButton'?: boolean;
  'cancelButtonText'?: string;
  'showApplyButton'?: boolean;
  'applyButtonText'?: string;
  'showCustomRangeLabel'?: boolean;
}

@Component({
  selector: 'ah-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})


export class DateRangePickerComponent implements OnInit {

  @Output() dateChange = new EventEmitter<{ startDate: string, endDate: string, label: string } | string>();
  @Input() showPicker: boolean = false;
  @Input() showCustomRanges: boolean = false;
  // @ViewChild(DaterangepickerDirective, { static: true }) pickerDirective: DaterangepickerDirective;
  // selectedDate: any;
  selectedDate: any = { startDate: null, endDate: null, label: null };

  ranges: any = {}

  actualRanges: any = {
    'Today': [dayjs(), dayjs()],
    'Yesterday': [dayjs().subtract(1, 'days'), dayjs().subtract(1, 'days')],
    'Last 7 Days': [dayjs().subtract(6, 'days'), dayjs()],
    'This Week': [dayjs().startOf('week'), dayjs().endOf('week')],
    'Last 30 Days': [dayjs().subtract(29, 'days'), dayjs()],
    'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
    'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    // 'Last 3 Month': [dayjs().subtract(3, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')]
  }

  invalidDates: dayjs.Dayjs[] = [];

  tooltips = [
  ];
  isShow: boolean = false;

  @Input() config: DateRangePickerConfig;
  defaultConfig = {
    'minDate': null,
    'maxDate': null,
    'placeholder': 'Select Range...',
    'currentStartValue': null,
    'currentEndValue': null,
    'showClearButton': false,
    'clearButtonText': null,
    'showCancelButton': false,
    'cancelButtonText': null,
    'showApplyButton': false,
    'applyButtonText': 'Apply',
    'showCustomRangeLabel': true
  }

  constructor(private commonUtils: CommonUtilsService) { }

  ngOnInit(): void {
    this.setConfig(this.config);
    this.isShow = true;
    this.ranges = this.actualRanges;
    if (this.showCustomRanges) {
      this.generateCustomRanges();
      // this.ranges = this.customRanges;
    }
  }

  generateCustomRanges(){
    const customRanges: any = {
      [this.commonUtils.getMonthYearKey()]: [dayjs().startOf('month'), dayjs().endOf('month')],
      [this.commonUtils.getMonthYearKey(1)]: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
      [this.commonUtils.getMonthYearKey(2)]: [dayjs().subtract(2, 'months').startOf('month'), dayjs().subtract(2, 'months').endOf('month')],
      // [`Q1-${this.currentYear}`]: [dayjs().startOf('year'), dayjs().startOf('year').add(2, 'months').endOf('month')],
      // [`Q2-${this.currentYear}`]: [dayjs().startOf('year').add(3, 'months'), dayjs().startOf('year').add(5, 'months').endOf('month')],
      // [`Q3-${this.currentYear}`]: [dayjs().startOf('year').add(6, 'months'), dayjs().startOf('year').add(8, 'months').endOf('month')],
      // [`Q4-${this.currentYear}`]: [dayjs().startOf('year').add(9, 'months'), dayjs().startOf('year').add(11, 'months').endOf('month')],
    }
    const quarter = this.commonUtils.generateQuarterRanges();
    this.ranges = {...customRanges, ...quarter};
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config.firstChange == false) {
      this.setConfig(this.config);
    }
  }

  setConfig(config?: DateRangePickerConfig) {
    const dataConfig = config ? Object.assign({}, this.defaultConfig, config) : this.defaultConfig;
    this.config = dataConfig;
    // console.log(this.config);

    if (this.config.currentStartValue) {
      this.selectedDate.startDate = dayjs(this.config.currentStartValue, 'DD-MM-YYYY');
    }
    if (this.config.currentEndValue) {
      this.selectedDate.endDate = dayjs(this.config.currentEndValue, 'DD-MM-YYYY');
    }
    if (!this.config.showCustomRangeLabel) {
      this.ranges = [];
    }
    // console.log(this.config, this.selectedDate);
  }

  isInvalidDate = (m: dayjs.Dayjs): boolean => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };

  isTooltipDate = (m: Dayjs): string | boolean | null => {
    const tooltip = this.tooltips.find((tt) => tt.date.isSame(m, 'day'));
    if (tooltip) {
      return tooltip.text;
    } else {
      return false;
    }
  };

  rangeClicked(range: DateRange): void {
    // eslint-disable-next-line no-console
    console.log('[rangeClicked] range is : ', range);
  }

  datesUpdated(range: TimePeriod): void {
    // eslint-disable-next-line no-console
    // console.log('[datesUpdated] range is : ', range, this.selectedDate);
    if (this.selectedDate && !!this.selectedDate.startDate && !!this.selectedDate.endDate) {
      this.formatDate();
    }
  }

  pickerDatesUpdated(range: TimePeriod) {
    // console.log('[datesUpdated] range is : ', range, this.selectedDate);
    this.selectedDate.startDate = range.startDate;
    this.selectedDate.endDate = range.endDate;
    this.selectedDate.label = range.chosenLabel;
    this.formatDate();

  }

  formatDate() {
    const startDateFormatted = this.selectedDate.startDate.format('DD-MM-YYYY');
    const endDateFormatted = this.selectedDate.endDate.format('DD-MM-YYYY');
    this.dateChange.emit({ 'startDate': startDateFormatted, 'endDate': endDateFormatted, 'label': this.selectedDate.label })
    // console.log(startDateFormatted, endDateFormatted);
  }

}
