import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../../http-client/http-client.service';

@Injectable({
  providedIn: 'root'
})
export class CommonApiService {

  constructor(private httpService: HttpClientService) { }

  createSignedAsset(count: number = 1, entity: string = 'others') {
    let params = new HttpParams()
      .set('count', count.toString())
      .set('entity', entity);
    return this.httpService.get(`${environment.BASE_URL}/v1/asset/upload?${params.toString()}`);
  }

  signedAssetUpload(url: string, payload: any) {
    return this.httpService.postWithProgress(url, payload);
  }

  mapSignedAsset(payload) {
    return this.httpService.post(environment.BASE_URL + '/v1/asset/save', payload);
  }

  getBlob(url: string) {
    return this.httpService.getBlob(url);
  }

}
