import { Injectable } from '@angular/core';
import { DataStateService } from '../data-state.service';
import { BehaviorSubject, Observable } from 'rxjs';


interface CommonState {
  currentData: any;
  currentPlanningTask: any;
  currentLoaderStatus: boolean;
  currentTimeLogDate: any;
  sideNavStatus: boolean;
  timelogActionType: string;
  timelogId: string;

}

@Injectable({
  providedIn: 'root'
})
export class CommonDataService extends DataStateService<CommonState> {


  constructor(

  ) {
    super();
    const initialState: CommonState = {
      currentData: false,
      currentPlanningTask: [],
      currentLoaderStatus: false,
      currentTimeLogDate: null,
      sideNavStatus: false,
      timelogActionType: null,
      timelogId: null
    };
    this.state$ = new BehaviorSubject<CommonState>(initialState);
  }

  setLoaderStatus(status: boolean): void {
    this.setState({ currentLoaderStatus: status });
  }

  setPlanningTaskId(taskId): void {
    this.setState({ currentPlanningTask: taskId });
  }

  setTimeLogAction(actionType: string): void {
    this.setState({ timelogActionType: actionType });
  }

  setTimeLogId(timelogId: string): void {
    this.setState({ timelogId: timelogId });
  }

  addTimeLog(status: boolean): void {
    this.setState({ currentData: status });
  }

  addTimeLogDate(date: any): void {
    this.setState({ currentTimeLogDate: date });
  }

  siteNavtoggleclose(): void {
    this.setState({ sideNavStatus: false });
  }

  siteNavtoggleStaus(status: boolean): void {
    this.setState({ sideNavStatus: status });
  }

}
