export const ASSET_EXTENSION = {
	allowed_video_mime_type: [
		'mp4'
	],
	allowed_image_mime_type: [
		'jpg' , 'jpeg', 'png', 'bmp', 'gif'
	]
}

export const ASSET_MIMETYPE = [
  {
    name: 'doc',
    mime: 'vnd.openxmlformats-officedocument.wordprocessingml.document',
    src: 'docs.svg',
    ext: 'doc'
  },
  {
    name: 'xlsx',
    mime: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    src: 'xls.svg',
    ext: 'xlsx'
  },
  // {
  //   name: 'xlx',
  //   mime: 'vnd.ms-excel',
  //   src: 'xls.svg',
  //   ext: 'xlx'
  // },
  {
    name: 'csv',
    mime: 'csv',
    src: 'xls.svg',
    ext: 'csv'
  },
  {
    name: 'png',
    mime: 'png',
    src: 'docs.svg',
    ext: 'png'
  },
  {
    name: 'jpeg',
    mime: 'jpeg',
    src: 'docs.svg',
    ext: 'jpeg'
  },
  {
    name: 'pdf',
    mime: 'pdf',
    src: 'pdf.svg',
    ext: 'pdf'
  }
];
