import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ah-single-select-dropdown',
  templateUrl: './single-select-dropdown.component.html',
  styleUrls: ['./single-select-dropdown.component.scss']
})
export class SingleSelectDropdownComponent implements OnInit, OnDestroy {

  @Output() selectedOptions = new EventEmitter<any>();
  @Output() createClicked = new EventEmitter<any>();
  @Input() itemList: any = [];
  @Input() displayText: string = 'placeholder';
  @Input() selectedItem: string = null;
  @Input() bindLabelText: string = 'title';
  @Input() bindValueText: string = 'id';
  @Input() placeholder: string = 'Select Text';
  @Input() notFoundText: string = 'No data found';
  @Input() isError: boolean = false;
  @Input() showCreate: boolean = false;
  @Input() showItem: boolean = false;
  subscriptions: Subscription[] = [];
  showCreateOption: boolean = false;
  nameText: string = null;

  constructor() { }

  ngOnInit(): void {
    // console.log(this.isError)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.showItems?.currentValue) {
      setTimeout(() => {
        this.toggleSelection(true);
      }, 30);
    }
  }

  displayTextFn() {
    const expenseExist = this.itemList.find((x) => x[this.bindValueText] === this.selectedItem);
    return expenseExist ? expenseExist[this.bindLabelText] : '';
  }

  toggleSelection(event) {
    this.showItem = event;
    if (!this.showItem) {
      this.ngOnDestroy();
    }
  }

  optionSelected(event) {
    this.toggleSelection(false);
    this.selectedOptions.emit(this.selectedItem);
    // console.log(event, this.selectedItem);
  }

  openCreate() {
    this.showCreateOption = true;
    // this.toggleSelection(false);
    // this.selectedOptions.emit(true);
  }

  create() {
    if (this.nameText?.length > 3) {
      this.createClicked.emit(this.nameText);
      this.nameText = null;
    }
  }

  clear() {
    this.showCreateOption = false;
  }

  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }


}
