import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../../http-client/http-client.service';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {

  constructor(private httpService: HttpClientService) { }

  changePassword(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/auth/change/password', payload);
  }

  getProfileInfo(): Observable<any> {
    return this.httpService.get(environment.BASE_URL + '/v1/user/profile');
  }

}
