import { ErrorStateMatcher } from '@angular/material/core';
import { FormGroupDirective, FormControl, FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

/**
 * Custom ErrorStateMatcher which returns true (error exists) when the parent form group is invalid and the control has been touched
 */
export class ConfirmValidParentMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | null): boolean {
			return control.parent.invalid && control.touched;
	}
}

	/**
	 *
	 * @param frm FormGroup
	 *
	 * Custom validator to check given password and confirm password are same.
	 */
	export const passwordMatchValidator = (frm: FormGroup) => {
		return frm.controls['password'].value === frm.controls['password_confirmation'].value ? null : {'mismatch': true};
	}

type ValidationErrors = {
    [key: string]: any;
};

export class GreaterValidator {

  static isGreater(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (control.value && matchingControl.value && control.value >= matchingControl.value) {
        matchingControl.setErrors({ isGreater: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  static checkTimeDiffrence(start_time: string, end_time: string, start_date:string, end_date:string) : ValidationErrors | null{
    return (formGroup: FormGroup) => {
      const startTime = formGroup.controls[start_time];
      const endTime = formGroup.controls[end_time];
      const startDate = formGroup.controls[start_date];
      const endDate = formGroup.controls[end_date];
      // console.log(startTime.value && endTime.value && new Date(startDate.value + ' ' + startTime.value + ':00').getTime() < new Date(endDate.value + ' ' + endTime.value + ':00').getTime());
      if (startTime.value && endTime.value && new Date(startDate.value + ' ' + startTime.value + ':00').getTime() >= new Date(endDate.value + ' ' + endTime.value + ':00').getTime()) {
        endTime.setErrors({ isGreater: true });
      } else if (startTime.value && endTime.value && new Date(startDate.value + ' ' + startTime.value + ':00').getTime() < new Date(endDate.value + ' ' + endTime.value + ':00').getTime()) {
        endTime.setErrors(null);
      }
      // else {
      //   endTime.setErrors(null);
      // }
    };
  }
}

export class anyOneFieldIsRequired {
  static isRequired(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (!control.value && !matchingControl.value) {
        matchingControl.setErrors({ isRequired: true });
      } else {
        matchingControl.setErrors(null);
      }
      // if (!control && !matchingControl) {
      //   return { expenseOrCategoryRequired: true };
      // }

      // return null;
    };
  }
}


  // export function startTimeBeforeEndTimeValidator(): ValidatorFn {
  //   return (control: AbstractControl): { [key: string]: any } | null => {
  //     const startTime = control.get('start_time')?.value;
  //     const endTime = control.get('end_time')?.value;
  //     if (startTime && endTime && startTime >= endTime) {
  //       return { 'startTimeBeforeEndTime': true };
  //     }
  //     return null;
  //   };
  // }
