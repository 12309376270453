import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PasswordValidator } from 'src/app/plugins/validators/password-validator';
import { AppToastrService } from 'src/app/core/services/toastr/app-toastr.service';
import { Router } from '@angular/router';
import { UserApiService } from 'src/app/core/services/api/user-api/user-api.service';
import { FORM_VALIDATION } from 'src/app/data/form-validation-config';
import { MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'ah-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChangePasswordComponent implements OnInit {
  @ViewChild(MatMenuTrigger) passwordPolicy: MatMenuTrigger;
  subscriptions: Subscription[] = [];
  changePasswordForm!: FormGroup;
  isLeaveInput: boolean = false;
  showPasswordConfirm: boolean = false;
  showNewPassword: boolean = false;
  showOldPassword: boolean = false;
  formValidation = FORM_VALIDATION;
  passwordsMatch = false;
  requestInProgress: boolean = false;
  showPassword: boolean = false;
  accessKeySubscription: Subscription;
  // formBuilder: any;

  constructor(
    private formBuilder: FormBuilder,
    private toast: AppToastrService,
    private router: Router,
    private userApi: UserApiService,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,

  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {

    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['',
        Validators.compose([Validators.required,
        PasswordValidator.patternValidator(this.formValidation.validation_regex.number_only, {
          hasNumber: true
        }),
        PasswordValidator.patternValidator(this.formValidation.validation_regex.upper_case_only, {
          hasCapitalCase: true
        }),
        PasswordValidator.patternValidator(this.formValidation.validation_regex.lower_case_only, {
          hasSmallCase: true
        }),
        PasswordValidator.patternValidator(
          this.formValidation.validation_regex.special_character,
          {
            hasSpecialCharacters: true
          }),
        Validators.minLength(8),
        Validators.maxLength(25),
        ])],
      confirmPassword: ['', [Validators.required]],
    }, {
      validator: PasswordValidator.confirmedValidator('newPassword', 'confirmPassword')
    });

  }

  get f() {
    return this.changePasswordForm.controls;
  }

  newPasswordChange(status: boolean) {
    this.isLeaveInput = status;
  }

  changeUserPassword() {

    if (this.changePasswordForm.invalid) {
      this.changePasswordForm.markAllAsTouched();
      return;
    }

    this.requestInProgress = true;

    let payload = {
      "current": this.changePasswordForm.value.oldPassword,
      "password": this.changePasswordForm.value.newPassword,
      "password_confirmation": this.changePasswordForm.value.confirmPassword,
    }
    // console.log(payload);
    const request = this.userApi.changePassword(payload).subscribe(
      {
        next: (data) => {
          // console.log(data);
          this.changePasswordForm.reset();
          this.requestInProgress = false;
          this.toast.success('Password changed successfully');
          this.close();
          // this.router.navigate(['/login']);
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.data.errors[0])
        },
      }
    )
    this.setSubscription(request);

  }

  close(status?) {
    this.dialogRef.close(status);
  }

  openPolicyMenu() {
    (this.passwordPolicy.menuOpen) ? this.passwordPolicy.openMenu() : this.passwordPolicy.closeMenu();
	}

  closePolicyMenu() {
    // console.log(this.passwordPolicy.menuOpen);
		this.passwordPolicy.closeMenu();
	}

  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }


}
