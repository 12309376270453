import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UploadFileValidationService {

  constructor() { }

  validateFile(fileObj: any, extensions) {
    // console.log(fileObj);
    let status = true;
    let mimeType = fileObj.type.split('/');
    mimeType = mimeType[mimeType.length - 1].toLowerCase();
    // console.log(mimeType, extensions.includes(mimeType));
    if (extensions.includes(mimeType)) {
      status = false;
    }
    return status;
  }
}
