import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomDatePipe } from './custom-date/custom-date.pipe';
import { EllipsisPipe } from './ellipsis/ellipsis.pipe';
import { TimeAgoPipe } from './time-ago/time-ago.pipe';
import { RemoveUnderScorePipe } from './remove-under-score/remove-under-score.pipe';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { TextMaskPipe } from './mask/text-mask.pipe';
import { ObsWithLoadingPipe } from './obs-with-loading/obs-with-loading.pipe';
import { SortByPipe } from './sort-by/sort-by.pipe';
import { FirstletterPipe } from './firstletter/firstletter.pipe';
import { FilenameEllipsisPipe } from './ellipsis/filename-ellipsis.pipe';
import { CapitalizePipe } from './capitalize/capitalize.pipe';
import { NumberSuffixPipe } from './conversion/number-suffix/number-suffix.pipe';



@NgModule({
  declarations: [
    CustomDatePipe,
    EllipsisPipe,
    TimeAgoPipe,
    RemoveUnderScorePipe,
    SafeHtmlPipe,
    TextMaskPipe,
    ObsWithLoadingPipe,
    SortByPipe,
    FirstletterPipe,
    FilenameEllipsisPipe,
    CapitalizePipe,
    NumberSuffixPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
		CustomDatePipe,
    EllipsisPipe,
    TimeAgoPipe,
    RemoveUnderScorePipe,
    SafeHtmlPipe,
    TextMaskPipe,
    ObsWithLoadingPipe,
    SortByPipe,
    FirstletterPipe,
    FilenameEllipsisPipe,
    CapitalizePipe,
    NumberSuffixPipe
  ]
})
export class PipesModule { }
