import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';
import { ObsWithLoadingPipe } from '../../pipes/obs-with-loading/obs-with-loading.pipe';
import { Observable, Subscription, debounceTime, distinctUntilChanged, map, of } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BoardApiService } from 'src/app/core/services/api/board-api/board-api.service';

@Component({
  selector: 'ah-task-search',
  templateUrl: './task-search.component.html',
  styleUrls: ['./task-search.component.scss'],
  providers: [ObsWithLoadingPipe],
})
export class TaskSearchComponent implements OnInit {


  subscriptions: Subscription[] = [];
  // @ViewChild('searchInput', { static: false }) searchInputRef!: ElementRef;
  @Output() selectedOption  = new EventEmitter();
  taskList$: Observable<any>;
  taskSearchForm: FormGroup;
  isShowDropDown: boolean = false;
  searchResult: boolean = true;
  constructor(
    private formBuilder: FormBuilder,
    private obsPipe: ObsWithLoadingPipe,
    private boardApi: BoardApiService,
    private elementRef: ElementRef
  ) { }

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: any) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.resetSearchField();
    }
  }

  ngOnInit(): void {
    this.taskSearchForm = this.formBuilder.group({
      keyword: [''],
    });
    // this.taskList$ = this.f.keyword.valueChanges.pipe(
    //   startWith(''),
    //   debounceTime(400),
    //   distinctUntilChanged(),
    //   switchMap((val) => {
    //     return this.filterTask(val || '');
    //   })
    // );
    this.taskSearchForm.get('keyword').valueChanges.pipe(debounceTime(500),distinctUntilChanged()).subscribe((val) => {
      // console.log(this.taskSearchForm.get('keyword').value, val)
      if (val?.length >= 3) {
        // console.log('hellow');
        this.taskList$ = this.filterTask(val);
      } else {
        this.taskList$ = of({ loading: false, value:[] });
      }
      if (val?.length == 0) {
        this.taskList$ = this.filterTask(val);
        // this.resetSearch();
      }
    });
  }

  get f() {
    return this.taskSearchForm.controls;
  }

  filterTask(val: any): Observable<any> {
    if(val == ''){
      return of({ loading: false, value:[] })
    }
    // return this.storiesAPI.filterUserList({ keyword: val })
    const searchValue = typeof val == 'object' ? val.title : val;
    this.searchResult = true;
    return this.obsPipe
      .transform(
        this.boardApi.getTaskList({
          filters: {
            keyword: searchValue,
          },
        })
      )
      .pipe(
        map((response: any) => {
          if (response.loading == true) {
            return { ...response, value: [] };
          } else {
            const value = response.value.data.tasks.filter((option) => {
              return (
                // option.task.title.toLowerCase().indexOf(searchValue.toLowerCase()) === 0
                option.task
              );
            });
            this.searchResult = (value.length == 0) ? false : true;
            return { ...response, value };
          }
        })
      );
  }

  initiateTask(event){
    // console.log(event);
    this.selectedOption.emit(event);
    this.resetSearchField();
    // this.menuTrigger.closeMenu();

  }

  resetSearch(){
    this.resetSearchField();
    // this.searchResult = [];
  }

  resetSearchField() {
    this.taskSearchForm.get('keyword').setValue('');
    // console.log(this.taskSearchForm.value.keyword);
  }

  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
