import { CommonUtilsService } from 'src/app/core/services/helper/common-utils.service';
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BoardStateService } from 'src/app/core/services/data/board-state/board-state.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { Subscription, interval } from 'rxjs';
import { BoardApiService } from 'src/app/core/services/api/board-api/board-api.service';
import { AppToastrService } from 'src/app/core/services/toastr/app-toastr.service';
import { GreaterValidator } from 'src/app/plugins/validators/custom-form-validation';
import { Category, Project, Scope, Client } from '../../interface/master-data';
import { MatDialog } from '@angular/material/dialog';
import { TimelogApiService } from 'src/app/core/services/api/timelog-api/timelog-api.service';
import { TimePickerConfig } from '../../interface/timer-interface';
import { PermissionService } from 'src/app/core/services/permission/permission.service';
import { CommonDataService } from 'src/app/core/services/data/common-data/common-data.service';

@Component({
  selector: 'ah-timelog-create-popup',
  templateUrl: './timelog-create-popup.component.html',
  styleUrls: ['./timelog-create-popup.component.scss']
})
export class TimelogCreatePopupComponent implements OnInit {

  subscriptions: Subscription[] = [];
  requestInProgress: boolean = false;
  projectList: Project[] = [];
  timeLogCreateForm: FormGroup;
  categoryList: Category[] = [];
  scopeList: Scope[] = [];
  endMinTime: any = moment(new Date()).format('HH:mm');
  taskList: any[] = [];
  timeLogId: string = '';
  isUpdate: boolean = false;
  totalDuration: string = '00:00';
  taskId: string = '';
  isProjectPreSelected: boolean = false;
  timerState: any;
  @ViewChild('deleteDialog') deleteDialog: TemplateRef<any>;
  endTimeChecker: any = null;
  // maxEndDate: string = moment(new Date()).add(1, 'day').format('YYYY-MM-DD');
  maxEndDate: string = moment(new Date()).format('YYYY-MM-DD');
  maxStartTime: string = moment(new Date()).format('HH:mm');
  startTimeConfig: TimePickerConfig = {};
  endTimeConfig: TimePickerConfig = {};
  isCurrentStartTime: boolean = true;
  isCurrentEndTime: boolean = false;
  projectwithclient: any;

  clientList: Client[]=[];
  type: string;
  hasPermission: boolean = false;
  clientId: any;
  originalData:any[] = [];
  private isProgrammaticChange: boolean = false;

  constructor(
    private timeLogApiService: TimelogApiService,
    private boardState: BoardStateService,
    private boardApi: BoardApiService,
    private dialog: MatDialog,
    private toast: AppToastrService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<TimelogCreatePopupComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private commonUtil: CommonUtilsService,
    private permissionService: PermissionService,
    private commonState: CommonDataService
  ) { }



  ngOnInit(): void {
    this.timeLogCreateForm = this.fb.group({
      client_id:[null],
      task_id: [null],
      entity_id: [null],
      entity_type: ['PROJECT'],
      description: ['', [Validators.minLength(15), Validators.maxLength(250)]],
      category: [null],
      start_date: [moment(new Date()).format('YYYY-MM-DD'), [Validators.required]],
      start_time: [moment(new Date()).format('HH:mm')],
      start_time_sec: [moment(new Date()).format('HH:mm:ss')],
      end_time: [''],
      end_time_sec: [''],
      duration: [''],
      label: [''],
      scope: [null],
      id: [''],
      end_date: [''],
      is_next_day: [false]
    }, {
      // validator: GreaterValidator.isGreater('start_time', 'end_time')
      validator: GreaterValidator.checkTimeDiffrence('start_time', 'end_time', 'start_date', 'end_date')
    });
    this.listClient();
    this.listProject();
    this.listCategory();
    this.listScope();
    this.listTask();
    if(this.data?.startDate && this.data?.isTimelog){
      // console.log(this.data.startDate);
      this.timeLogCreateForm.get('start_date').setValue(moment(new Date(this.data?.startDate))?.format('YYYY-MM-DD'));
      this.setTimeConfig();
      // console.log(this.timeLogCreateForm.controls);
    }
    if (this.data && Object.keys(this.data).length > 1 && !this.data?.isTimelog) {
      // console.log(this.data);

      this.patchFormData();
    }
    const taskIdChanges = this.timeLogCreateForm.get('task_id').valueChanges.subscribe((data) => {
      if(data){
        this.setCategoryAndProject(data);
      }
    });
    this.setSubscription(taskIdChanges);

    const timerState = this.boardState.select('currentTimerLog').subscribe(res => {
      this.timerState = res;
      if( res.entity_type == 'CLIENT' && this.data?.payload) {
        this.clientId = res.entity_id
      }



    });
    this.setSubscription(timerState);
    this.setTimeConfig();
    this.getcheckDisabled();
    /** Update end time when timer end */
    this.endTimeChecker = interval(1000).subscribe(() => {
      if (this.data && Object.keys(this.data).length > 1 && !this.data?.isUpdate && this.data.end_time) {
        // this.timeLogCreateForm.get('end_time').setValue(moment(new Date()).format('HH:mm'));
        // this.timeLogCreateForm.get('end_time_sec').setValue(moment(new Date()).format('HH:mm:ss'));
      }
      this.maxStartTime = moment(new Date()).format('HH:mm');
      this.setTimeConfig();
    });
    this.timeLogCreateForm.get('start_date').valueChanges.subscribe(value => {
      if (value && this.changeDateFormat(value) < this.changeDateFormat(new Date())) {
        // this.timeLogCreateForm.get('end_date').setValidators([Validators.required]);
        // this.timeLogCreateForm.controls['end_time'].setValidators([Validators.required]);
        this.isCurrentStartTime = false;
      } else {
        // this.timeLogCreateForm.get('end_date').clearValidators();
        this.timeLogCreateForm.get('end_time').clearValidators();
        // this.isCurrentStartTime = true;
      }
      this.timeLogCreateForm.get('end_time').updateValueAndValidity();
      // this.timeLogCreateForm.get('end_date').updateValueAndValidity();
      this.getcheckDisabled();
    });
    this.timeLogCreateForm.get('is_next_day').valueChanges.subscribe(value => {
      const startDate = this.f.start_date?.value;
      if(value){
        this.timeLogCreateForm.get('end_date').setValue(moment(new Date(startDate)).add(1, 'day').format('YYYY-MM-DD'))
      } else {
        this.timeLogCreateForm.get('end_date').setValue(moment(new Date(startDate)).format('YYYY-MM-DD'))
      }
      this.setTimeConfig();
      this.setTotalDuration();
    });

   if(this.data) {
    this.type =this.data?.payload?.entity_type;
   }

   const moduleEntries = Object.entries(this.permissionService.getPermission());
    // console.log(moduleEntries);
    this.hasPermission = moduleEntries.some(([key, value]) => {
      return key === 'clientView';
    });
    this.timeLogCreateForm.get('client_id').valueChanges.subscribe(newValue => {
      if (this.isProgrammaticChange) {
        this.isProgrammaticChange = false;
        return;
      }
      this.clientId = newValue;
      // this.clientId = newValue;
      // if(this.data?.payload?.entity_id || this.data?.payload?.entity_type == null ) {
      //   this.isProgrammaticChange = true;
      //   this.timeLogCreateForm.get('entity_id').setValue(null);
      // }

      const shouldClearEntityId = this.data?.payload?.entity_id || this.data?.payload?.entity_type === null;
      if (shouldClearEntityId || !this.data?.project_id) {
        this.isProgrammaticChange = true;
        this.timeLogCreateForm.get('entity_id').setValue(null);
      } else if (this.data?.project_id) {
        this.timeLogCreateForm.get('entity_id').setValue(this.data.project_id);
      }

      this.projectFromCleintIDclient()
    });


    const entityIdChanges = this.timeLogCreateForm.get('entity_id').valueChanges.subscribe(value=>{
      if (this.isProgrammaticChange) {
        this.isProgrammaticChange = false;
        return;
      }
     this.checkAndUpdateProjectClientId(value)
    });
    this.setSubscription(entityIdChanges);
  }

  clientDropdownOpen(){
    this.data.project_id = null;
  }

  checkAndUpdateProjectClientId(value) {
    const projectId = value;
    const projectData = this.originalData.find(project => project.id === projectId);

    if(projectData && projectData.client_info && this.hasPermission){

      this.isProgrammaticChange = true;
      this.clientId = projectData.client_info.id;
      this.timeLogCreateForm.get('client_id').setValue(projectData.client_info.id);
      this.projectFromCleintIDclient()
    }
  }


  projectChanged(event) {
    if (event?.id && this.isTaskIsMappedToProject(this.timeLogCreateForm.get('task_id').value, event?.id)) {
      // console.log('isValue => ' + event.id);
      this.timeLogCreateForm.get('task_id').setValue(null);
    }
  }

  isTaskIsMappedToProject(taskId, projectId) {
    let task = this.taskList.find(x => x.id == taskId);
    // console.log(tas[kId, projectId, (task?.entity_info?.id !== projectId));
    // console.log(task);
    return task?.entity_info?.id !== projectId ? true : false;
  }

  taskCleared(){
    this.timeLogCreateForm.get('entity_id').enable();
    this.timeLogCreateForm.get('entity_id').setValue(null);
  }

  clientCleared() {
    this.timeLogCreateForm.get('entity_id').setValue(null);
  }

  getcheckDisabled() {
    const startDate = this.f.start_date?.value;
    const endDate = this.f.end_date?.value;

    if (this.isStartDateDifferentFromToday(startDate) || this.isUpdate) {
      this.enableNextDayField();

      // if (this.isStartDateBeforeEndDate(startDate, endDate) || !endDate) {
      if (this.isStartDateBeforeEndDate(startDate, endDate) && endDate) {
        this.setTimeLogNextDayValue(true);
      }
    } else {
      this.setTimeLogNextDayValue(false);
      this.disableNextDayField();
    }
  }

  isStartDateDifferentFromToday(startDate) {
    const formattedStartDate = this.changeDateFormat(startDate);
    const formattedToday = this.changeDateFormat(new Date());
    return formattedStartDate !== formattedToday;
  }

  enableNextDayField() {
    this.timeLogCreateForm.get('is_next_day').enable();
  }

  isStartDateBeforeEndDate(startDate, endDate) {
    const formattedStartDate = this.changeDateFormat(startDate);
    const formattedEndDate = this.changeDateFormat(endDate);
    return formattedStartDate < formattedEndDate;
  }

  setTimeLogNextDayValue(value) {
    this.timeLogCreateForm.get('is_next_day').setValue(value);
  }

  disableNextDayField() {
    this.timeLogCreateForm.get('is_next_day').disable();
  }

  setTimeConfig() {
    const startDate = this.f.start_date?.value;
    const endDate = this.f.end_date?.value;
    this.startTimeConfig = {
      placeholder: 'Start Time',
      currentValue: this.f.start_time?.value,
      startTime: '00:00',
      endTime: this.maxStartTime,
      startDate: this.f.start_date?.value,
      endDate: this.f.end_date?.value,
      isUpdate: this.isUpdate,
      type: 'START_TIME'
    }
    this.endTimeConfig = {
      placeholder: 'End Time',
      startTime: this.f.start_time?.value,
      endTime: this.maxStartTime,
      currentValue: this.f.end_time?.value,
      startDate: this.f.start_date?.value,
      endDate: this.f.end_date?.value,
      isUpdate: this.isUpdate,
      type: 'END_TIME',
      showIcon: true
    }
    if (startDate && endDate && this.changeDateFormat(startDate) == this.changeDateFormat(endDate)) {
      this.startTimeConfig.minTime = '00:00';
      this.startTimeConfig.maxTime = this.startTimeConfig.endTime;
      this.endTimeConfig.minTime = this.endTimeConfig.startTime;
      this.endTimeConfig.maxTime = this.endTimeConfig.endTime;
    } else if (startDate && endDate && this.changeDateFormat(startDate) != this.changeDateFormat(endDate) && this.changeDateFormat(endDate) == this.changeDateFormat(startDate, 'add')) {
      this.startTimeConfig.minTime = '00:00';
      this.startTimeConfig.maxTime = '23:59';
      this.endTimeConfig.minTime = '00:00';
      this.endTimeConfig.maxTime = (this.changeDateFormat(endDate) == this.changeDateFormat(startDate, 'add')) ? this.endTimeConfig.endTime : '23:59';
    }
    if (startDate && this.changeDateFormat(startDate) < this.changeDateFormat(new Date())){
      this.startTimeConfig.maxTime = '23:59';
      this.endTimeConfig.maxTime = '23:59';
    }
    if (endDate && this.changeDateFormat(endDate) < this.changeDateFormat(new Date())){
      this.endTimeConfig.minTime = '00:00';
      this.endTimeConfig.maxTime = '23:59';
    }
    // console.log(this.endTimeConfig);

  }

  setCategoryAndProject(data) {
    let task = this.taskList.find(x => x.id == data);
    if (task) {
      if (task.entity_info?.id) {
        this.timeLogCreateForm.get('entity_id').setValue(task.entity_info.id);
        this.timeLogCreateForm.get('entity_id').disable();
      } else {
        this.timeLogCreateForm.get('entity_id').enable();
        this.timeLogCreateForm.get('entity_id').setValue(null);
      }
      this.timeLogCreateForm.get('entity_id').updateValueAndValidity();
      if (task.category?.id) {
        this.timeLogCreateForm.get('category').setValue(task.category.id);
      }
    }
  }

  patchFormData() {

    this.timeLogCreateForm.patchValue({
      duration: (this.data.total_duration) ? this.data.total_duration : '',
      start_time: this.data.payload?.start_time,
      start_time_sec: this.data.payload?.start_time_sec,
      end_time: (this.data.end_time) ? this.data.end_time : this.data.payload.end_time,
      end_time_sec: (this.data.end_time_sec) ? this.data.end_time_sec : this.data.payload.end_time_sec,
      start_date: this.data.payload?.start_date,
      end_date: (this.data.end_date) ? this.data.end_date : this.data.payload.end_date,
      entity_id:(this.data.payload?.entity_type =='PROJECT') ? this.data.payload?.entity_id : null,
      client_id:(this.data.payload?.entity_type =='CLIENT') ? this.data.payload?.entity_id : null,
      entity_type: (this.data.payload?.entity_type) ? this.data.payload?.entity_type : (this.data.payload?.entity_id) ? 'PROJECT' : null,
      category: this.data.payload?.category?.id,
      description: this.data.payload?.description,
      id: this.data.payload?.details_id,
      task_id: this.data.payload?.task_id,
      scope: this.data.payload?.scope?.id,

    });
    this.isCurrentEndTime = this.data.current_end_time ? this.data.current_end_time : false;
    this.isCurrentStartTime = this.data.payload.current_start_time ? this.data.payload.current_start_time : false ;
    this.timeLogId = this.data.payload.details_id;
    this.isUpdate = this.data.isUpdate;
    // this.endMinTime = (this.data.end_time) ? this.data.end_time : this.data.payload.end_time;
    this.endMinTime = this.timeLogCreateForm.value.start_time;
    this.totalDuration = (this.timeLogCreateForm.value.end_time && this.timeLogCreateForm.value.start_time) ? this.calculateTimeDifference() : '00:00';
    // this.timeLogCreateForm.get('end_time').setValidators([Validators.required]);
    const startDate = this.f.start_date?.value;
    const endDate = this.f.end_date?.value;
    if(this.changeDateFormat(startDate) < this.changeDateFormat(endDate)){
      this.timeLogCreateForm.get('is_next_day').setValue(true);
    }
    if(this.data.end_time || this.data.payload.end_time){
      this.setDescriptionValidation();
    }
    if(this.data?.payload?.entity_type == 'PROJECT' && this.data?.payload?.entity_info?.client_info) {
      this.timeLogCreateForm.get('client_id').setValue(this.data?.payload?.entity_info?.client_info?.id);
    }
    this.clientId = this.timeLogCreateForm.value.client_id;
    this.projectFromCleintIDclient();


  }

  setDescriptionValidation(){
    this.timeLogCreateForm.get('description').setValidators([Validators.required, Validators.maxLength(250)]);
    this.timeLogCreateForm.get('description').updateValueAndValidity();
  }

  mappedTask() {
    let taskInfo = this.data?.payload?.task_info;
    if (taskInfo) {
      // const index = this.taskList.findIndex(group => group.options.some(option => option.id === taskInfo.id));
      const index = this.taskList.findIndex(x=>x.id = taskInfo.id);
      if (index === -1) {
        // const newGroup = {
        //   name: 'mapped',
        //   options: [taskInfo]
        // };
        this.taskList.push(taskInfo);
      }
    }
  }

  listTask() {
    let payload = {
      "filters": {
        "request_type": "TASK",
        "show_all": true
      }
    }
    const taskList = this.boardApi.getTaskList(payload).subscribe(
      {
        next: (res) => {
          // console.log(res);
          let tasks = res.data.tasks;
          // this.taskList = [{
          //   'name': 'tasks',
          //   'options' : tasks.map((x) => { return x.task })
          // }]
          this.taskList = tasks.map((x) => { return x.task });
          // this.mappedTask();
          if (this.data?.task_id) {
            this.timeLogCreateForm.get('task_id').setValue(this.data.task_id);
            this.setCategoryAndProject(this.data.task_id)
          }
          // console.log(this.taskList)
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(taskList);
  }

  listCategory() {
    let payload = {
      "filters": {
        "show_all": true
      }
    }
    const categoryList = this.boardApi.listCategory(payload).subscribe(
      {
        next: (res) => {
          // console.log(res);
          this.requestInProgress = false;
          this.categoryList = res.data.categories;
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(categoryList);
  }

  listScope() {
    let payload = {
      "filters": {
        "entity_type": "TASK",
        "show_all": true
      }
    }
    const categoryList = this.boardApi.listScope(payload).subscribe(
      {
        next: (res) => {
          // console.log(res);
          this.requestInProgress = false;
          this.scopeList = res.data.scopes;
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(categoryList);
  }

  listProject() {
    let payload = {
      "filters": {
        "entity_type": "TASK",
        "show_all": true
      }
    }
    const projectList = this.boardApi.projectList(payload).subscribe(
      {
        next: (res) => {
          this.requestInProgress = false;


          this.originalData = res.data.project
          // this.projectList = res.data.project;
          this.projectFromCleintIDclient()
          if(this.data?.payload?.entity_type == 'PROJECT' && this.data?.payload?.entity_id) {
            this.checkAndUpdateProjectClientId(this.data?.payload?.entity_id)
           }
          if(this.data?.project_id) {
            this.timeLogCreateForm.get('entity_id').setValue(this.data.project_id);
             const clientInfo = this.originalData
            .find(project => project.id === this.data.project_id);
            if(clientInfo && clientInfo.client_info) {
              this.timeLogCreateForm.get('client_id').setValue(clientInfo?.client_info?.id);
            }
          }


        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(projectList);
  }

  projectFromCleintIDclient() {

    if(this.hasPermission && this.clientId) {
      // console.log("123",this.clientId);

      const projectList = this.originalData
      .filter(project => project.client_info && project.client_info.id === this.clientId);
      this.projectList = [...projectList]
      // console.log(this.projectList , projectList);

  } else{
    this.projectList = [...this.originalData];
  }

  }

  listClient() {
    let payload = {
      "filters": {
        "entity_type": "TASK",
        "show_all": true
      }
    }
    const ClientList = this.boardApi.getClient(payload).subscribe(
      {
        next: (res) => {
          this.requestInProgress = false;
          this.clientList = res.data.clients;
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(ClientList);
  }



  get f() {
    return this.timeLogCreateForm.controls;
  }

  timePickerChange(value, timeType) {
    let fromTime = value;
    if (timeType == 'start_time') {
      this.timeLogCreateForm.get('start_time').setValue(fromTime);
      this.timeLogCreateForm.get('start_time_sec').setValue(fromTime + ':00');
      // this.timeLogCreateForm.get('start_time_sec').setValue(fromTime + '00');
      this.endMinTime = fromTime;
      this.isCurrentStartTime = false;
    }
    if (timeType == 'end_time') {
      this.timeLogCreateForm.get('end_time').setValue(fromTime);
      this.timeLogCreateForm.get('end_time_sec').setValue(fromTime + ':00');
      this.isCurrentEndTime = false;
      this.isCurrentStartTime = false;
      if(!this.timeLogCreateForm.value.end_date){
        // console.log(this.timeLogCreateForm.value.start_date);
        this.timeLogCreateForm.get('end_date').setValue(this.timeLogCreateForm.value.start_date);
        // console.log(this.timeLogCreateForm.value.end_date);
      }
      this.setDescriptionValidation();
      // if (this.endTimeChecker) {
      //   this.endTimeChecker.unsubscribe();
      // }
    }
    this.setTimeConfig();
    this.setTotalDuration();
    // console.log(fromTime);
  }

  datePickerChange(value, dateType) {
    const dateChange = value;
    const dateFormat = this.changeDateFormat(dateChange);

    if (dateType === 'start_date') {
      this.timeLogCreateForm.get('start_date').setValue(dateFormat);
      this.maxEndDate = this.changeDateFormat(new Date());

      if (dateFormat !== this.changeDateFormat(new Date())) {
        this.timeLogCreateForm.get('is_next_day').enable();

        if (moment(new Date()).subtract(1, 'day').format('YYYY-MM-DD') == dateFormat) {
          const isNextDay = this.f.is_next_day?.value;

          if (isNextDay) {
            this.timeLogCreateForm.get('end_date').setValue(moment(new Date(dateChange)).add(1, 'day').format('YYYY-MM-DD'));
          } else {
            this.timeLogCreateForm.get('end_date').setValue(dateFormat);
          }
        } else {
          this.timeLogCreateForm.get('is_next_day').setValue(false);
          this.timeLogCreateForm.get('end_date').setValue(dateFormat);
        }
      }
    }

    if (dateType === 'end_date') {
      this.timeLogCreateForm.get('end_date').setValue(dateFormat);
    }

    this.setTimeConfig();
    this.setTotalDuration();
  }
  setTotalDuration(){
    this.totalDuration = (this.timeLogCreateForm.value.end_time && this.timeLogCreateForm.value.start_time) ? this.calculateTimeDifference() : '00:00';
  }

  changeDateFormat(date, type = null) {
    if (type == 'add') {
      return moment(new Date(date)).add(1, 'day').format('YYYY-MM-DD')
    }
    return moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");
  }

  checkAndCreatePayload() {
    // console.log(this.timeLogCreateForm.value);

    let payload = {
      "time_format": "SECONDS",
      'timesheets': [
        {
          // 'entity_id': (this.timeLogCreateForm.value.entity_type == 'PROJECT' ) ? this.timeLogCreateForm.getRawValue()?.entity_id : this.timeLogCreateForm.value.client_id,
          // 'entity_type': (this.timeLogCreateForm.value.entity_type) ? this.timeLogCreateForm.value.entity_type : (this.timeLogCreateForm.getRawValue()?.entity_id) ? 'PROJECT' : null,
          // 'entity_type': ((this.timeLogCreateForm.getRawValue()?.entity_id || this.timeLogCreateForm.value.client_id) && this.timeLogCreateForm.value.entity_type) ? this.timeLogCreateForm.value.entity_type : null,
          'description': this.timeLogCreateForm.value.description,
          'category': {
            'id': this.timeLogCreateForm.value.category
          },
          'start_date': this.changeDateFormat(this.timeLogCreateForm.value.start_date),
          'end_date': (this.timeLogCreateForm.value.end_date) ? this.changeDateFormat(this.timeLogCreateForm.value.end_date) : null,
          // 'start_time': this.timeLogCreateForm.value.start_time,
          // 'start_time_sec': this.timeLogCreateForm.value.start_time_sec,
          // 'end_time': this.timeLogCreateForm.value.end_time,
          // 'end_time_sec': this.timeLogCreateForm.value.end_time_sec,
          // 'duration': (this.timeLogCreateForm.value.end_time && this.timeLogCreateForm.value.start_time) ? this.calculateTimeDifference() : '00:00',
          'label': this.timeLogCreateForm.value.label,
          'scope': {
            'id': this.timeLogCreateForm.value.scope,
          },
          'task_id': this.timeLogCreateForm.value.task_id,
          'id': this.timeLogCreateForm.value.id
        }
      ]
    }
    if(this.timeLogCreateForm.getRawValue()?.entity_id ) {
      payload['timesheets'][0]['entity_type'] = 'PROJECT';
      payload['timesheets'][0]['entity_id'] = this.timeLogCreateForm.getRawValue()?.entity_id ;
    }

    if(this.timeLogCreateForm.value?.client_id && !this.timeLogCreateForm.getRawValue()?.entity_id && this.hasPermission) {
      payload['timesheets'][0]['entity_type'] = 'CLIENT';
      payload['timesheets'][0]['entity_id'] = this.timeLogCreateForm.value?.client_id ;
    }


    // console.log("Payload from crete timelog",payload);

    if(this.isCurrentStartTime){
      payload['timesheets'][0]['current_start_time'] = 1;
    } else if(this.timeLogCreateForm.value.start_time) {
      // payload['timesheets'][0]['start_time'] = this.timeLogCreateForm.value.start_time;
      payload['timesheets'][0]['start_time'] = this.timeLogCreateForm.value.start_time_sec;
    }
    if(this.isCurrentEndTime){
      payload['timesheets'][0]['current_end_time'] = 1;
    } else if(this.timeLogCreateForm.value.end_time) {
      // payload['timesheets'][0]['end_time'] = this.timeLogCreateForm.value.end_time;
      payload['timesheets'][0]['end_time'] = this.timeLogCreateForm.value.end_time_sec;
    }
    if (this.data?.newTask) {
      payload['timesheets'].push(this.data.newTask);
    }
    // console.log(payload , this.isCurrentStartTime);
    return payload;
  }

  createTask() {
    if (this.timeLogCreateForm.invalid) {
      this.timeLogCreateForm.markAllAsTouched();
      // console.log(this.timeLogCreateForm);
      return;
    }
    let payload = this.checkAndCreatePayload();
    // let payload = {
    //   'timesheets': [
    //     {
    //       'entity_id': this.timeLogCreateForm.getRawValue()?.entity_id,
    //       'entity_type': this.timeLogCreateForm.value.entity_type,
    //       'description': this.timeLogCreateForm.value.description,
    //       'category': {
    //         'id': this.timeLogCreateForm.value.category
    //       },
    //       'date': this.changeDateFormat(this.timeLogCreateForm.value.start_date),
    //       'start_time': this.timeLogCreateForm.value.start_time,
    //       'end_time': this.timeLogCreateForm.value.end_time,
    //       'duration': (this.timeLogCreateForm.value.end_time && this.timeLogCreateForm.value.start_time) ? this.calculateTimeDifference() : '00:00',
    //       'label': this.timeLogCreateForm.value.label,
    //       'scope': {
    //         'id': this.timeLogCreateForm.value.scope,
    //       },
    //       'task_id': this.timeLogCreateForm.value.task_id,
    //       'id': this.timeLogCreateForm.value.id
    //     }
    //   ]
    // }
    // if (this.data?.newTask) {
    //   payload['timesheets'].push(this.data.newTask);
    // }
    // console.log(payload);
    // return;
    this.requestInProgress = true;
    this.createTimeLogObservable(payload);

    // this.setSubscription(createTimeLog);
  }

  createTimeLogObservable(payload) {
    const createTimeLog = this.boardApi.createTimeLog(payload).subscribe(
      {
        next: (res) => {
          this.requestInProgress = false;
          // console.log(res.data);
          // const timeLog = res.data.timesheets[0];
          // console.log(timeLog);
          // this.commonState.addTimeLogDate(timeLog.start_date);
          // setTimeout(() => {
          //   this.commonState.addTimeLogDate(null);
          // }, 1000);
          // let currentTask = res.data.current_task || {};
          // if(Object.keys(currentTask).length > 0 && !this.timeLogCreateForm.value.id){
          
          // }
          // this.commonState.addTimeLog(true);
          // console.log(res);
          if (this.isUpdate) {
            this.toast.success('Timelog updated successfully.');
          // } else {
            // this.toast.success('Logged time successfully.');
          }
          this.close(true);
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(createTimeLog);
  }

  calculateTimeDifference() {
    let startTimeArray = this.timeLogCreateForm.value.end_time.split(':')
    const time1 = this.timeLogCreateForm.value.end_date ? new Date(this.timeLogCreateForm.value.end_date) : new Date();
    time1.setHours(startTimeArray[0]);
    time1.setMinutes(startTimeArray[1]);

    let endTimeArray = this.timeLogCreateForm.value.start_time.split(':')
    const time2 = this.timeLogCreateForm.value.start_date ? new Date(this.timeLogCreateForm.value.start_date) : new Date();
    time2.setHours(endTimeArray[0]);
    time2.setMinutes(endTimeArray[1]);

    if (time1.getTime() - time2.getTime() < 0) {
      return '00:00';
    }

    const diffMs = time1.getTime() - time2.getTime();
    const diffMin = diffMs / 60000;

    const hours = Math.floor(diffMin / 60);
    const minutes = Math.floor(diffMin % 60);

    const result = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

    // console.log(result); // Output: HH:mm
    return result;
  }


  deleteTimeLog() {
    if (this.timerState.details_id && !this.timerState.end_time) {
      const dialogRef = this.dialog.open(this.deleteDialog, {
        height: '220px',
        width: '450px',
        disableClose: true,
      });
      const isClosed = dialogRef.afterClosed().subscribe((value) => {
        if (value) {
          // this.commonUtil.getCurrentActiveTask();
        }
      });
      this.setSubscription(isClosed);
    }
  }

  closeDeleteConfirmation(details_id) {
    if (!details_id) {
      return;
    }
    // console.log(details_id);
    this.requestInProgress = true;
    const timeLogDelete = this.timeLogApiService.deleteTimeSheet(details_id).subscribe({
      next: (res) => {
        this.requestInProgress = false;
        if (this.data?.newTask) {
          const newTaskPayload = {
            'timesheets': [this.data.newTask]
          };
          this.createTimeLogObservable(newTaskPayload);
        } else {
          // this.commonUtil.getCurrentActiveTask();
          // this.commonState.addTimeLog(true);
          this.close('DISCARD');
        }
      },
      error: (error) => {
        this.requestInProgress = false;
      },
    });
    this.setSubscription(timeLogDelete);
  }

  close(status?) {
    this.dialogRef.close(status);
  }

  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }

  ngOnDestroy(): void {
    if (this.endTimeChecker) {
      this.endTimeChecker.unsubscribe();
    }
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
