import { UserInfo } from './../../interface/user-info';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, Observable, startWith, Subject } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'ah-input-autocomplete',
  templateUrl: './input-autocomplete.component.html',
  styleUrls: ['./input-autocomplete.component.scss']
})
export class InputAutocompleteComponent implements OnInit {

  @Input() options: any[];
  @Input() selectedList: any[];
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  userControl = new FormControl();
  // options: any = [{ 'name': 'mari' }, { 'name': 'mani' }, { 'name': 'yogesh' }];
  selectedUsers = [];

  filteredUsers: Observable<any[]>;
  lastFilter: string = '';
  isAllSelected: boolean = false;
  displayStates

  @Output() selectedOptions = new EventEmitter<any[]>();

  constructor(
  ) { }

  ngOnInit() {
    // console.log(this.options);
    if(this.selectedList.length > 0){
      this.selectedList.forEach(x=>{
        this.options.forEach(y=>{
          if(y.id == x.id){
            y.selected = true;
            this.selectedUsers.push(y);
          }
        })
      });
      // console.log(this.selectedList);
    }
    this.filteredUsers = this.userControl.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : this.lastFilter),
      map(filter => this.filter(filter))
    );
  }

  filter(filter: string) {
    this.lastFilter = filter;
    if (filter) {
      return this.options.filter(option => {
        return option.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      })
    } else {
      return this.options.slice();
    }
  }

  displayFn(value) {
    let displayValue: string;
    if (Array.isArray(value)) {
      value.forEach((user, index) => {
        if (index === 0) {
          displayValue = user.name;
        } else {
          displayValue += ', ' + user.name;
        }
      });
    } else {
      displayValue = value;
    }
    return displayValue;
  }

  optionClicked(event: Event, user) {
    event.stopPropagation();
    this.toggleSelection(user);
  }

  toggleSelectAll() {
    this.isAllSelected = !this.isAllSelected;
    if (this.isAllSelected) {
      this.options.forEach(x => {
        x.selected = true;
        let index = this.selectedUsers.findIndex(y=>y.id === x.id);
        if(index > -1){
          this.selectedUsers[index] = x;
        } else {
          this.selectedUsers.push(x);
        }
      });
    } else {
      this.options.forEach(x => {
        x.selected = false;
      });
      this.selectedUsers = [];
    }
    // console.log(this.isAllSelected, this.selectedUsers)
    this.userControl.setValue('');
    this.emitToParent();
    this.close();
  }

  toggleSelection(user) {
    user.selected = !user.selected;
    if (user.selected) {
      // this.selectedUsers.push(user);
      let index = this.selectedUsers.findIndex(x=>x.id === user.id);
        if(index > -1){
          this.selectedUsers[index] = user;
        } else {
          this.selectedUsers.push(user);
        }
    } else {
      const i = this.selectedUsers.findIndex(value => value.id === user.id);
      this.selectedUsers.splice(i, 1);
    }

    // this.userControl.setValue(this.selectedUsers);
    this.userControl.setValue('');
    // console.log(this.userControl['value']);
    this.emitToParent();
    this.close();
  }

  close(){
    this.autocomplete.closePanel();
  }

  emitToParent() {
    this.selectedOptions.emit(this.selectedUsers);
  }
}
