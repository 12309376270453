import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ah-task-view',
  templateUrl: './task-view.component.html',
  styleUrls: ['./task-view.component.scss']
})
export class TaskViewComponent implements OnInit {

  @Input() taskInfo: any;
  @Input() sprintInfo: any  = {};
  @Output() closeSprint = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  close(event=false) {
    this.closeSprint.emit(event);
  }

}
