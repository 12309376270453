import { FORM_VALIDATION } from 'src/app/data/form-validation-config';
import { CommonUtilsService } from 'src/app/core/services/helper/common-utils.service';
import { BoardStateService } from 'src/app/core/services/data/board-state/board-state.service';
import { Category, Project } from './../../interface/master-data';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { BoardApiService } from 'src/app/core/services/api/board-api/board-api.service';
import { AppToastrService } from 'src/app/core/services/toastr/app-toastr.service';
import { Status } from '../../interface/master-data';
import { UserInfo } from '../../interface/user-info';
import { PlanningApiService } from 'src/app/core/services/api/planning-api/planning-api.service';

@Component({
  selector: 'ah-task-create-popup',
  templateUrl: './task-create-popup.component.html',
  styleUrls: ['./task-create-popup.component.scss']
})
export class TaskCreatePopupComponent implements OnInit {

  subscriptions: Subscription[] = [];
  requestInProgress: boolean = false;
  projectList: Project[] = [];
  taskCreateForm: FormGroup;
  minDate = moment(new Date()).format('YYYY-MM-DD');
  categoryList: Category[] = [];
  priorityList: any = [];
  statusList: Status[] = [];
  userList: UserInfo[] = [];
  selectedMemebers = [];
  taskId: string = '';
  formValidation = FORM_VALIDATION;
  sprintList: any = [];


  constructor(
    private boardApi: BoardApiService,
    private toast: AppToastrService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<TaskCreatePopupComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private boardState: BoardStateService,
    private commonUtil: CommonUtilsService,
    private planningApi: PlanningApiService,
  ) { }

  ngOnInit(): void {
    this.taskCreateForm = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(150) ]],
      project_id: [null],
      category: [''],
      status: ['DRAFT'],
      sprint_id: [''],
      priority: [''],
      task_status:[''],
      description: ['', Validators.maxLength(250)],
      start_date: [''],
      due_date: [''],
      estimated_time: ['']
    });
    this.listProject();
    this.listCategory();
    this.listPriority();
    this.listStatus();
    this.listUser();
    this.listSprint();
    // console.log(this.data);
    this.taskCreateForm.get('sprint_id').setValue(this.data?.sprint_info?.sprint_id);
    if(this.data && !this.data?.taskStatus){
      this.postProcessForm();
    }
  }

  postProcessForm() {
    this.taskCreateForm.patchValue({
      title: this.data.title,
      project_id: this.data.entity_info?.id,
      category: this.data.category?.id,
      priority: this.data.priority,
      task_status: this.data.task_status_info?.id,
      description: this.data.description,
      start_date: (this.data.start_date) ? moment(new Date(this.data.start_date)).format("YYYY-MM-DD") : '',
      due_date: (this.data.due_date) ? moment(new Date(this.data.due_date)).format("YYYY-MM-DD") : '',
      estimated_time: (this.data.estimated_time) ? this.commonUtil.convertToMinutes(this.data.estimated_time) : '',
      sprint_id: (this.data?.sprint_info?.sprint_id) ?  this.data?.sprint_info?.sprint_id : this.data?.sprint_info?.sprint_id
    });
    this.taskId = this.data.id;
    this.selectedMemebers = this.data.members.map(x=> {
      return x.user;
    });
    // console.log(this.selectedMemebers);
  }

  handleSelectionChanged(selectedValues): void {
    this.selectedMemebers = selectedValues;
    // console.log(this.selectedMemebers);
  }

  listSprint(){
    let payload = {
      "filters": {
        "show_all": true
      },
      "status": ["DRAFT", "ACTIVE"],
      "type": "BASIC"
    }
    const TimeSheetAPI = this.planningApi.planningList(payload).subscribe({
      next: (res) => {
        this.requestInProgress = false;
        let response = res.data;
        this.sprintList = response.sprint_list || [];
        // console.log(this.sprintList);
        // console.log(response, this.sprintList);
      },
      error: (error) => {
        this.requestInProgress = false;
        // console.error(error);
      }
    });
    this.setSubscription(TimeSheetAPI);
  }


  listCategory() {
    let payload = {
      "filters": {
        "show_all": true
      }
    }
    const categoryList = this.boardApi.listCategory(payload).subscribe(
      {
        next: (res) => {
          // console.log(res);
          this.requestInProgress = false;
          this.categoryList = res.data.categories;
          if(this.categoryList.length > 0 && !this.taskCreateForm.value.category){
            this.taskCreateForm.get('category').setValue(this.categoryList[0]?.id);
          }
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(categoryList);
  }

  listPriority() {
    let payload = {
      "filters": {
        "type": "PRIORITY",
        "show_all": true
      }
    }
    const priorityList = this.boardApi.listLabel(payload).subscribe(
      {
        next: (res) => {
          // console.log(res);
          this.requestInProgress = false;
          this.priorityList = res.data.labels;
          if(!this.taskId){
            let defaultSelect = this.priorityList.find(x=>x.id == 1);
            this.taskCreateForm.get('priority').setValue(defaultSelect?.id);
          }
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(priorityList);
  }

  listStatus() {
    let payload = {
      "filters": {
        "entity_type":"TASK",
        "show_all": true
      }
    }
    const statusList = this.boardApi.listStatus(payload).subscribe(
      {
        next: (res) => {
          // console.log(res);
          this.requestInProgress = false;
          this.statusList = res.data.status;
          if(!this.taskId){
            let defaultSelect = this.statusList[0];
            this.taskCreateForm.get('task_status').setValue(defaultSelect.id);
          }
          if(this.data?.taskStatus){
            this.taskCreateForm.get('task_status').setValue(this.data.taskStatus);
          }
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(statusList);
  }

  listUser() {
    let payload = {
      "filters": {
        "show_all": true
      }
    }
    const statusList = this.boardApi.listUser(payload).subscribe(
      {
        next: (res) => {
          // console.log(res);
          this.requestInProgress = false;
          this.userList = res.data.users;
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(statusList);
  }

  listProject() {
    let payload = {
      "filters": {
        "entity_type": "TASK",
        "show_all": true
      }
    }
    const projectList = this.boardApi.projectList(payload).subscribe(
      {
        next: (res) => {
          // console.log(res);
          this.requestInProgress = false;
          this.projectList = res.data.project;
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(projectList);
  }

  get f() {
    return this.taskCreateForm.controls;
  }

  timePickerChange(value) {
    let fromTime = value;
    this.taskCreateForm.get('estimated_time').setValue(fromTime)
    // console.log(fromTime);
  }

  datePickerChange(value, dateType) {
    let dateChange = value;
    if (dateType == 'StartDate') {
      this.taskCreateForm.get('start_date').setValue(dateChange)
    }
    if (dateType == 'EndDate') {
      this.taskCreateForm.get('due_date').setValue(dateChange)
    }

    // console.log(dateChange, value);
  }

  changeDateFormat(data) {
    return moment(data, "YYYY-MM-DD").format("YYYY-MM-DD");
  }

  createPayload(){
    if(this.taskCreateForm.invalid){
      this.taskCreateForm.markAllAsTouched();
      return;
    }
    // this.requestInProgress = true;
    let payload = {
      "task_status": this.taskCreateForm.value.task_status,
      "start_date": (this.taskCreateForm.value.start_date) ? this.changeDateFormat(this.taskCreateForm.value.start_date) : null,
      "due_date": (this.taskCreateForm.value.due_date) ? this.changeDateFormat(this.taskCreateForm.value.due_date) : null,
      "priority": this.taskCreateForm.value.priority,
      "title": this.taskCreateForm.value.title,
      "status": "ACTIVE",
      "type": "TASK",
      // "entity_type": "PROJECT",
      "description": this.taskCreateForm.value.description,
      "estimated_time": (this.taskCreateForm.value.estimated_time) ? this.commonUtil.convertToTime(this.taskCreateForm.value.estimated_time): '',
      "entity_id": this.taskCreateForm.value.project_id,
      "sprint_id": this.taskCreateForm.value.sprint_id
    }
    // if(this.data?.sprint_info){
    //   payload['sprint_id'] = this.taskCreateForm.value.sprint_id;
    // }
    if(this.taskCreateForm.value.category){
      payload["category"] = {
        "id": this.taskCreateForm.value.category
      };
    }
    if(this.taskCreateForm.value.project_id){
      payload['entity_type'] = "PROJECT"
    }
    if(this.selectedMemebers.length > 0){
      payload['members'] = this.selectedMemebers;
      payload['member_details'] = this.selectedMemebers.map(x=> {
        return {'user': x}
      });
    }
    // console.log(payload);return;

    if(this.taskId){
      this.updateTask(payload);
    } else {
      this.createTask(payload);
    }
  }

  createTask(payload?) {
    // console.log(payload);
    // return;
    if(this.requestInProgress){
      return;
    }
    this.requestInProgress = true;
    const createTask = this.boardApi.createTask(payload).subscribe(
      {
        next: (res) => {
          this.requestInProgress = false;
          this.boardState.addToBoard([res.data]);
          this.toast.success('Task created successfully');
          setTimeout(() => {
            this.close();
          },100);
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(createTask);

  }

  updateTask(payload) {
    if(this.requestInProgress){
      return;
    }
    this.requestInProgress = true;
    const createTask = this.boardApi.updateTask(this.taskId, payload).subscribe(
      {
        next: (res) => {
          // console.log(res);
          this.requestInProgress = false;
          this.boardState.updateToBoard(res.data);
          this.toast.success('Task updated successfully');
          setTimeout(() => {
            this.close();
          },100);
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(createTask);

  }

  close(status?) {
    this.dialogRef.close(status);
  }

  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
