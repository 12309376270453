import { Component, Inject, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Project } from '../../interface/master-data';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FORM_VALIDATION } from 'src/app/data/form-validation-config';
import moment from 'moment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppToastrService } from 'src/app/core/services/toastr/app-toastr.service';
import { BoardApiService } from 'src/app/core/services/api/board-api/board-api.service';
import { PlanningApiService } from 'src/app/core/services/api/planning-api/planning-api.service';
import { PlanningStateService } from 'src/app/core/services/data/planning-state/planning-state.service';

@Component({
  selector: 'ah-sprint-create-popup',
  templateUrl: './sprint-create-popup.component.html',
  styleUrls: ['./sprint-create-popup.component.scss']
})
export class SprintCreatePopupComponent implements OnInit {

  subscriptions: Subscription[] = [];
  requestInProgress: boolean = false;
  projectList: Project[] = [];
  sprintCreateForm: FormGroup;
  minDate = moment(new Date()).format('YYYY-MM-DD');
  sprintId: string = '';
  formValidation = FORM_VALIDATION;


  constructor(
    private toast: AppToastrService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<SprintCreatePopupComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private boardApi: BoardApiService,
    private planningApi: PlanningApiService,
    private planningState: PlanningStateService
  ) { }

  ngOnInit(): void {
    this.sprintCreateForm = this.fb.group({
      sprint_name: ['', [Validators.required, Validators.maxLength(150)]],
      project_id: [null],
      status: ['DRAFT'],
      description: ['', Validators.maxLength(250)],
      start_date: [''],
      due_date: [''],
    });
    this.listProject();
    // console.log(this.data);
    if(this.data){
      this.patchForm();
    }
  }

  patchForm() {
    this.sprintCreateForm.patchValue({
      sprint_name: this.data.sprint_name,
      project_id: this.data.entity_info?.id,
      description: this.data.description,
      status: this.data.status,
      start_date: (this.data.sprint_start_date) ? moment(new Date(this.data.sprint_start_date)).format("YYYY-MM-DD") : '',
      due_date: (this.data.sprint_end_date) ? moment(new Date(this.data.sprint_end_date)).format("YYYY-MM-DD") : '',

    });
    this.sprintId = this.data.sprint_id;
  }

  get f() {
    return this.sprintCreateForm.controls;
  }

  listProject() {
    let payload = {
      "filters": {
        "entity_type": "TASK",
        "show_all": true
      }
    }
    const projectList = this.boardApi.projectList(payload).subscribe(
      {
        next: (res) => {
          // console.log(res);
          this.requestInProgress = false;
          this.projectList = res.data.project;
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(projectList);
  }

  datePickerChange(value, dateType) {
    let dateChange = value;
    if (dateType == 'StartDate') {
      this.sprintCreateForm.get('start_date').setValue(dateChange)
    }
    if (dateType == 'EndDate') {
      this.sprintCreateForm.get('due_date').setValue(dateChange)
    }

    // console.log(dateChange, value);
  }

  changeDateFormat(data) {
    return moment(data, "YYYY-MM-DD").format("YYYY-MM-DD");
  }

  createPayload() {
    if (this.sprintCreateForm.invalid) {
      this.sprintCreateForm.markAllAsTouched();
      return;
    }
    // this.requestInProgress = true;
    let tempPayload = {
      "sprint_name": this.sprintCreateForm.value.sprint_name,
      "start_date": (this.sprintCreateForm.value.start_date) ? this.changeDateFormat(this.sprintCreateForm.value.start_date) : null,
      "end_date": (this.sprintCreateForm.value.due_date) ? this.changeDateFormat(this.sprintCreateForm.value.due_date) : null,
      "status": "DRAFT",
      "description": this.sprintCreateForm.value.description,
      "entity_id": this.sprintCreateForm.value.project_id
    }
    if (this.sprintCreateForm.value.project_id) {
      tempPayload['entity_type'] = "PROJECT"
    }
    if (this.sprintId) {
      tempPayload['id'] = this.sprintId;
    }
    let payload = {
      'sprint_list': [tempPayload]
    }

    // console.log(payload);
    // return;

    if (this.sprintId) {
      this.updateTask(payload);
    } else {
      this.createTask(payload);
    }
  }

  createTask(payload?) {
    const createTask = this.planningApi.createSprint(payload).subscribe(
      {
        next: (res) => {
          // console.log(res.data);
          this.requestInProgress = false;
          this.toast.success('Sprint created successfully');
          const sprint = res.data.sprint || {};
          if(Object.keys(sprint).length > 0){
            this.planningState.addToSprint([sprint])
          }
          setTimeout(() => {
            this.close(true);
          }, 100);
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(createTask);

  }

  updateTask(payload) {
    const createTask = this.planningApi.createSprint(payload).subscribe(
      {
        next: (res) => {
          // console.log(res.data);
          const sprint = res.data.sprint || {};
          this.requestInProgress = false;
          this.toast.success('Task updated successfully');
          if(Object.keys(sprint).length > 0){
            this.planningState.updateToSprint(sprint)
          }
          setTimeout(() => {
            this.close(true);
          }, 100);
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(createTask);

  }

  close(status = false) {
    this.dialogRef.close(status);
  }

  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
