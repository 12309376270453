<div class="show-column" fxLayout="column" cdkDropList (cdkDropListDropped)="drop($event)">
  <div class="show-column-items" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="4px"
    *ngFor="let col of displayedColumns" cdkDrag>
    <div class="over-flow-hidden " fxFlex="calc(100% - 16px)" fxLayout="row" fxFlex="auto">
      <p>
        <mat-checkbox [checked]="columnVisibility[col]" (change)="toggleColumnVisibility(col)">{{col | titlecase | removeUnderScore}} </mat-checkbox>
      </p>
    </div>
    <img class="drag-icon cp" fxFlex="12px" src="../../../../assets/image/svg/grid-dot.svg" cdkDragHandle>
  </div>
</div>
