import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ToastrModule } from 'ngx-toastr';
import { CommonLoggerService } from './core/services/logger/common-logger.service';
import { GlobalErrorHandler } from './global-error-handler';
import { ErrorInterceptor } from './core/helper/error.interceptor';
import { JwtInterceptor } from './core/helper/jwt.interceptor';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { SocketModule } from './modules/socket/socket.module';
import { SharedModule } from './shared/shared.module';
import { NgxPermissionsModule } from 'ngx-permissions';
// import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

const socketConfig: SocketIoConfig = { url: environment.SOCKET_IO_URL, options: { path: '/dh-socket' } };
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    SocketModule,
    FlexLayoutModule.withConfig({ addOrientationBps: false }),
    SocketIoModule.forRoot(socketConfig),
    SharedModule,
    NgxPermissionsModule.forRoot(),
    // OwlDateTimeModule,
    // OwlNativeDateTimeModule
  ],
  providers: [
    CommonLoggerService,
    // { provide: APP_INITIALIZER, useFactory: tokenInitializer, multi: true, deps: [AuthService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
