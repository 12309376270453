import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonUtilsService } from 'src/app/core/services/helper/common-utils.service';
import { TableUtilsService } from 'src/app/core/services/helper/table-utils.service';

@Component({
  selector: 'ah-column-modify',
  templateUrl: './column-modify.component.html',
  styleUrls: ['./column-modify.component.scss']
})
export class ColumnModifyComponent implements OnInit {

  displayedColumns: string[] = [];
  columnVisibility: { [key: string]: boolean };
  subscriptions: Subscription[] = [];

  constructor(
    private tableUtil: TableUtilsService
  ) { }

  ngOnInit(): void {

    const columnOrder = this.tableUtil.column$.subscribe(
      (columnOrder) => {
        this.columnVisibility = columnOrder.visibilityColumns;
        this.displayedColumns = columnOrder.originalColumns;
      }
    );
    this.setSubscription(columnOrder);

  }

  drop(event: CdkDragDrop<string[]>) {
    const prevIndex = event.previousIndex;
    const currentIndex = event.currentIndex;
    const movedColumn = this.displayedColumns.splice(prevIndex, 1)[0];
    this.displayedColumns.splice(currentIndex, 0, movedColumn);
    this.setColumnOrder();
  }

  setColumnOrder(){
    const visibleColumns = this.displayedColumns.filter(column => this.columnVisibility[column]);
    this.tableUtil.setColumnOrder(visibleColumns);
  }

  toggleColumnVisibility(column: string) {
    this.columnVisibility[column] = !this.columnVisibility[column];
    this.tableUtil.setColumnVisibility(this.columnVisibility);
    this.setColumnOrder();
  }

  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
