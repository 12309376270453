<!--
<mat-drawer-container class="example-container" [hasBackdrop]="false">
  <mat-drawer #drawer [mode]="'over'" [position]="'end'">
   <div>Side bar</div>
  </mat-drawer>
  <mat-drawer-content>
    <button mat-raised-button (click)="drawer.toggle()">Toggle drawer</button>
      </mat-drawer-content>
</mat-drawer-container> -->
<div class="main-dashboard" fxLayout="column" fxLayoutAlign="start stretch">
  <div class="main-dashboard-title" [ngClass]="{'active': taskId}" fxLayout="row" fxLayoutAlign="space-between center">
    <div>{{!taskId ? 'All Timesheet' : 'Task Timesheet'}}</div>
    <div class="breadcrumb-section" fxLayout="row" fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="18px"
      *ngIf="!((projectId && !taskId) || (taskId && !projectId))">
      <div class="breadcrumb-section-nav cna">Task</div>
      <!-- <div><img src="../../../assets/image/svg/breadcrumb-arrow.svg" alt="" width="20px"></div>
          <div class="breadcrumb-section-nav">Task</div> -->
      <div><img src="../../../assets/image/svg/breadcrumb-arrow.svg" alt="" width="20px"></div>
      <div class="breadcrumb-section-nav active cna">All Timesheet</div>
    </div>
    <div class="breadcrumb-section" fxLayout="row" fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="18px"
      *ngIf="taskId || projectId">
      <mat-icon class="cp" (click)="close()">highlight_off</mat-icon>
    </div>
  </div>
  <div class="main-dashboard-choose-date timesheet">
    <div class="gray-bg" fxLayoutGap.xs="12px" fxLayoutGap.sm="12px" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="space-between center" fxLayoutAlign.xs="start start" fxLayoutAlign.sm="start start">
      <div fxFlex.xs="auto" fxFlex.sm="auto" class="main-dashboard-choose-date-left" *ngIf="!((projectId && !taskId) || (taskId && !projectId))"
        fxFlex="50" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <ah-date-range-navigator fxFlexFill [selectedDate]="selectedDate" (dateEmit)="datePickerEmit($event)"></ah-date-range-navigator>
      </div>
      <div class="total-duration" fxFlex="100" fxFlex.xs="auto" fxFlex.sm="auto" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" [fxLayoutAlign]="taskId ? 'start center' : 'end center'"
        fxLayoutGap="15px">
        <!-- <div fxLayout="row" fxLayoutAlign="space-between center"> -->
        <div fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="2px" fxFlexFill fxFlexFill *ngIf="taskId">
          <div class="title">{{ProjectInfo?.task_info?.entity_info?.name}}</div>
          <div class="sub-title">{{ProjectInfo?.task_info?.title}}</div>
        </div>
        <div fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="2px" fxFlexFill fxFlexFill *ngIf="projectId">
          <div class="title">{{clientName }}</div>
          <div class="sub-title">{{projectName }}</div>
        </div>
        <!-- </div> -->
        <div fxLayout="row"  fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxFlexFill fxFlexFill fxLayoutAlign="end center" fxLayoutGap="15px" *ngIf="timeLogList.length > 0">
          <div class="time" fxFlex="auto" fxFlex.xs="60" fxFlex.sm="60" fxLayout="column" fxLayoutAlign="center end" fxLayoutAlign.xs="center start" fxLayoutAlign.sm="center start">Total Duration: {{totalDuration}}</div>
          <div fxFlex="20" fxFlex.xs="40" fxFlex.sm="40" fxLayout="column" fxLayoutAlign="center end" fxLayoutAlign.xs="center end" fxLayoutAlign.sm="center end">
            <button (click)="openDialog()" class="add-time-log active">+ Add Time Log</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="time-log" fxFlex="auto" fxLayout="column" fxLayoutAlign="start start" *ngIf="timeLogList.length > 0">
    <table mat-table [dataSource]="dataSource" class="time-log-table" matSort>
      <!-- project_title Column -->
      <ng-container matColumnDef="project_title" *ngIf="!taskId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Project Title</th>
        <td mat-cell *matCellDef="let element">
        <!-- <td mat-cell *matCellDef="let element">{{(element.entity_type == 'PROJECT' && element.entity_info?.name) ? element.entity_info?.name : '-'}} -->
          <ng-container *ngIf="element.entity_type == 'PROJECT' && element.entity_info?.name; else noProjectOrName">
            <div [ngClass]="{'task cp' : !projectId && hasPermission && element?.entity_info?.id && element.entity_type == 'PROJECT'}" (click)="navigateToTimeLog(element?.entity_info?.id, element.entity_type)">
              {{ element.entity_info?.name }}
            </div>
          </ng-container>
          <ng-template #noProjectOrName>
            <ng-container *ngIf="hasPermission; else notAllowed">
                    <ah-multi-select-dropdown
                    [itemList]="combinedList"
                    [bindLabelText]="'name'"
                    [bindValueText]="'id'"
                    [displayText]="'+ Projects'"
                    [placeholder]="'Search Clients or Projects'"
                    [showCreate]="true"
                    [isTag]="false"
                    (selectedOptions)="selectedTagItem($event, element)"
                    [isFromUntagged]="true"
                    ></ah-multi-select-dropdown>
                </ng-container>
                <ng-template #notAllowed>-</ng-template>

          </ng-template>

        </td>
      </ng-container>

      <!-- Task_name Column -->
      <ng-container matColumnDef="Task_name" *ngIf="!taskId && !hasPermission">
        <th mat-header-cell *matHeaderCellDef>Task Name</th>
        <td mat-cell *matCellDef="let element">
          <div class="two-line-ellipsis" [ngClass]="{'task cp' : (!taskId && !projectId) && element?.task_info?.id}" (click)="navigateToTimeLog(element?.task_info?.id)">
            {{(element.task_info?.title) ? element.task_info?.title : '-'}}</div>
        </td>
      </ng-container>

      <!-- description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element">
          <div class="two-line-ellipsis">
            {{(element.description) ? element.description : 'No description'}}
          </div>

        </td>
      </ng-container>

      <!-- date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let element">{{element.date | date: 'dd-MM-YYYY'}}</td>
      </ng-container>

      <!-- start_time Column -->
      <ng-container matColumnDef="start_time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Time</th>
        <td mat-cell *matCellDef="let element">{{element.start_time}}</td>
      </ng-container>
      <!-- end_time Column -->
      <ng-container matColumnDef="end_time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>End Time</th>
        <td mat-cell *matCellDef="let element">{{(element.end_time) ? element.end_time : ''}}
          <span *ngIf="!element.end_time" class="end-time">
            00:00
          </span>
        </td>
      </ng-container>

      <!-- time_log Column -->
      <ng-container matColumnDef="time_log">
        <th mat-header-cell *matHeaderCellDef>Duration</th>
        <td mat-cell *matCellDef="let element">{{(element.details_id == timerState.details_id) ? (timer$ | async) :
          element.duration }}</td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element">
          <div fxLayout="row" fxLayoutGap="11px" fxLayoutAlign="start center" class="action">
            <button class="edit cp act-btn" [ngClass]="{'disable' : element.details_id == timerState.details_id}"
              (click)="editTimeLog(element)" fxLayout="row" fxLayoutAlign="center center">
              <mat-icon>edit</mat-icon>
            </button>
            <!-- [ngClass]="{'disable' : element.details_id == timerState.details_id}"  -->
            <button class="delete cp act-btn" (click)="deleteTimeLog(element)" fxLayout="row" fxLayoutAlign="center center">
              <mat-icon>delete</mat-icon>
            </button>
          </div>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'un-assigned': !row.task_info?.title && !hasPermission}">
      </tr>
    </table>
    <mat-paginator class="drop-arrow" #paginator [length]="totalTasks" [pageSize]="limit"
      [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)">
      <!-- <select (change)="goToChange($event.target)">
        <option *ngFor="let pageNumber of [].constructor(pageNumbers); let i = index;" [value]="i+1">{{i+1}}
        </option>
      </select> -->
    </mat-paginator>
  </div>

  <div fxLayout="column" fxLayoutAlign="start center" fxFlex="100" class="footer mt-1" *ngIf="timeLogList.length == 0">
    <!-- <div class="no-time-log" fxLayout="column" fxLayoutAlign="center center">
          <img src="../../../../assets/image/svg/no-time-log.svg" width="178px">
          <div class="title">No Time Log's to Show</div>
          <div class="title-small">Looks like you haven't added any time log for this date</div>
          <button (click)="openDialog()" class="add-time-log">+ Add Time Log</button>
      </div> -->
    <ah-no-data-view fxFlexFill [config]="noDataPlaceholder" (addButtonClicked)="openDialog()"></ah-no-data-view>
  </div>
</div>
<ah-spinner *ngIf="requestInProgress" [config]="{fullscreen: true}"></ah-spinner>
<router-outlet name="ah-router-modal"></router-outlet>

