<div class="choose-date-left" fxFlex="100" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
  <button class="date-select" fxLayout="column" fxLayoutAlign="start center" (click)="selectPreviousDate()">
    <img src="../../../../assets/image/calender/left-circle-active.svg">
  </button>
  <span>{{(selectedDate?.label) ? selectedDate?.label : '-'}}</span>
  <div fxFlex="20px" fxLayout="column" fxLayoutAlign="start center" class="report-filter-left cp" ahClickOutside
    (appOutsideClick)="togglePicker($event)">
    <span fxFlex="auto" fxLayout="row" fxLayoutAlign="start center">
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <g clip-path="url(#clip0_7766_199)">
          <path
            d="M15.75 1.5H13.5V0H12V1.5H6V0H4.5V1.5H2.25C1.65326 1.5 1.08097 1.73705 0.65901 2.15901C0.237053 2.58097 0 3.15326 0 3.75L0 18H18V3.75C18 3.15326 17.7629 2.58097 17.341 2.15901C16.919 1.73705 16.3467 1.5 15.75 1.5ZM1.5 3.75C1.5 3.55109 1.57902 3.36032 1.71967 3.21967C1.86032 3.07902 2.05109 3 2.25 3H15.75C15.9489 3 16.1397 3.07902 16.2803 3.21967C16.421 3.36032 16.5 3.55109 16.5 3.75V6H1.5V3.75ZM1.5 16.5V7.5H16.5V16.5H1.5Z"
            fill="#333333" />
          <path d="M12.75 9.75H11.25V11.25H12.75V9.75Z" fill="#333333" />
          <path d="M9.75 9.75H8.25V11.25H9.75V9.75Z" fill="#333333" />
          <path d="M6.75 9.75H5.25V11.25H6.75V9.75Z" fill="#333333" />
          <path d="M12.75 12.75H11.25V14.25H12.75V12.75Z" fill="#333333" />
          <path d="M9.75 12.75H8.25V14.25H9.75V12.75Z" fill="#333333" />
          <path d="M6.75 12.75H5.25V14.25H6.75V12.75Z" fill="#333333" />
        </g>
        <defs>
          <clipPath id="clip0_7766_199">
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
    <ng-container *ngIf="showDatePicker">
      <div class="show-date-range-picker">
        <ah-date-range-picker fxFlexFill [showPicker]="true" [config]="{'currentStartValue': selectedDate?.startDate,
                                          'currentEndValue': selectedDate?.endDate}"
          (dateChange)="dateChange($event)"></ah-date-range-picker>
      </div>
    </ng-container>
  </div>
  <button class="date-select" fxLayout="column" fxLayoutAlign="start center" (click)="selectNextDate()"><img
      src="../../../../assets/image/calender/right-circle-active.svg" type="image/svg+xml"></button>
</div>
