<div fxFlex="500px" fxFlex.xs="100%" fxFlex.sm="100%" fxLayout="column" fxLayoutAlign="start" class="side-nav">
    <div class="header" fxLayout="row" fxFlex="auto" fxLayoutAlign="space-between center" fxLayoutGap="30px">
        <div>Create Task</div>
        <div (click)="close()" class="cp"><img src="../../../assets/image/svg/close.svg" alt="" width="12px"></div>
    </div>
    <div class="body custom-scroll-thin" fxLayout="column" fxLayoutGap="15px">
        <form [formGroup]="taskCreateForm" fxFlex="auto" fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="column" fxLayoutAlign="start start" fxFlex="auto" fxLayoutGap="8px">
                <div class="title">Task Name <span class="msg-error">*</span></div>
                <mat-form-field appearance="outline">
                    <!-- <mat-label>Task Name</mat-label> -->
                    <input formControlName="title" placeholder="Task Name" matInput [maxlength]="255">
                  </mat-form-field>
                <div class="msg-error" *ngIf="f.title.touched && f.title.errors?.required">
                    Task Name is Required
                </div>
                <div class="msg-error" *ngIf="f.title.touched && f.title.errors?.maxlength">
                  Maximum allowed limit is 150 character.
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="16px">
                <div fxLayout="column" fxLayoutAlign="start start" fxFlex="calc(50% - 8px)" fxLayoutGap="8px">
                    <div class="title">Sprints</div>
                    <ng-select [items]="sprintList" bindLabel="sprint_name" bindValue="sprint_id" formControlName="sprint_id"
              placeholder="Select Sprint"></ng-select>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="16px">
                <div fxLayout="column" fxLayoutAlign="start start" fxFlex="calc(50% - 8px)" fxLayoutGap="8px">
                    <div class="title">Projects</div>
                    <!-- <mat-form-field appearance="outline">
                        <mat-label>Projects</mat-label>
                        <mat-select formControlName="project_id" placeholder="Select Project">
                            <mat-option *ngFor="let project of projectList" [value]="project.id">{{project.name}}</mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    <ng-select [items]="projectList" bindLabel="name" bindValue="id" placeholder="Select Project" formControlName="project_id">
                    </ng-select>
                    <div class="msg-error" *ngIf="f.project_id.touched && f.project_id.errors?.required ">
                        Project is Required
                    </div>
                </div>
                <div fxLayout="column" fxLayoutAlign="start start" fxFlex="calc(50% - 8px)" fxLayoutGap="8px">
                    <div class="title">Category</div>
                    <!-- <mat-form-field appearance="outline">
                        <mat-label>Category</mat-label>
                        <mat-select formControlName="category" placeholder="Select Category">
                            <mat-option *ngFor="let category of categoryList" [value]="category.id">{{category.name}}</mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    <ng-select [items]="categoryList" bindLabel="name" bindValue="id" placeholder="Select Category" formControlName="category"></ng-select>
                    <div class="msg-error" *ngIf="f.category.touched && f.category.errors?.required ">
                        Category is Required
                    </div>
                </div>
            </div>

            <div fxLayout="row" fxLayoutGap="16px">
                <div fxLayout="column" fxLayoutAlign="start start" fxFlex="calc(50% - 8px)" fxLayoutGap="8px">
                    <div class="title">Task Status</div>
                    <!-- <mat-form-field appearance="outline">
                        <mat-select formControlName="task_status" placeholder="Select Status">
                            <mat-option *ngFor="let status of statusList" [value]="status.id">{{status.name}}</mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    <ng-select [items]="statusList" bindLabel="name" bindValue="id" placeholder="Select Status" formControlName="task_status"></ng-select>
                </div>
                <div fxLayout="column" fxLayoutAlign="start start" fxFlex="calc(50% - 8px)" fxLayoutGap="8px">
                    <div class="title">Priority</div>
                    <!-- <mat-form-field appearance="outline">
                        <mat-select formControlName="priority" placeholder="Select Priority">
                            <mat-option *ngFor="let priority of priorityList" [value]="priority.id">{{priority.name}}</mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    <ng-select [items]="priorityList" bindLabel="name" bindValue="id" placeholder="Select Priority" formControlName="priority"></ng-select>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="start start" fxFlex="auto" class="single-row">
                <div class="title mb-8">Add Members</div>
                <ah-input-autocomplete *ngIf="userList && userList.length > 0" [selectedList]="selectedMemebers" [options]="userList" (selectedOptions)="handleSelectionChanged($event)"></ah-input-autocomplete>
                <div fxLayout="column" fxLayoutAlign="start start" fxFlex="auto" fxLayoutGap="8px" class="single-row" *ngIf="selectedMemebers">
                    <!-- <div class="title">Add Members</div> -->
                    <!-- [options]="userList" -->
                    <!-- <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="13px" *ngIf="userList && userList.length > 0"> -->
                    <!-- </div> -->
                    <!-- <mat-form-field appearance="outline">
                        <mat-select formControlName="project_id" placeholder="Select Members">
                            <mat-option *ngFor="let project of projectList" [value]="project.id">{{project.name}}</mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    <div fxLayout="row wrap" fxFlex="auto" fxLayoutAlign="start center" fxLayoutGap="13px" *ngIf="selectedMemebers">
                        <div class="assignee" fxLayoutGap="8px" fxLayout="row" fxLayoutAlign="start center" *ngFor="let member of selectedMemebers">
                            <!-- <div class="assignee-img"><img src="../../../assets/image/svg/user.png" alt="" width="24px"></div> -->
                            <div fxLayout="column" fxFlex="22px" class="member" fxLayoutAlign="center center" *ngIf="!member['user']?.profile_pic">
                                <div class="member-name">
                                    A
                                    <!-- {{member['user']?.avatar_text.charAt(0)}} -->
                                </div>
                            </div>
                            <div class="name">{{member.name}}</div>
                            <!-- <div class="remove"> remove</div> -->
                        </div>
                    </div>
                </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="start start" fxFlex="auto" fxLayoutGap="8px">
                <div class="title">Description</div>
                <textarea formControlName="description" [maxlength]="201" ahCountdown></textarea>
                <div class="msg-error" *ngIf="f.description.touched && f.description.errors?.maxlength">
                    Maximum allowed limit is 250 character.
                </div>
            </div>

            <div fxLayout="row" fxLayoutGap="8px">
                <div fxLayout="column" fxLayoutAlign="start start" fxFlex="calc(35% - 12px)" fxLayoutGap="8px">
                    <!-- <div class="cp details">Monday, November 21</div> -->
                    <div class="title">Start Date</div>
                    <ah-date-picker fxFlexFill (dateChange)="datePickerChange($event, 'StartDate')" [config]="{placeholder: 'Start Date', currentValue: f.start_date?.value }"></ah-date-picker>
                </div>
                <div fxLayout="column" fxLayoutAlign="start start" fxFlex="calc(35% - 12px)" fxLayoutGap="8px">
                    <!-- <div class="cp details">Monday, November 21</div> -->
                    <div class="title">End date</div>
                    <ah-date-picker fxFlexFill (dateChange)="datePickerChange($event, 'EndDate')" [config]="{placeholder: 'End Date', currentValue: f.due_date?.value}"></ah-date-picker>
                </div>
                <div fxLayout="column" fxLayoutAlign="start start" fxFlex="calc(30% - 12px)" fxLayoutGap="8px">

                    <!-- <ah-time-picker fxFlexFill (fromTime)="timePickerChange($event)" [config]="{minTime: 'test', maxTime:'hellow' }"></ah-time-picker> -->
                    <div class="title">Estimation Time</div>
                    <mat-form-field appearance="outline">
                        <!-- <mat-label>Task Name</mat-label> -->
                        <!-- ahEstimateTimeFormat -->
                        <input type="text" formControlName="estimated_time" placeholder="1h 2m" matInput ahEstimateTimeFormat>
                    </mat-form-field>
                </div>
            </div>


        </form>
        <div fxLayout="column" fxLayoutAlign="start start" fxFlex="auto" fxLayoutGap="8px" class="single-row">
            <div fxLayout="row" fxFlex="auto" fxLayoutAlign="end center" class="single-row" fxLayoutGap="8px">
                <a class="button fill" fxLayout="column" fxFlex="100px" fxLayoutAlign="center center" (click)="createPayload()"> {{ !taskId ? 'Save' : 'Update' }} </a>
            </div>
        </div>


    </div>
</div>
<ah-spinner *ngIf="requestInProgress" [config]="{fullscreen: true}"></ah-spinner>
