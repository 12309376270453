<div class="overview">
  <div class="content" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="26px">
    <form [formGroup]="taskCreateForm" fxFlex="auto" fxLayout="column" fxLayoutGap="15px">
      <div fxLayout="row" class="title-border" fxLayoutAlign="start start" fxLayoutGap="16px" *ngIf="!taskId">
        <div class="icon" fxFlex="14px">
          <img [src]="'../../../../assets/image/svg/planning/project.svg'">
        </div>
        <div class="icon-item" fxFlex="auto">
          <ng-container *ngIf="showSelect === 'title'; else selectTitleTemplate">
            <div class="task-title" fxLayout="row">
              <input class="input" formControlName="title" placeholder="Task Name" [maxlength]="255"
                (blur)="toggleEdit(null)" #title>
            </div>
            <div class="msg-error" *ngIf="f.title.touched && f.title.errors?.required">
              Task Name is Required
            </div>
            <div class="msg-error" *ngIf="f.title.touched && f.title.errors?.maxlength">
              Maximum allowed limit is 150 character.
            </div>
          </ng-container>
          <ng-template #selectTitleTemplate>
            <div class="input-placehodler" [ngClass]="{'active' : taskId}" (click)="toggleEdit('title')">{{(f.title.value) ? f.title.value : 'Title'}}
            </div>
          </ng-template>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px">
        <div class="icon" fxFlex="14px">
          <img [src]="'../../../../assets/image/svg/planning/menu-burger.svg'">
        </div>
        <ng-container *ngIf="showSelect=='description'; else selectDescriptionTemplate">
          <textarea formControlName="description" placeholder="Write something..." [maxlength]="201"
            ahCountdown></textarea>
          <div class="msg-error" *ngIf="f.description.touched && f.description.errors?.maxlength">
            Maximum allowed limit is 250 character.
          </div>
        </ng-container>
        <ng-template #selectDescriptionTemplate>
          <div class="input-placehodler" [ngClass]="{'active' : taskId}" (click)="toggleEdit('description')">{{(f.description.value) ?
            f.description.value : 'Description'}}</div>
        </ng-template>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px">
        <div class="icon" fxFlex="14px">
          <img [src]="'../../../../assets/image/svg/planning/progress.svg'">
        </div>
        <div class="icon-item" fxFlex="auto">
          <ng-container *ngIf="showSelect === 'status'; else selectStatusTemplate">
            <ng-select [items]="statusList" bindLabel="name" bindValue="id" formControlName="task_status"
              placeholder="Select Status"></ng-select>
          </ng-container>
          <ng-template #selectStatusTemplate>
            <div class="input-placehodler" [ngClass]="{'active' : taskId}" (click)="toggleEdit('status')">{{(f.task_status.value) ?
              displayName('status',f.task_status.value) :
              'Status'}}
            </div>
          </ng-template>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px">
        <div class="icon" fxFlex="14px">
          <img [src]="'../../../../assets/image/svg/planning/project.svg'">
        </div>
        <div class="icon-item" fxFlex="auto">
          <ng-container *ngIf="showSelect === 'sprint'; else selectSprintTemplate">
            <ng-select [items]="sprintList" bindLabel="sprint_name" bindValue="sprint_id" formControlName="sprint_id"
              placeholder="Select Sprint"></ng-select>
          </ng-container>
          <ng-template #selectSprintTemplate>
            <div class="input-placehodler" [ngClass]="{'active' : taskId}" (click)="toggleEdit('sprint')">{{(f.sprint_id.value) ?
              displayName('sprint', f.sprint_id.value) :
              'Sprint'}}
            </div>
          </ng-template>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px">
        <div class="icon" fxFlex="14px">
          <img [src]="'../../../../assets/image/svg/planning/project.svg'">
        </div>
        <div class="icon-item" fxFlex="auto">
          <ng-container *ngIf="showSelect === 'project'; else selectTemplate">
            <ng-select [items]="projectList" bindLabel="name" bindValue="id" formControlName="project_id"
              placeholder="Select Project"></ng-select>
          </ng-container>
          <ng-template #selectTemplate>
            <div class="input-placehodler" [ngClass]="{'active' : taskId}" (click)="toggleEdit('project')">{{(f.project_id.value) ?
              displayName('project', f.project_id.value) :
              'Project'}}
            </div>
          </ng-template>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px">
        <div class="icon" fxFlex="14px">
          <img [src]="'../../../../assets/image/svg/planning/project.svg'">
        </div>
        <div class="icon-item" fxFlex="auto">
          <ng-container *ngIf="showSelect === 'category'; else selectCategoryTemplate">
            <ng-select [items]="categoryList" bindLabel="name" bindValue="id" formControlName="category_id"
              placeholder="Select Category"></ng-select>
          </ng-container>
          <ng-template #selectCategoryTemplate>
            <div class="input-placehodler" [ngClass]="{'active' : taskId}" (click)="toggleEdit('category')">{{(f.category_id.value) ?
              displayName('category', f.category_id.value) :
              'Category'}}</div>
          </ng-template>
          <!-- (change)="closeMenu(categoryMenuTrigger)" -->
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px">
        <div class="icon" fxFlex="14px">
          <img [src]="'../../../../assets/image/svg/planning/chart-pie-.svg'">
        </div>
        <div class="icon-item" fxFlex="auto">
          <ng-container *ngIf="showSelect === 'priority'; else selectPriorityTemplate">
            <ng-select [items]="priorityList" bindLabel="name" bindValue="id" formControlName="priority_id"
              placeholder="Select Priority"></ng-select>
          </ng-container>
          <ng-template #selectPriorityTemplate>
            <div class="input-placehodler" [ngClass]="{'active' : taskId}" (click)="toggleEdit('priority')">{{(f.priority_id.value) ?
              displayName('priority', f.priority_id.value) :
              'Priority'}}
            </div>
          </ng-template>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px" *ngIf="false">
        <div class="icon" fxFlex="14px">
          <img [src]="'../../../../assets/image/svg/planning/progress.svg'">
        </div>
        <div class="icon-item" fxFlex="auto">
          <ng-container *ngIf="showSelect === 'status'; else selectStatusTemplate">
            <ng-select [items]="statusList" bindLabel="name" bindValue="id" formControlName="task_status"
              placeholder="Select Status"></ng-select>
          </ng-container>
          <ng-template #selectStatusTemplate>
            <div class="input-placehodler" [ngClass]="{'active' : taskId}" (click)="toggleEdit('status')">{{(f.task_status.value) ?
              displayName('status',f.task_status.value) :
              'Status'}}
            </div>
          </ng-template>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px">
        <div class="icon" fxFlex="14px">
          <img [src]="'../../../../assets/image/svg/planning/calendar.svg'">
        </div>
        <div class="icon-item" fxFlex="auto">
          <ng-container *ngIf="showSelect === 'start_date'; else selectStartDateTemplate">
            <ah-date-picker fxFlexFill (dateChange)="datePickerChange($event, 'StartDate')"
              [config]="{placeholder: 'Start Date', currentValue: f.start_date?.value }"></ah-date-picker>
          </ng-container>
          <ng-template #selectStartDateTemplate>
            <div class="input-placehodler" [ngClass]="{'active' : taskId}" (click)="toggleEdit('start_date')">{{(f.start_date.value) ?
              (f.start_date.value | date: 'dd-MMM-YYYY' ) : 'Start Date'}}</div>
          </ng-template>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px">
        <div class="icon" fxFlex="14px">
          <img [src]="'../../../../assets/image/svg/planning/calendar.svg'">
        </div>
        <div class="icon-item" fxFlex="auto">
          <ng-container *ngIf="showSelect === 'end_date'; else selectEndDateTemplate">
            <ah-date-picker fxFlexFill (dateChange)="datePickerChange($event, 'EndDate')"
              [config]="{placeholder: 'End Date', currentValue: f.due_date?.value }"></ah-date-picker>
          </ng-container>
          <ng-template #selectEndDateTemplate>
            <div class="input-placehodler" [ngClass]="{'active' : taskId}" (click)="toggleEdit('end_date')">{{(f.due_date.value) ? (f.due_date.value | date: 'dd-MMM-YYYY') :
              'Due date'}}</div>
          </ng-template>
        </div>
      </div>
    </form>

    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <div class="icon" fxFlex="14px">
          <img src="../../../../assets/image/svg/planning/user-add.svg">
        </div>
        <div class="icon-item" fxFlex="auto" fxLayout="row" fxLayoutGap="8px">
          <div fxLayout="row" fxLayoutGap="-10px" class="user-assignee" *ngIf="selectedMembers?.length > 0">
            <ng-container>
              <ah-user *ngFor="let user of selectedMembers | slice:0:3" class="w-32 h-32" [userInfo]="user"></ah-user>
              <ah-user *ngIf="selectedMembers.length > 3" class="w-32 h-32" [showCount]="true"
                [memberCount]="selectedMembers.length - 3"></ah-user>
            </ng-container>
          </div>
          <!-- <ng-container *ngIf="showSelect === 'add_user'; else selectUserTemplate"> -->
            <!-- <ah-input-autocomplete *ngIf="userList && userList.length > 0" [selectedList]="selectedMembers"
              [options]="userList" (selectedOptions)="handleSelectionChanged($event)"></ah-input-autocomplete> -->
          <!-- </ng-container> -->
          <!-- <ng-template #selectUserTemplate> -->
            <!-- <div fxLayoutAlign="start center" *ngIf="selectedMembers.length == 0" (click)="toggleEdit('add_user')" class="plus cp">+ Add Assignee</div>
            <div fxLayoutAlign="start center" *ngIf="selectedMembers.length > 0" (click)="toggleEdit('add_user')" class="cp plus-hover">
            </div> -->
            <ngx-avatar size="32" bgColor="#4C6FFF"  value="+" (click)="toggleEdit('add_user')" [matMenuTriggerFor]="menuUser" #userMenuTrigger="matMenuTrigger">
            </ngx-avatar>

            <mat-menu #menuUser="matMenu" class="select-popup-planning">
              <div fxLayout="row" class="select-option" fxLayoutAlign="space-between center">
                <div class="clear small cp" fxLayout="row" fxLayoutAlign="start start" (click)="selectAll('user')">Select All
                </div>
                <div class="clear small cp" fxLayout="row" fxLayoutAlign="end start" *ngIf="selectedUser.length > 0" (click)="clear('user', userMenuTrigger)">Clear All</div>
              </div>
              <div (click)="$event.stopPropagation()">
                <ng-select [items]="userList | sortBy: 'name' : 'asc'" [isOpen]="true" bindLabel="name" bindValue="id"
                    [(ngModel)]="selectedUser"  placeholder="Select Assignee" [closeOnSelect]="true"
                    (change)="onSelectedOptionsChange($event, 'user')" [multiple]="true">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.name}}
                  </ng-template>
              </ng-select>
              </div>
            </mat-menu>
          <!-- </ng-template> -->
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="start end" fxLayoutGap="16px" class="mt-20">
    <div fxLayout="row" fxLayoutGap="16px">
      <button class="ah-btn gray fit-width" (click)="cancel()">Cancel</button>
      <button class="ah-btn solid fit-width" (click)="createPayload()" *ngIf="showButton">{{taskId ? 'Update' : 'Create'}}</button>
    </div>
  </div>
</div>
<ah-spinner *ngIf="requestInProgress" [config]="{fullscreen: false}"></ah-spinner>

