import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface Columns {
  originalColumns: string[];
  visibilityColumns: { [key: string]: boolean };
}

@Injectable({
  providedIn: 'root'
})
export class TableUtilsService {

  private columnOrderSubject = new BehaviorSubject<string[]>([]);
  columnOrder$ = this.columnOrderSubject.asObservable();

  private columnVisibilitySubject = new BehaviorSubject<{ [key: string]: boolean }>({});
  columnVisibility$ = this.columnVisibilitySubject.asObservable();

  private columnSubject = new BehaviorSubject<Columns>(null);
  column$ = this.columnSubject.asObservable();

  constructor() { }

  setColumn(input: Partial<Columns> | { originalColumns: any; visibilityColumns: any }): void {

    const currentColumns = this.columnSubject.getValue();
    let updatedColumns: Columns = { ...currentColumns };
    // console.log(updatedColumns.originalColumns, input.originalColumns);

    if ('originalColumns' in input) {
      updatedColumns.originalColumns = [...input.originalColumns];
    }

    if ('visibilityColumns' in input) {
      updatedColumns.visibilityColumns = input.visibilityColumns;
    }

    this.columnSubject.next(updatedColumns);
  }

  setColumnOrder(columnOrder: string[]) {
    this.columnOrderSubject.next(columnOrder);
  }

  setColumnVisibility(columnVisibility: { [key: string]: boolean }) {
    this.columnVisibilitySubject.next(columnVisibility);
  }

  drop(event: CdkDragDrop<string[]>, displayedColumns, orderedColumns) {
    const prevIndex = event.previousIndex;
    const currentIndex = event.currentIndex;
    const movedColumn = orderedColumns.splice(prevIndex, 1)[0];

    orderedColumns.splice(currentIndex, 0, movedColumn);

    const visibleColumn = displayedColumns;
    const commonElements = orderedColumns.filter(item => visibleColumn.includes(item));
    const nonCommonElements = visibleColumn.filter(item => !orderedColumns.includes(item));

    const finalArray = [...commonElements, ...nonCommonElements];

    // console.log('a=' + displayedColumns, 'b=' + orderedColumns, 'c=' + finalArray);

    this.setColumnOrder(orderedColumns);
    this.setColumn({ originalColumns: finalArray });
  }

}
