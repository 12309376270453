import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CalenderHeaderComponent } from '../calender-header/calender-header.component';

@Component({
  selector: 'ah-calender-popup',
  templateUrl: './calender-popup.component.html',
  styleUrls: ['./calender-popup.component.scss'],
  exportAs: 'menuInOtherComponent',
})
export class CalenderPopupComponent implements OnInit {

  calenderHeader = CalenderHeaderComponent;
  @ViewChild('calender') calender: ElementRef;

  @Input() currentDate?: any;
  @Input() showMonthPicker: boolean = false;
  @Input() setMaxDate: boolean = true;
  @Output() dateChange = new EventEmitter<{ start: Date, end: Date } | Date>();
  selectedDate: any;
  // today: any | null = new Date();
  today:  Date | null = null;

  constructor(
  ) { }

  ngOnInit(): void {
    this.setMaxDateCondition();
  }

  setMaxDateCondition() {
    console.log(this.setMaxDate)
    if (this.setMaxDate) {
      this.today = new Date();
    } else {
      this.today = null;
    }
    console.log(this.today);
  }

  onDateSelected(event) {
    this.selectedDate = event;
    // console.log(this.selectedDate);
    this.dateChange.emit(this.selectedDate);
    // this.menu.closed.emit();
  }

  chosenMonthHandler(event){
    // console.log(event);
    this.dateChange.emit(new Date(event));
  }

}
