import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ah-line-loader',
  templateUrl: './line-loader.component.html',
  styleUrls: ['./line-loader.component.scss']
})
export class LineLoaderComponent  {

  constructor() { }


}
