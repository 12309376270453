import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[ahDropAsset]'
})
export class DropAssetDirective {

  @Output() dropped = new EventEmitter<FileList>();
  @Output() hovered = new EventEmitter<boolean>();

  @HostListener("drop", ["$event"])
  onDrop($event) {
    $event.preventDefault();
    this.dropped.emit($event.dataTransfer.files);
    this.hovered.emit(false);
  }

  @HostListener("dragover", ["$event"])
  onDragOver($event) {
    $event.preventDefault();
    this.hovered.emit(true);
  }

  @HostListener("dragleave", ["$event"])
  onDragLeave($event) {
    $event.preventDefault();
    this.hovered.emit(false);
  }

}
