import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';

interface DatePickerConfig {
  'minDate'?: any;
  'maxDate'?: any;
  'placeholder'?: string;
  'type'?: string;
  'startDate'?: any;
  'endDate'?: any
  'currentValue'?: any
}
@Component({
  selector: 'ah-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {

  @Output() dateChange = new EventEmitter<{ start: Date, end: Date } | Date>();
  @ViewChild('picker', { static: false }) picker: MatDatepicker<Date>;
  selectedDate: any;
  @Input() tiggerOpen: boolean = false;
  @Input() showDatePickerPrefix: boolean = false;

  @Input() config: DatePickerConfig
  defaultConfig = {
    'minDate': null,
    'maxDate': null,
    'placeholder': 'Date',
    'type': 'date',
    'currentValue': null
  };

  constructor() { }

  ngOnInit(): void {
    this.setConfig(this.config);
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    if (changes.config?.firstChange == false) {
      this.setConfig(this.config);
    }
    if(changes.tiggerOpen?.currentValue){
      this.openDatepicker();
    }
  }

  setConfig(config?: DatePickerConfig) {
    const dataConfig = config ? Object.assign({}, this.defaultConfig, config) : this.defaultConfig;
    this.config = dataConfig;
    if(this.config.currentValue){
      this.selectedDate = moment(new Date(this.config.currentValue)).format('YYYY-MM-DD');
    }
  }

  openDatepicker() {
    if(this.picker){
      this.picker.open();
    }
  }

  onSelectedChanged(event) {
    // console.log(this.selectedDate, event);
    const date = event;
    switch (this.config.type) {
      case 'date':
        this.dateChange.emit(date);
        break;
      case 'dateRange':
        if (!this.config.startDate || this.config.endDate) {
          this.config.startDate = date;
          this.config.endDate = null;
        } else {
          if (date < this.config.startDate) {
            this.config.endDate = this.config.startDate;
            this.config.startDate = date;
          } else {
            this.config.endDate = date;
          }
          this.dateChange.emit({ start: this.config.startDate, end: this.config.endDate });
        }
        break;
      case 'customRange':
        if (!this.config.startDate || this.config.endDate) {
          this.config.startDate = date;
          this.config.endDate = null;
        } else {
          if (date < this.config.startDate) {
            this.config.endDate = this.config.startDate;
            this.config.startDate = date;
          } else {
            this.config.endDate = date;
          }
          this.dateChange.emit({ start: this.config.startDate, end: this.config.endDate });
        }
        break;
    }
  }
}
