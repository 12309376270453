import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { BoardApiService } from 'src/app/core/services/api/board-api/board-api.service';
import { PlanningApiService } from 'src/app/core/services/api/planning-api/planning-api.service';
import { BoardStateService } from 'src/app/core/services/data/board-state/board-state.service';
import { CommonUtilsService } from 'src/app/core/services/helper/common-utils.service';
import { AppToastrService } from 'src/app/core/services/toastr/app-toastr.service';
import { FORM_VALIDATION } from 'src/app/data/form-validation-config';
import { Project, Category, Status } from 'src/app/shared/interface/master-data';
import { UserInfo } from 'src/app/shared/interface/user-info';

const ELEMENT_FOCUS_CONST = {
  "start_date": false,
  "due_date": false,
}
@Component({
  selector: 'ah-task-create',
  templateUrl: './task-create.component.html',
  styleUrls: ['./task-create.component.scss']
})
export class TaskCreateComponent implements OnInit {

  @Input() taskId: string = '';
  @Input() taskName: string = '';
  @Input() taskInfo: any = {};
  @Input() sprintInfo: any = {};
  @Output() closeTaskCreate = new EventEmitter();
  @ViewChild('title') title!: ElementRef;
  subscriptions: Subscription[] = [];
  requestInProgress: boolean = false;
  projectList: Project[] = [];
  taskCreateForm: FormGroup;
  minDate = moment(new Date()).format('YYYY-MM-DD');
  categoryList: Category[] = [];
  priorityList: any = [];
  statusList: Status[] = [];
  userList: UserInfo[] = [];
  selectedMembers = [];
  formValidation = FORM_VALIDATION;
  elementFocus = { ...ELEMENT_FOCUS_CONST };
  // showSelect: boolean = false;
  showSelect: string = null;
  showButton: boolean = false;
  sprintList: any = [];
  selectedUser: any = [];


  constructor(
    private boardApi: BoardApiService,
    private toast: AppToastrService,
    private fb: FormBuilder,
    private commonUtil: CommonUtilsService,
    private planningApi: PlanningApiService,
    private boardState: BoardStateService,
  ) { }

  ngOnInit(): void {
    this.taskCreateForm = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(150)]],
      project_id: [null],
      category_id: [''],
      status: ['DRAFT'],
      sprint_id: [''],
      priority_id: [''],
      task_status: [''],
      description: ['', Validators.maxLength(250)],
      start_date: [''],
      due_date: [''],
      estimated_time: [''],
    });
    this.listProject();
    this.listCategory();
    this.listPriority();
    this.listStatus();
    this.listUser();
    this.listSprint();
    this.taskCreateForm.get('sprint_id').setValue(this.sprintInfo?.sprint_id);
    // console.log(this.taskInfo, this.sprintInfo);
    if (Object.keys(this.taskInfo).length > 0) {
      this.postProcessForm();
      this.selectedMembers = this.taskInfo.members;
    }
    if (!this.taskId) {
      this.showButton = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    if (changes.taskName && !changes.taskName.firstChange && changes.taskName.currentValue) {
      this.taskCreateForm.get('title').setValue(changes.taskName.currentValue);
      this.showButton = true;
    }
    if (!changes.taskId) {
      this.showSelect = 'title';
      setTimeout(() => {
        if (this.title) {
          this.title.nativeElement.focus();
        }
      }, 200);
    }
    if (changes.taskInfo && !changes.taskInfo.firstChange) {
      this.taskCreateForm.get('task_status').setValue(changes.taskInfo.currentValue.task_status_info?.id);
    }
  }
  get f() {
    return this.taskCreateForm.controls;
  }

  postProcessForm() {
    this.taskCreateForm.patchValue({
      title: this.taskInfo.title,
      project_id: this.taskInfo.entity_info?.id,
      category_id: this.taskInfo.category?.id,
      priority_id: this.taskInfo.priority,
      task_status: this.taskInfo.task_status_info?.id,
      description: this.taskInfo.description,
      start_date: (this.taskInfo.start_date) ? moment(new Date(this.taskInfo.start_date)).format("YYYY-MM-DD") : '',
      due_date: (this.taskInfo.due_date) ? moment(new Date(this.taskInfo.due_date)).format("YYYY-MM-DD") : '',
      estimated_time: (this.taskInfo.estimated_time) ? this.commonUtil.convertToMinutes(this.taskInfo.estimated_time) : '',
      sprint_id: (this.taskInfo.sprint_info?.sprint_id) ? this.taskInfo.sprint_info?.sprint_id : this.sprintInfo?.sprint_id
    });
    this.taskId = this.taskInfo.id;
    // this.selectedMembers = this.taskInfo.members.map(x=> {
    //   return x.user;
    // });

    this.selectedUser = this.taskInfo.members.map(x => {
      return x.id;
    });
    // console.log(this.selectedMembers);
  }

  displayName(entityType, entityId) {
    if (!entityId) {
      return null;
    }
    let displayName = null;
    switch (entityType.toLowerCase()) {
      case 'category':
        const category = this.categoryList.find(x => x.id == entityId);
        displayName = category?.name;
        break;
      case 'project':
        const project = this.projectList.find(x => x.id == entityId);
        displayName = project?.name;
        break;
      case 'priority':
        const priority = this.priorityList.find(x => x.id == entityId);
        displayName = priority?.name;
        break;
      case 'status':
        const scope = this.statusList.find(x => x.id == entityId);
        displayName = scope?.name;
        break;
      case 'user':
        const user = this.userList.find(x => x.id == entityId);
        displayName = user?.name;
        break;
      case 'sprint':
        const sprint = this.sprintList.find(x => x.sprint_id == entityId);
        displayName = sprint?.sprint_name;
        break;
    }
    return displayName
  }

  handleSelectionChanged(selectedValues): void {
    // console.log(selectedValues);
    this.selectedMembers = selectedValues;
    // console.log(this.selectedMembers);
  }

  datePickerChange(value, dateType) {
    let dateChange = value;
    if (dateType == 'StartDate') {
      this.taskCreateForm.get('start_date').setValue(dateChange)
    }
    if (dateType == 'EndDate') {
      this.taskCreateForm.get('due_date').setValue(dateChange)
    }
  }

  listSprint() {
    let payload = {
      "filters": {
        "show_all": true
      },
      "status": ["DRAFT", "ACTIVE"],
      "type": "BASIC"
    }
    const TimeSheetAPI = this.planningApi.planningList(payload).subscribe({
      next: (res) => {
        this.requestInProgress = false;
        let response = res.data;
        this.sprintList = response.sprint_list || [];
        // console.log(this.sprintList);
        // console.log(response, this.sprintList);
      },
      error: (error) => {
        this.requestInProgress = false;
        // console.error(error);
      }
    });
    this.setSubscription(TimeSheetAPI);
  }

  listCategory() {
    let payload = {
      "filters": {
        "show_all": true
      }
    }
    const categoryList = this.boardApi.listCategory(payload).subscribe(
      {
        next: (res) => {
          // console.log(res);
          this.requestInProgress = false;
          this.categoryList = res.data.categories;
          if (this.categoryList.length > 0 && !this.taskCreateForm.value.category_id) {
            this.taskCreateForm.get('category_id').setValue(this.categoryList[0]?.id);
          }
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(categoryList);
  }

  listPriority() {
    let payload = {
      "filters": {
        "type": "PRIORITY",
        "show_all": true
      }
    }
    const priorityList = this.boardApi.listLabel(payload).subscribe(
      {
        next: (res) => {
          // console.log(res);
          this.requestInProgress = false;
          this.priorityList = res.data.labels;
          if (!this.taskId) {
            let defaultSelect = this.priorityList.find(x => x.id == 1);
            this.taskCreateForm.get('priority_id').setValue(defaultSelect?.id);
          }
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(priorityList);
  }

  listStatus() {
    let payload = {
      "filters": {
        "entity_type": "TASK",
        "show_all": true
      }
    }
    const statusList = this.boardApi.listStatus(payload).subscribe(
      {
        next: (res) => {
          // console.log(res);
          this.requestInProgress = false;
          this.statusList = res.data.status;
          if (!this.taskId) {
            let defaultSelect = this.statusList[0];
            this.taskCreateForm.get('task_status').setValue(defaultSelect.id);
          }
          if (this.taskInfo?.taskStatus) {
            this.taskCreateForm.get('task_status').setValue(this.taskInfo.taskStatus);
          }
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(statusList);
  }

  listUser() {
    let payload = {
      "filters": {
        "show_all": true
      }
    }
    const statusList = this.boardApi.listUser(payload).subscribe(
      {
        next: (res) => {
          // console.log(res);
          this.requestInProgress = false;
          this.userList = res.data.users;
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(statusList);
  }

  listProject() {
    let payload = {
      "filters": {
        "entity_type": "TASK",
        "show_all": true
      }
    }
    const projectList = this.boardApi.projectList(payload).subscribe(
      {
        next: (res) => {
          // console.log(res);
          this.requestInProgress = false;
          this.projectList = res.data.project;
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(projectList);
  }

  createPayload() {
    if (this.taskCreateForm.invalid) {
      this.taskCreateForm.markAllAsTouched();
      return;
    }
    // this.requestInProgress = true;
    let payload = {
      "task_status": this.taskCreateForm.value.task_status,
      "start_date": (this.taskCreateForm.value.start_date) ? this.changeDateFormat(this.taskCreateForm.value.start_date) : null,
      "due_date": (this.taskCreateForm.value.due_date) ? this.changeDateFormat(this.taskCreateForm.value.due_date) : null,
      "priority": this.taskCreateForm.value.priority_id,
      "title": this.taskCreateForm.value.title,
      // "status": (this.sprintInfo?.status == "ACTIVE" && this.sprintInfo?.sprint_code != "BACKLOG") ? "ACTIVE" : "DRAFT",
      "status": "ACTIVE",
      "type": "TASK",
      "description": this.taskCreateForm.value.description,
      "estimated_time": (this.taskCreateForm.value.estimated_time) ? this.commonUtil.convertToTime(this.taskCreateForm.value.estimated_time) : '',
      "entity_id": this.taskCreateForm.value.project_id,
      "sprint_id": this.taskCreateForm.value.sprint_id,
    }
    if(this.taskCreateForm?.value?.sprint_id){
      payload["status"] = this.sprintInfo?.sprint_code != "BACKLOG" ? 'ACTIVE' : 'DRAFT';
    }
    if (this.taskCreateForm.value.category_id) {
      payload["category"] = {
        "id": this.taskCreateForm.value.category_id
      };
    }
    if (this.taskCreateForm.value.project_id) {
      payload['entity_type'] = "PROJECT"
    }
    if (this.selectedMembers.length > 0) {
      payload['members'] = this.selectedMembers;
      payload['member_details'] = this.selectedMembers.map(x => {
        return { 'user': x }
      });
    } else {
      payload['members'] = [];
    }
    // console.log(this.sprintInfo);
    console.log(payload);
    // return;

    if (this.taskId) {
      this.updateTask(payload);
    } else {
      this.createTask(payload);
    }
  }

  createTask(payload?) {
    // console.log(payload);
    // return;
    this.requestInProgress = true;
    const createTask = this.boardApi.createTask(payload).subscribe(
      {
        next: (res) => {
          this.requestInProgress = false;
          this.toast.success('Task created successfully');
          this.boardState.addToBoard([res.data]);
          this.close();
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(createTask);
  }

  updateTask(payload) {
    this.requestInProgress = true;
    const createTask = this.boardApi.updateTask(this.taskId, payload).subscribe(
      {
        next: (res) => {
          // console.log(res);
          this.requestInProgress = false;
          this.toast.success('Task updated successfully');
          this.boardState.updateToBoard(res.data);
          this.close();
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(createTask);
  }

  changeDateFormat(data) {
    return moment(data, "YYYY-MM-DD").format("YYYY-MM-DD");
  }

  close() {
    this.closeTaskCreate.emit(true);
  }

  cancel() {
    this.closeTaskCreate.emit(false);
  }

  toggleEdit(inputType) {
    this.showSelect = inputType;
    this.showButton = true;
  }

  closeMenu(menuTrigger: MatMenuTrigger) {
    if (menuTrigger) {
      menuTrigger.closeMenu();
    }
  }

  onSelectedOptionsChange(newOptions: any[], entityType) {
    if (entityType == 'user') {
      this.selectedMembers = this.userList.filter(x =>
        this.selectedUser.includes(x.id)
      );
    }
    // console.log(this.taskInfo.task_members, this.selectedMembers);
  }

  selectAll(entityType) {
    if (entityType == 'user') {
      const selectedUser = this.selectedMembers;
      this.selectedMembers = this.userList.map(x => x.id);
    }
  }

  clear(entityType, menuTrigger) {
    if (entityType == 'user') {
      this.selectedMembers = [];
    }
    this.closeMenu(menuTrigger);
  }

  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
