import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { TimePickerConfig } from '../../interface/timer-interface';
import moment from 'moment';
import { BreakpointService } from 'src/app/core/helper/breakpoint/breakpoint.service';



@Component({
  selector: 'ah-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  currentdatetime: any
  time: any;
  // @Input() config: TimePickerConfig;
  @Input('config') set config(data: TimePickerConfig) {
    this._config = data;
  };
  private _config;
  defaultConfig = {
    'minTime': null,
    'maxTime': null,
    'format': 24,
    'placeholder': 'Time',
    'currentValue': null,
    'startDate': null,
    'endDate': null,
    'startTime': null,
    'endTime': null,
    'updateMaxTime': false,
    'type': null,
    'showIcon': false,
  };


  @Output() fromTime = new EventEmitter();

  isResponsive: boolean = false;

  constructor(
    private breakpoint: BreakpointService,
  ) { }

  ngOnInit(): void {
    // this.defaultConfig.maxTime = this.datepipe.transform((new Date()), 'HH:mm');
    this.setConfig(this.config);
    const responsive = this.breakpoint.getBreakpoint().subscribe(res => {
			this.isResponsive = (res.size == 'XS' || res.size == 'SM') ? true : false;
		});
    this.setSubscription(responsive);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config.firstChange == false) {
      this.setConfig(this.config);
    }
  }

  postProcessingConfig() {
    this.config.minTime = this.config.minTime ? this.config.minTime : this.config.startTime;
    this.config.maxTime = this.config.maxTime ? this.config.maxTime : this.config.endTime;
    if (this.config.currentValue) {
      this.time = this.config.currentValue;
    }
    if (this.config.isUpdate) {
      this.config.minTime = null;
      this.config.maxTime = null;
    }
  }

  get config() {
    return this._config;
  }

  setConfig(config?: TimePickerConfig) {
    const dataConfig = config ? Object.assign({}, this.defaultConfig, config) : this.defaultConfig;
    this.config = dataConfig;
    this.postProcessingConfig();
    // console.log(this.config);
  }

  sendTime() {
    this.fromTime.emit(this.time);
  }

  setCurrentTime(){
    this.fromTime.emit(moment(new Date()).format('HH:mm'));
  }

  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
