import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EchoService, ECHO_CONFIG, NgxLaravelEchoModule} from 'ngx-laravel-echo';
import { echoConfig } from 'src/app/data/echo/echo.config';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    // NgxLaravelEchoModule.forRoot(echoConfig),
  ],
  providers: [
    EchoService,
    { provide: ECHO_CONFIG, useValue: echoConfig },
  ]
})
export class SocketModule { }
