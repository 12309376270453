<div class="side-bar" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
  <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="header-title">Create Task</div>
    <img src="../../../../assets/image/svg/cross-planning.svg" width="14px" (click)="close()">
  </div>
  <div class="tab-sec custom-scroll-thin">
    <div class="content" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="26px">
      <ah-task-create fxFlexFill [taskInfo]="taskInfo" [sprintInfo]="sprintInfo" (closeTaskCreate)="close($event)"></ah-task-create>
    </div>
  </div>
</div>
