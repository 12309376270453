import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonDataService } from 'src/app/core/services/data/common-data/common-data.service';
import { SidebarService } from 'src/app/core/services/data/sidebar/sidebar.service';

interface sideBarObj {
  sprint_info: any;
  task_info: any;
  sideBarType: string;
}
@Component({
  selector: 'ah-planning-side-bar',
  templateUrl: './planning-side-bar.component.html',
  styleUrls: ['./planning-side-bar.component.scss']
})
export class PlanningSideBarComponent implements OnInit {
  @Output() closeSideBar = new EventEmitter();
  @Input() sidebarObj: sideBarObj | any;
  viewMode = 'no-update';
  panelOpenState = false;
  constructor(
    private sidebarState: SidebarService,
    private commonData: CommonDataService
  ) { }
  ngOnInit(): void {
    const sidebar = this.sidebarState.select('sidebarObj').subscribe((res)=>{
      this.sidebarObj = res;
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    if(changes.sidebarObj.firstChange){
      this.sidebarObj = {...changes.sidebarObj.currentValue};
    }
    console.log(this.sidebarObj);
  }



  close(event) {
    // console.log(event);
    this.sidebarState.clear();
    this.sidebarState.setisForceRefresh(event);
    this.commonData.setPlanningTaskId(null);
    setTimeout(() => {
      this.sidebarState.setisForceRefresh(false);
    }, 1000);
    // this.closeSideBar.emit(event);
  }

}
