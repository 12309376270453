import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnDestroy } from '@angular/core';

@Component({
  selector: 'ah-select-filters',
  templateUrl: './select-filters.component.html',
  styleUrls: ['./select-filters.component.scss']
})
export class SelectFiltersComponent implements OnInit, OnDestroy {
  @Output() itemSelected = new EventEmitter<any>();
  @Input() itemList: any = [];
  @Input() selectedItems: any = [];
  @Input() bindLabelText: string = 'title';
  @Input() bindValueText: string = 'id';
  @Input() placeholder: string = 'Select Text';
  @Input() notFoundText: string = 'No data found';
  @Input() isShow: boolean = false;
  constructor() { }

  ngOnInit(): void {
    console.log(this.bindLabelText);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isShow) {
      if (!this.isShow) {
        this.clear();
      }
    }
  }

  selectAll() {
    this.selectedItems = this.itemList.map(x=>x[this.bindValueText]);
    // console.log(this.selectedItems);
    this.toggleSelection(true);
  }

  addSelection() {
    // console.log(this.selectedItems);
    this.toggleSelection(true);
  }


  toggleSelection(event) {
    this.itemSelected.emit({ 'selected': this.selectedItems, 'isOpen': event })
  }

  clear() {
    this.selectedItems = []
    this.itemSelected.emit({ 'selected': this.selectedItems, 'isOpen': false })
  }

  ngOnDestroy(): void {
    this.bindLabelText = null;
  }
}
