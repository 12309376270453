import { HttpClientService } from './../../http-client/http-client.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  constructor(private httpService: HttpClientService) { }

  login(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/auth/login', payload);
  }

  logout(): Observable<any>{
    return this.httpService.get(environment.BASE_URL + '/v1/auth/logout', "");
  }

  refreshToken(payload: { refresh_token: any; }): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/auth/token/refresh', payload);
  }

  email_send(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/forgot_password/email/send-verification', payload);
  }

  email_verify(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/forgot_password/email/verify', payload);
  }

  reset_password(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/forgot_password/reset/password', payload);
  }

  getUserSetting(){
    return this.httpService.get(environment.BASE_URL + '/v1/setting/view', null);
  }

}
