import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ah-no-data-view',
  templateUrl: './no-data-view.component.html',
  styleUrls: ['./no-data-view.component.scss']
})
export class NoDataViewComponent implements OnInit {

  @Input() config:any= {
    title: null,
    description: null,
    isShowButton: false,
    buttonText: null
  };

  @Output() addButtonClicked = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }

  buttonClicked(){
    this.addButtonClicked.emit();
  }

}
