import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { BoardStateService } from 'src/app/core/services/data/board-state/board-state.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'ah-search-result-item',
  templateUrl: './search-result-item.component.html',
  styleUrls: ['./search-result-item.component.scss']
})
export class SearchResultItemComponent implements OnInit {

  subscriptions: Subscription[] = [];
  @Input() taskInfo: any;
  @Output() taskEvent = new EventEmitter();
  timerState;
  constructor(
    private boardState: BoardStateService,
    private dialog: MatDialog,
    private router: Router,
  ) { }

  ngOnInit(): void {
    const timerState = this.boardState.select('currentTimerLog').subscribe(res => {
      this.timerState = res;
    });
    this.setSubscription(timerState);
  }

  initiateTask(task, taskStatus){
    // console.log(task);
    this.taskEvent.emit({task, taskStatus});
  }

  navigateToTimeLog(id: string) {
    this.router.navigate(['./dashboard/dashboard/', { outlets: { 'ah-router-modal': ['viewTimeLog', id] } }]);
  }

  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
