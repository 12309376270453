import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataStateService } from '../data-state.service';
import { CommonStorageService } from '../../storage/common-storage.service';
import { ReferenceName } from 'src/app/shared/enum/reference-name.enum';

interface AuthState {
  loggedIn: boolean;
  userInfo: any;
  userAppSettings: any;
}

@Injectable({
  providedIn: 'root'
})
export class AuthDataService extends DataStateService<AuthState>  {

  loggedInStatus$: Observable<boolean>;

  constructor(
    private commonStorage: CommonStorageService,
  ) {
    super();
    const initialState: AuthState = {
			loggedIn: false,
      userInfo: [],
      userAppSettings: [],
		};
    this.state$ = new BehaviorSubject<AuthState>(initialState);

    this.loggedInStatus$ = this.select('loggedIn');
   }

   setLoggedInStatus(status: boolean): void {
    this.setState({ loggedIn: status });
   }

	 setUserProfileInfo(userInfo: any): void {
		this.setState({ userInfo: userInfo });
	 }

	 getUserProfileInfo() {
    let userInfo = this.selectSnapshot('userInfo');
    if(userInfo && Object.keys(userInfo)?.length > 0){
      userInfo = this.commonStorage.get(ReferenceName.USER_INFO);
    }
    return userInfo;
  }

  setUserAppSettings(settings): void {
    this.setState({ userAppSettings: settings });
   }
}
