import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ah-attachment-popup',
  templateUrl: './attachment-popup.component.html',
  styleUrls: ['./attachment-popup.component.scss']
})
export class AttachmentPopupComponent implements OnInit {

  attachments = [];
  attachmentEvent = [];
  onDelete = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<AttachmentPopupComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) { }

  ngOnInit(): void {
    this.attachments = this.data;
    // console.log(this.data, this.attachments);
  }

  uploadedAsset(event){
    // console.log(event);
    this.attachmentEvent = event;
    if(event.close){
      this.close();
    }
    if(event.is_delete && typeof event.attachment_id == 'number'){
      this.onDelete.emit(event);
    }
  }

  close() {
    this.dialogRef.close(this.attachmentEvent);
  }

}
