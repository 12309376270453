import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, Input, Output, Renderer2 } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { fromEvent, tap, switchMap, map, distinctUntilChanged, takeUntil } from 'rxjs';

@Directive({
  selector: '[resizeColumn]'
})
export class TableColumnResizeDirective {

  @Input("resizeColumn") resizable: boolean;

  @Input() index: number;
  @Input() width: number;

  private startX: number;

  private startWidth: number;

  private column: HTMLElement;

  private table: HTMLElement;

  private pressed: boolean;

  private resizer;


  private thead;

  private isLastColumn: boolean = false;

  constructor(private renderer: Renderer2, private el: ElementRef) {
    this.column = this.el.nativeElement;
  }

  ngOnInit() {
  }
  ngAfterViewInit() {
    if (this.resizable) {
      const row = this.renderer.parentNode(this.column);
      this.thead = this.renderer.parentNode(row);
      this.table = this.renderer.parentNode(this.thead);
      // const thCollection = this.table.getElementsByTagName("th");

      this.resizer = this.renderer.createElement("div");
      this.renderer.addClass(this.resizer, "resize-holder");
      this.setLineStyles(this.resizer);
      this.renderer.appendChild(this.column, this.resizer);

      this.renderer.listen(this.resizer, "mousedown", this.onMouseDown);
      this.renderer.listen("document", "mousemove", this.onMouseMove);
      this.renderer.listen("document", "mouseup", this.onMouseUp);

      // this.setWidth(this.column.getElementsByTagName("th"), this.width)

      // this.renderer.setStyle(this.column, "width", `${this.width}px`);
      this.setWidth(this.column, this.width)

      this.renderer.listen(this.resizer, 'mouseover', () => {
        this.renderer.setStyle(this.resizer, "background", "#4c6fff");
      });

      this.renderer.listen(this.column, 'mouseout', () => {
        this.renderer.setStyle(this.resizer, "background", "#cbd1d8");
      });
    }
  }

  setLineStyles(el: HTMLDivElement) {
    this.renderer.setStyle(el, "position", "absolute");
    this.renderer.setStyle(el, "cursor", "e-resize");
    this.renderer.setStyle(el, "width", "2px");
    this.renderer.setStyle(el, "right", "0");
    this.renderer.setStyle(el, "top", "0");
    this.renderer.setStyle(el, "height", "100%");
    this.renderer.setStyle(el, "background", "#cbd1d8");
  }

  setWidth(element, width: number | string) {
    this.renderer.setStyle(
      element,
      "width",
      width + (width === "auto" ? "" : "px")
    );
  }

  onMouseDown = (event: MouseEvent) => {
    this.pressed = true;
    this.startX = event.pageX;
    this.startWidth = this.column.offsetWidth;
    // this.renderer.setStyle(resizer, "background", "#ff0000");
    const allColumns = this.thead.querySelectorAll("th");
    const totalColumns = allColumns.length;

    // Get the index of the current column
    const currentIndex = Array.from(allColumns).indexOf(this.column);
    // console.warn("Last column clicked! :=>", tableWidth, currentIndex === totalColumns - 1);
    if (currentIndex === totalColumns - 1) {
      this.isLastColumn = true
    }
  };

  onMouseMove = (event: MouseEvent) => {

    if(!this.pressed){
      this.renderer.setStyle(this.resizer, "background", "#cbd1d8");
      return;
    }
    this.renderer.setStyle(this.resizer, "background", "#4c6fff");

    if (this.pressed && event.buttons) {
      this.renderer.addClass(this.table, "resizing");

      // Calculate width of column
      let width = this.startWidth + (event.pageX - this.startX);

      if (width < 5) {
        return;
      }

      const tableCells = Array.from(this.table.querySelectorAll(".mat-row")).map(
        (row: any) => row.querySelectorAll(".mat-cell").item(this.index)
      );

      // Set table header width
      // this.renderer.setStyle(this.column, "width", `${width}px`);
      this.setWidth(this.column, width);

      // Set table cells width
      for (const cell of tableCells) {
        this.renderer.setStyle(cell, "width", `${width}px`);
      }

      // Get the total number of columns
      // const allColumns = this.thead.querySelectorAll("th");
      // const totalColumns = allColumns.length;

      // // Get the index of the current column
      // const currentIndex = Array.from(allColumns).indexOf(this.column);
      // console.warn("Last column clicked! :=>", tableWidth, currentIndex === totalColumns - 1);
      // currentIndex === totalColumns - 1 &&
      // console.log(event.pageX);
      if (this.isLastColumn) {
        let tableWidth = this.table.offsetWidth + (event.pageX - this.startX);
        this.renderer.setStyle(this.table, "width", `${tableWidth}px`);
      }

    }
  };

  onMouseUp = (event: MouseEvent) => {
    if (this.pressed) {
      this.pressed = false;
      this.renderer.removeClass(this.table, "resize-holder");
    }
    this.isLastColumn = false;
  };



}
