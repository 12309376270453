<div class="time-log" *ngIf="objectKeys(groupedData)?.length > 0">
    <div class="date-group" fxLayout="column" *ngFor="let group of groupedData | keyvalue: sortKeys">
      <div class="date">{{group.key | date:'MMM d, y'}}</div>
      <div fxLayout="row" class="time" fxLayoutAlign="space-between center" fxLayoutGap="16px" *ngFor="let item of group.value">
        <div class="time-sec" fxFlex="auto">{{(item.description) ? item.description : '-'}}</div>
        <div fxLayoutAlign="end center" fxFlex="300px" fxLayoutGap="24px">
          <div class="time-sec">{{item.start_time}} - {{(item.end_time) ? item.end_time : '' }}
            <span *ngIf="!item.end_time" class="end-time">
              00:00
            </span>
          </div>
          <div class="duration">{{(item.duration) ? item.duration : (timer$ | async)}}</div>
          <div fxLayout="row" fxLayoutGap="11px" fxLayoutAlign="start center" class="action" *ngIf="viewFrom == 'DASHBOARD'">
            <button class="edit cp" fxLayout="row" fxLayoutAlign="center center" [disabled]="item?.details_id == timerState?.details_id" [ngClass]="{'disable' : item?.details_id == timerState?.details_id}" (click)="editTimeLog(item)">
                <mat-icon>edit</mat-icon>
              </button>
            <button class="delete cp" fxLayout="row" fxLayoutAlign="center center" (click)="deleteTimeLog(item)">
                <mat-icon>delete</mat-icon>
              </button>
        </div>
        </div>
      </div>
    </div>
</div>
<ng-container *ngIf="!requestInProgress && !isLastPage">
  <div fxLayout="row">
    <button class="ah-btn solid fit-width mt-10" (click)="getTimeLog()">
      load more
    </button>
  </div>
</ng-container>

<div fxLayout="row" fxLayoutAlign="start center" fxFlex="100" class="footer mt-1" *ngIf="objectKeys(groupedData)?.length == 0">
  <ah-no-data-view fxFlexFill [config]="noDataPlaceholder"></ah-no-data-view>
</div>

<ah-spinner *ngIf="requestInProgress" [config]="{fullscreen: false}"></ah-spinner>
<ng-template #deleteDialog let-data>
  <div class="timesheet" fxLayout="row" fxLayoutAlign="center center">
      <div class="timesheet-app">
          <mat-dialog-content>
              <div fxLayout="column" fxLayoutAlign="start start" class="text-center mt-1">
                  <p class="mt-2">
                      Are you sure you want to delete?
                  </p>
              </div>
          </mat-dialog-content>
          <mat-dialog-actions align="center">
              <div class="mt-3">
                  <button class="timesheet-btn solid blue-bg btn-color button create" matDialogClose (click)="closeDeleteConfirmation(data.taskInfo)">
          Yes
        </button> &nbsp;&nbsp;
                  <button class="timesheet-btn outline btn-color button create" matDialogClose (click)="closeDeleteConfirmation(null)">
          No
        </button>
              </div>
          </mat-dialog-actions>
      </div>
  </div>
</ng-template>
