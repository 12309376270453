<div fxLayout="row" fxLayoutAlign="center center" class="attachment" fxLayoutGap="8px" ahClickOutside (appOutsideClick)="toggleProgressBar($event)">
  <div class="report" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" *ngIf="uploadedAttachments.length > 0 && showFileName">
    <img src="../../../../assets/image/svg/docs.svg" height="14px">
    <!-- <div class="report-name">{{uploadedAttachments?.[0].file_name | filenameEllipsis:25}}</div> -->
  </div>
  <div class="report-count" fxLayout="row" fxLayoutAlign="center center" *ngIf="uploadedAttachments.length > 1 && showFileCount">+{{uploadedAttachments.length - 1}}</div>
  <svg width="18" height="18" viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_181_1910)">
      <path
        d="M17.2118 7.19989C17.0712 7.05929 16.8805 6.9803 16.6816 6.9803C16.4827 6.9803 16.292 7.05929 16.1513 7.19989L7.98235 15.4041C7.63413 15.7524 7.22073 16.0287 6.76574 16.2172C6.31076 16.4057 5.8231 16.5027 5.33061 16.5027C4.33599 16.5028 3.38208 16.1078 2.67872 15.4045C1.97537 14.7013 1.58019 13.7474 1.58012 12.7528C1.58005 11.7582 1.97509 10.8042 2.67835 10.1009L10.5901 2.15689C11.0132 1.74056 11.5836 1.50827 12.1772 1.51062C12.7707 1.51297 13.3393 1.74976 13.7591 2.16942C14.1788 2.58909 14.4158 3.15762 14.4182 3.75118C14.4207 4.34473 14.1886 4.91523 13.7723 5.3384L5.8606 13.2824C5.71791 13.4191 5.52794 13.4954 5.33035 13.4954C5.13275 13.4954 4.94278 13.4191 4.8001 13.2824C4.65949 13.1417 4.58051 12.951 4.58051 12.7521C4.58051 12.5533 4.65949 12.3625 4.8001 12.2219L11.8441 5.14565C11.9807 5.00419 12.0563 4.81474 12.0546 4.61809C12.0529 4.42145 11.974 4.23334 11.835 4.09428C11.6959 3.95522 11.5078 3.87635 11.3111 3.87464C11.1145 3.87293 10.925 3.94853 10.7836 4.08514L3.7396 11.1614C3.53062 11.3703 3.36486 11.6184 3.25176 11.8914C3.13866 12.1644 3.08045 12.457 3.08045 12.7525C3.08045 13.048 3.13866 13.3406 3.25176 13.6136C3.36486 13.8867 3.53062 14.1347 3.7396 14.3436C4.16831 14.7527 4.73813 14.981 5.33072 14.981C5.92331 14.981 6.49313 14.7527 6.92185 14.3436L14.8328 6.39889C15.5213 5.69262 15.9039 4.74353 15.8975 3.75722C15.8912 2.77091 15.4966 1.8268 14.7991 1.12941C14.1016 0.43202 13.1574 0.0375037 12.1711 0.0313237C11.1848 0.0251437 10.2358 0.407798 9.5296 1.09639L1.61785 9.04039C0.63323 10.025 0.0800781 11.3604 0.0800781 12.7529C0.0800781 14.1454 0.63323 15.4808 1.61785 16.4654C2.60246 17.45 3.93789 18.0032 5.33035 18.0032C6.7228 18.0032 8.05823 17.45 9.04285 16.4654L17.2118 8.26339C17.2819 8.1937 17.3375 8.11084 17.3755 8.01959C17.4134 7.92833 17.4329 7.83047 17.4329 7.73164C17.4329 7.63281 17.4134 7.53496 17.3755 7.4437C17.3375 7.35245 17.2819 7.26959 17.2118 7.19989Z" />
    </g>
    <defs>
      <clipPath id="clip0_181_1910">
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
  <div class="show-expense-date" *ngIf="showAttachmentProgress">
    <ah-attachement (emitProgress)="progress($event)" [isView]="isView" [uploadedAttachments]="uploadedAttachments" (emitAction)="closeAttachmentProgress($event)"></ah-attachement>
  </div>
</div>
<!-- (click)="showProgressBar()" -->
