import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ah-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  @Input() userInfo: any;
  @Input() showCount: boolean = false;
  @Input() size: number = 32;
  @Input() memberCount: any;
  @Input() isClick: boolean = false;
  @Input() selectedUser: any = [];
  @Output() emitUserInfo = new EventEmitter();
  colors = ['#48C171', '#4887C1', '#FB665D', '#C19148', '#C148B5'];
  constructor() { }

  ngOnInit(): void {
    // console.log(this.userInfo);
    this.updateUserColor();
  }

  updateUserColor(){
    this.userInfo = {...this.userInfo, ...{color: this.getcolor()}}
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    if(changes.userInfo && !changes.userInfo.firstChange){
      this.updateUserColor();
    }
  }

  onClick(){
    if(this.isClick){
      this.emitUserInfo.emit(this.userInfo);
    }
  }

  getcolor(){
    const randomIndex = Math.floor(Math.random() * this.colors.length);
    const randomColor = this.colors[randomIndex];
    return randomColor;
  }

}
