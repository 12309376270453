import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../../http-client/http-client.service';

@Injectable({
  providedIn: 'root'
})
export class BoardApiService {

  constructor(private httpService: HttpClientService) { }

  getTaskList(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/task/list', payload);
  }
  
  getTaskListV2(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v2/task/list', payload);
  }

  getStatusList(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/status/list', payload)
  }

  projectList(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/project/list', payload)
  }

  createTask(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/task/create', payload)
  }

  listCategory(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/category/list', payload);
  }

  listStatus(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/status/list', payload);
  }

  listUser(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/user/list', payload);
  }

  listLabel(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/label/list', payload);
  }

  listScope(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/scope/list', payload);
  }

  currentTask(): Observable<any> {
    return this.httpService.get(environment.BASE_URL + '/v1/timesheet/current/task');
  }

  createTimeLog(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/timesheet/save', payload);
  }

  updateTask(id: string, payload: any): Observable<any> {
    return this.httpService.put(environment.BASE_URL + '/v1/task/update/' + id, payload)
  }

  deleteTask(id: string): Observable<any> {
    return this.httpService.delete(environment.BASE_URL + '/v1/task/delete/' + id, null);
  }

  taskPartialUpdate(payload: any): Observable<any> {
    return this.httpService.put(environment.BASE_URL + '/v1/task/partial-update', payload);
  }

  pinUnpinTask(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/task/pinning', payload);
  }

  getSprintList(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/sprint/list', payload);
  }

  getSprintStatus(id: any): Observable<any> {
    return this.httpService.get(environment.BASE_URL + '/v1/sprint/status-check/'+id);
  }

  completeSprint(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/sprint/complete', payload);
  }
  
  getClientList(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/timesheet/client/view', payload);
  }

  getProjectList(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/timesheet/project/view', payload);
  }

  createProject(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/project/create', payload);
  }
  
  getClientSummary(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/client/summary', payload);
  }

  getProjectSummary(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/project/summary', payload);
  }

  getClient(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/client/list', payload);
  }

}
