import { Injectable, OnDestroy } from '@angular/core';
import { DataStateService } from '../data-state.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

interface SidebarState {
  sidebarObj: any;
  isClose: boolean;
  isForceRefresh: boolean;
  sidebarConfig: any;
}

@Injectable({
  providedIn: 'root'
})
export class SidebarService extends DataStateService<SidebarState> implements OnDestroy {

  private subscriptions: Subscription[] = [];

  sidebarObj$: Observable<any[]>;
  sidebarStatus$: Observable<any>;

  stateData: SidebarState = {
    sidebarObj: [],
    isClose: false,
    isForceRefresh: false,
    sidebarConfig: {
      mode: 'over',
      position: 'end',
    }
  }

  constructor() {
    super();
    const initialState: SidebarState = this.stateData;

    this.state$ = new BehaviorSubject<SidebarState>(initialState);
    this.sidebarObj$ = this.select('sidebarObj');
    this.sidebarStatus$ = this.select('isClose');
  }

  setSidebarStatus(status: boolean) {
    this.set('isClose', status);
  }

  setSidebarConfig(mode: string) {
    this.set('sidebarConfig', mode);
  }

  setSidebarObj(sidebarObj: any) {
    this.set('sidebarObj', sidebarObj);
  }

  setisForceRefresh(status: boolean) {
    this.set('isForceRefresh', status);
  }

  clear(): void{
    this.setState({sidebarObj: [], isClose: false})
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
