import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { CommonUtilsService } from 'src/app/core/services/helper/common-utils.service';

@Component({
  selector: 'ah-date-range-navigator',
  templateUrl: './date-range-navigator.component.html',
  styleUrls: ['./date-range-navigator.component.scss']
})
export class DateRangeNavigatorComponent implements OnInit {

  @Input() selectedDate: any = { startDate: null, endDate: null, label: null };
  @Output() dateEmit = new EventEmitter;

  showDatePicker: boolean = false;
  selectedStartDate: any;
  selectedEndDate: any;

  constructor(
    private commonUtil: CommonUtilsService,
  ) { }

  ngOnInit(): void {
    this.selectedStartDate = moment(this.selectedDate.startDate, 'DD-MM-YYYY')
    this.selectedEndDate = moment(this.selectedDate.endDate, 'DD-MM-YYYY');
  }

  togglePicker(event) {
    this.showDatePicker = event;
  }


  dateChange(event) {
    this.showDatePicker = false;
    this.selectedDate = event;
    this.selectedDate.label = this.commonUtil.calculateLabel(this.selectedDate.startDate, this.selectedDate.endDate);
    this.selectedStartDate = moment(this.selectedDate.startDate, 'DD-MM-YYYY')
    this.selectedEndDate = moment(this.selectedDate.endDate, 'DD-MM-YYYY');
    this.dateEmit.emit(this.selectedDate);
  }

  selectPreviousDate() {
    this.selectDate('previous');
  }

  selectNextDate() {
    if (moment(new Date(this.selectedStartDate)).format('YYYY-MM-DD') == moment(new Date()).format('YYYY-MM-DD')) {
      return;
    }
    this.selectDate('next');
  }

  selectDate(direction: 'previous' | 'next') {
    const startDate = moment(this.selectedStartDate);
    const endDate = moment(this.selectedEndDate);
    // console.log(startDate, endDate);

    // Calculate the difference in days, weeks, months, and years
    const dayDifference = endDate.diff(startDate, 'days');
    const weekDifference = endDate.diff(startDate, 'weeks', true);
    const monthDifference = endDate.diff(startDate, 'months', true);
    const yearDifference = endDate.diff(startDate, 'years');

    // Determine the appropriate unit based on the largest difference
    let unit: 'day' | 'week' | 'month' | 'year' | 'custom' = 'day';
    let difference = dayDifference;
    // console.log(difference);

    // console.log(startDate, endDate);

    // console.log(dayDifference, weekDifference, monthDifference, yearDifference)


    if (yearDifference >= monthDifference && yearDifference >= 1) {
      unit = 'year';
      // } else if (monthDifference >= 1) {
    } else if (startDate.date() === 1 && endDate.date() === moment(startDate).endOf('month').date()) {
      unit = 'month';
      difference = Math.round(monthDifference);
      // } else if (weekDifference >= dayDifference && weekDifference >= 7) {
    } else if (startDate.day() === 0 && endDate.day() === 6) {
      unit = 'week';
      difference = Math.round(weekDifference);
    } else if (dayDifference >= 1) {
      unit = 'day';
      difference = difference + 1;
    }

    if (difference === 0) {
      unit = 'day';
      difference = 1;
    }

    // if(difference > 1 && unit == 'custom'){
    //   unit = 'day';
    // }

    // console.log(unit, difference, direction);

    if (direction === 'previous') {
      this.selectedStartDate = this.selectedStartDate.subtract(difference, unit);
      this.selectedEndDate = this.selectedEndDate.subtract(difference, unit);
    } else if (direction === 'next') {
      this.selectedStartDate = this.selectedStartDate.add(difference, unit);
      this.selectedEndDate = this.selectedEndDate.add(difference, unit);
    }


    // console.log(this.selectedStartDate, this.selectedEndDate)

    // Adjust the this.selectedEndDate based on the unit
    if (unit === 'week') {
      this.selectedEndDate = this.selectedEndDate.endOf('week');
    } else if (unit === 'month') {
      // console.log(this.selectedEndDate);
      this.selectedEndDate = this.selectedEndDate.endOf('month');
    } else if (unit === 'year') {
      this.selectedEndDate = this.selectedEndDate.endOf('year');
    }

    // Convert to the desired format if needed
    const formattedStartDate = this.selectedStartDate.format('DD-MM-YYYY');
    const formattedEndDate = this.selectedEndDate.format('DD-MM-YYYY');

    // console.log('Selected Start Date:', formattedStartDate);
    // console.log('Selected End Date:', formattedEndDate);
    this.dateChange({
      'startDate': formattedStartDate,
      'endDate': formattedEndDate,
      'label': null
    });
  }

}
