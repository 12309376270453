<!-- <mat-form-field *ngIf="false">
  <input type="text" placeholder="Select options" matInput [formControl]="myControl" [matAutocomplete]="auto">
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onOptionSelected($event)">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{ option.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<mat-form-field *ngIf="false">
  <input type="text" #inputTrigger placeholder="Select items" matInput [matAutocomplete]="autoComplete" [formControl]="itemControl">
  <mat-autocomplete #autoComplete="matAutocomplete">
    <mat-option>
      <mat-checkbox [checked]="isAllSelected" (change)="toggleSelectAll()" (click)="$event.stopPropagation()">
          Select All
        </mat-checkbox>
    </mat-option>
    <mat-option *ngFor="let item of filteredItems | async;" [value]="selectedItems">
      <div (click)="optionClicked($event, item);">
        <mat-checkbox [checked]="item.selected" (change)="toggleSelection(item)" (click)="$event.stopPropagation()">
          {{ item.name }}
        </mat-checkbox>
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field> -->
<div fxLayout="row" fxLayoutAlign="start center">
  <mat-form-field appearance="outline">
    <input type="text" placeholder="Select Users" aria-label="Select Users" matInput [matAutocomplete]="auto"
      [formControl]="userControl">
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" [disableRipple]="true">
      <mat-option>
        <mat-checkbox [checked]="isAllSelected" (change)="toggleSelectAll()" (click)="$event.stopPropagation()">
          Select All
        </mat-checkbox>
      </mat-option>
      <mat-option *ngFor="let user of filteredUsers | async" [value]="selectedUsers">
        <div (click)="optionClicked($event, user)">
          <mat-checkbox [checked]="user.selected" (change)="toggleSelection(user)" (click)="$event.stopPropagation()">
            {{ user.name }}
          </mat-checkbox>
        </div>
      </mat-option>
    </mat-autocomplete>

</div>
