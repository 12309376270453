<div class="side-bar" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
  <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="header-title">{{(cloneTaskInfo?.entity_info?.name) ? cloneTaskInfo?.entity_info?.name + '-' : ''}}{{cloneTaskInfo.task_id}}</div>
    <img src="../../../../assets/image/svg/cross-planning.svg" width="14px" (click)="close()">
  </div>
  <div class="desc">
    <!-- {{cloneTaskInfo.title}} -->
    <form [formGroup]="taskCreateForm">
      <ng-container *ngIf="!showSelect; else selectTitleTemplate">
        <div (click)="showSelect=true">{{(f.title.value) ? f.title.value : 'Title'}}</div>
      </ng-container>
      <ng-template #selectTitleTemplate>
        <div class="task-title" fxLayout="row">
          <input class="input" formControlName="title" placeholder="Task Name" [maxlength]="255" (blur)="showSelect=false">
        </div>
        <div class="msg-error" fxLayout="row" *ngIf="f.title.touched && f.title.errors?.required">
          Task Name is Required
        </div>
        <div class="msg-error" fxLayout="row" *ngIf="f.title.touched && f.title.errors?.maxlength">
          Maximum allowed limit is 150 character.
        </div>
      </ng-template>
    </form>
  </div>
  <!-- <div class="border-right" fxLayout="row" fxFlex="auto" fxLayoutAlign="start center" fxLayoutGap="20px" *ngIf="false">
      <button class="ah-btn fit-width gray project" [matMenuTriggerFor]="menu" #taskMenuTrigger="matMenuTrigger">
        <span>{{ (cloneTaskInfo?.task_status_info) ? cloneTaskInfo?.task_status_info?.name : 'Task status' }} </span>
        <span class="vector"> <img src="../../../../assets/image/svg/vector.svg" /></span>
      </button>
      <mat-menu #menu="matMenu" class="select-popup-planning table">
        <div (click)="$event.stopPropagation()">
          <div fxLayout="row">
            <ng-select [items]="statusList | sortBy: 'name' : 'asc'" [isOpen]="true" bindLabel="name" bindValue="id"
              placeholder="Select Project" [(ngModel)]="selectedTaskStatus" [multiple]="false"
              (change)="onSelectedOptionsChange(cloneTaskInfo, taskMenuTrigger)">
            </ng-select>
          </div>
        </div>
      </mat-menu>
  </div> -->

  <div fxLayout="row" fxLayoutAlign="space-between center" class="wd-100">
    <ah-sprint-sidebar-header class="wd-100" [navList]="navList"
    (emitCurrentViewMode)="setCurrentViewMode($event)" [currentViewMode]="viewMode"></ah-sprint-sidebar-header>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px" class="total" *ngIf="viewMode == 'TIMELOG'">
    <div class="total-time" fxLayout="column">Total Duration: {{totalDuration}}</div>
     <a class="add-time-log" (click)="openTimeLog()" *ngIf="viewFrom == 'DASHBOARD'">+ Add Time Log</a>
  </div>
  <div class="tab-sec custom-scroll-thin" [ngSwitch]="viewMode">
    <div class="no-update" *ngSwitchCase="'ACTIVITY'" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px">
      <div class="no-update-img"><img src="../../../../assets/image/svg/no-update.svg"></div>
      <div class="no-update-title">No updates yet for this item</div>
      <div class="no-update-desc">Be the first one to update about progress, mention someone or upload files to share
        with your team members</div>
      <div class="no-update-add">Add details</div>
    </div>
    <div class="content" *ngSwitchCase="'OVERVIEW'" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="26px">
      <ah-task-overview fxFlexFill [taskName]="f.title.value" [sprintInfo]="sprintInfo" [taskId]="taskInfo.task_info.id" [taskInfo]="cloneTaskInfo" (closeTaskCreate)="close($event)"></ah-task-overview>
    </div>
    <div class="content" *ngSwitchCase="'ESTIMATION'" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px">
      <ah-estimation fxFlexFill [taskId]="taskInfo.task_info.id" [taskInfo]="cloneTaskInfo" [sprintInfo]="sprintInfo" [viewFrom]="viewFrom"></ah-estimation>
    </div>
    <div class="content" *ngSwitchCase="'COMMENT'" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
      <textarea name="" placeholder="Add a comment" id="" cols="30" rows="10"></textarea>
      <div class="comment" fxLayout="row" fxLayoutGap="12px">
        <div fxLayout="row" fxFlex="24px" class="user-assignee">
          <ah-user class="w-24 h-24"></ah-user>
        </div>
        <div class="comment-section" fxLayout="column" fxLayoutGap="12px">
          <div class="name">Nethra Balasubramaniam • <span>20/06/2023, 09:32 PM</span></div>
          <div class="desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry...</div>
        </div>
      </div>
    </div>
    <div class="content" *ngSwitchCase="'TIMELOG'" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
      <!-- <div class="date-group" fxLayout="column" *ngFor="let number of [0,1,2,3]">
        <div class="date">May 29 - Jun 4</div>
        <div fxLayout="row" class="time" fxLayoutAlign="space-between center" *ngFor="let number of [0,1,2,3]">
          <div class="time-sec">11:47 - 12:03</div>
          <div class="duration">00:00:03</div>
        </div>
      </div> -->
      <ah-planning-time-log-view (emitTimeLogOpen)="timeLogOpenEmit($event)" [timeLogOpen]="timeLogOpen" fxLayout="column" fxFlexFill [taskId]="taskInfo.task_info.id" [viewFrom]="viewFrom" (TotalDurationEmit)="totalDurationEmit($event)"></ah-planning-time-log-view>
    </div>
  </div>
</div>
