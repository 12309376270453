import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ah-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss']
})
export class ProfilePictureComponent implements OnInit {
@Input()  userInfo: any;
  constructor() { }

  ngOnInit(): void {
  }

}
