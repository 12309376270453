<!-- <div fxLayoutAlign="center center" fxLayoutGap="20px" class="time-picker" style="z-index: 99999;">
    <div class="12hr-example">
      <input matInput [ngxTimepicker]="default" [(ngModel)]="time1" (ngModelChange)="sendTime()" [max]="currentdatetime" readonly>
      <ngx-material-timepicker-toggle [for]="default">
      </ngx-material-timepicker-toggle>
        <ngx-material-timepicker #default></ngx-material-timepicker>
   </div>
</div> -->

<ng-container class="time-picker">
    <mat-form-field appearance="outline">
        <input [ngxTimepicker]="toggleIcon" matInput [format]="24" [max]="config.maxTime" [min]="config.minTime" readonly [(ngModel)]="time" (ngModelChange)="sendTime()" [placeholder]="config.placeholder">
        <ngx-material-timepicker-toggle [for]="toggleIcon" matSuffix *ngIf="false">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" ngxMaterialTimepickerToggleIcon>
              <path fill="currentColor" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8s8 3.58 8 8s-3.58 8-8 8zm.5-13H11v6l5.25 3.15l.75-1.23l-4.5-2.67z"/>
            </svg>
        </ngx-material-timepicker-toggle>
        <div class="cp" matSuffix *ngIf="config.showIcon" (click)="setCurrentTime()" [matTooltipClass]="{ 'tool-tip': true }" [matTooltip]="'Set current time'" matTooltipPosition="above">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" >
            <path fill="currentColor" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8s8 3.58 8 8s-3.58 8-8 8zm.5-13H11v6l5.25 3.15l.75-1.23l-4.5-2.67z"/>
          </svg>
      </div>
        <!-- (opened)="timerOpened()"  -->
        <ngx-material-timepicker #toggleIcon [enableKeyboardInput]="!isResponsive"></ngx-material-timepicker>
    </mat-form-field>
</ng-container>
