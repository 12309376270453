<div class="timesheet" fxLayout="row" fxLayoutAlign="center center">
  <div class="timesheet-app">
    <mat-dialog-content>
      <div fxLayout="column" fxLayoutAlign="start start" class="text-center mt-1">
        <p class="mt-2">
          Are you sure you want to delete?
        </p>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
      <div class="mt-3 action">
        <button class="timesheet-btn solid blue-bg btn-color button create" matDialogClose
          (click)="closeDeleteConfirmation(data.itemInfo)">
          Yes
        </button>
        <button class="timesheet-btn outline btn-color button create" matDialogClose
          (click)="closeDeleteConfirmation(null)">
          No
        </button>
      </div>
    </mat-dialog-actions>
  </div>
</div>
