import { TimerService } from './../../timer-service/timer.service';
import { Injectable, OnDestroy } from '@angular/core';
import { map, of, Subscription, switchMap, concat, Observable, tap, BehaviorSubject } from 'rxjs';
import { BoardAppState } from 'src/app/shared/interface/BroadAppState';
import { BoardApiService } from '../../api/board-api/board-api.service';
import { CommonStorageService } from '../../storage/common-storage.service';
import { DataStateService } from '../data-state.service';
import { ReferenceName as refName } from '../../../../shared/enum/reference-name.enum';

@Injectable({
  providedIn: 'root'
})
export class BoardStateService extends DataStateService<BoardAppState> implements OnDestroy {

  private subscriptions: Subscription[] = [];

  taskList$: Observable<any[]>;

  stateData: BoardAppState = {
    taskList: [],
    taskIds: [],
    statusList: [],
    currentTimerLog: [],
    isForceRefresh: false,
    isSideBarUpdate: false,
  }

  constructor(
    private boardApi: BoardApiService,
    private storage: CommonStorageService,
    private commonStorage: CommonStorageService,
    private timerService: TimerService,
  ) {
    super();
    const initialState: BoardAppState = this.stateData;
    this.state$ = new BehaviorSubject<BoardAppState>(initialState);

    this.taskList$ = this.select('taskList').pipe(
			tap((results) => {
				results.sort(
					(a, b) =>
						new Date(b.task.updated_at).getTime() - new Date(a.task.updated_at).getTime()
				);
			})
		);
    // this.taskList$ = this.select('taskList');
  }

  addToBoard(data: any, count?: number): void {
    // let postProcessData = this.postProcessDashboard(data);
    const boardData = this.selectSnapshot('taskList');
    let newBoardData = this.verifyIdExist(data);
    this.setState({ taskList: [...boardData, ...newBoardData] });
    // console.log(data, newBoardData, this.selectSnapshot('taskList'));
  }

  updateToBoard(taskObj): void {
		const taskList = this.selectSnapshot('taskList');
    // console.log(taskList, taskObj);
    // if(taskObj?.task?.task_status_info?.nature == 'DELETED'){
    //   this.removeBoard(taskObj.task.id)
    //   return;
    // }
		this.setState({
			taskList: taskList.map((x) => (x.task.id === taskObj.task.id ? taskObj : x)),
		});
    this.setIsSideBarUpdate(true);
	}

  postProcessDashboard(data) {
    let statusList = this.selectSnapshot('statusList');
    let dashboradTaks = statusList.map((task) => {
      let obj2 = data.filter((b) => task.id === b.task.task_status_info?.id);
      // console.log(obj2, task.id);
      if (obj2) {
        task = {
          ...task,
          ...{
            task_list: obj2.map((x) => {
              x = {
                ...x.task, ...{
                  'members': x.members,
                  'check_list': x.checklists
                }

              }
              return x
            }),
          },
        };
      }
      else {
        task = {
          ...task,
          ...{
            task_list: [],
          },
        };
      }
      return task;
    });
    return dashboradTaks;
  }

  verifyIdExist(data: any) {
    const existingBoardIds = this.selectSnapshot('taskIds');
    let dataList = [];
    let dataListIds = [];
    for (let _data of data) {
      if (!existingBoardIds.includes(_data.task.id)) {
        dataList.push(_data);
        dataListIds.push(_data.task.id)
      }
    }
    this.setState({ taskIds: [...existingBoardIds, ...dataListIds] });
    return dataList;
  }

  listboardData(payload: any) {
    return of(payload).pipe(
      switchMap((payload) =>
        concat(
          of({ type: 'start' }),
          this.boardApi.getTaskList(payload).pipe(
            map((res: any) => {
              this.addToBoard(res.data.tasks);
              // return { type: 'finish', value: this.postProcessDashboard(res.data.tasks) };
              return { type: 'finish', value: res.data.tasks };
            })
          )
        )
      )
    );
  }

  removeBoard(task_id: string): void {
		const statetaskList = this.selectSnapshot('taskList');
    const taskList= this.removeById(statetaskList, task_id);
    this.setState({ taskList });
    this.setState({ isForceRefresh: true });
    // console.log(this.selectSnapshot('taskList'));
	}

  pinnedTaskBoardData(data: any, task_id :any): void {
    let taskList = this.selectSnapshot('taskList');
    // console.log(taskList, 'test');
    const taskIndex = taskList.findIndex(x => x.task.id == task_id );
    const exisitingPinnedIndex = taskList.findIndex(x => x.task.is_pinned);
    // if(exisitingPinnedIndex > -1){
    //   taskList[exisitingPinnedIndex].task.is_pinned = false;
    // }
    if(taskIndex > -1){
      taskList.splice(taskIndex,1);
      taskList.unshift(data);
      this.setState({taskList});
    }
    // console.log(this.selectSnapshot('taskList'));
    this.updateToBoard(data);
  }

  setForceRefresh(data: boolean){
    this.setState({ isForceRefresh: data });
  }

  setIsSideBarUpdate(status: boolean){
    this.setState({ isSideBarUpdate: status });
  }

  removeById(taskItems: any[], id: string) {
    const idx  = taskItems.findIndex((element) => {
      return element.task.id === id;
    });
    if (idx >= 0 ) {
      taskItems.splice(idx,1);
    }
    return taskItems;
  }


	clearBoard() {
		this.setState({ taskList: [], taskIds: [] });
	}

  setStatusList(newStatusList: any): void{
    // const statusData = this.selectSnapshot('statusList');
    this.setState({ statusList: newStatusList });
  }

  getStatusList(){
    return this.selectSnapshot('statusList');
  }

  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }

  reset() {
    this.setState(this.stateData);
  }

  getTimerLog(){
    return this.selectSnapshot('currentTimerLog') || this.storage.get(refName.TASK_TIMER);
  }

  setTimerLog(data: any){
    this.setState({currentTimerLog: data})
  }

  clearTimerLog(){
    this.timerService.stop();
    this.commonStorage.remove(refName.TASK_TIMER);
    this.setState({currentTimerLog: []});
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
