import { CommonUtilsService } from '../../../core/services/helper/common-utils.service';
import { BoardStateService } from 'src/app/core/services/data/board-state/board-state.service';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TimelogApiService } from 'src/app/core/services/api/timelog-api/timelog-api.service';
import { CommonStorageService } from 'src/app/core/services/storage/common-storage.service';
import { Observable, Subscription, filter, map } from 'rxjs';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { TimelogCreatePopupComponent } from 'src/app/shared/components/timelog-create-popup/timelog-create-popup.component';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { CommonDataService } from 'src/app/core/services/data/common-data/common-data.service';
import { DeleteConfirmationComponent } from '../delete-confirmation/delete-confirmation.component';
import { Client, NoDataPlaceholder } from '../../interface/master-data';
import { TimeLogDate, Timesheet } from '../../interface/timesheet';
import { CombinedList, PayloadToUpdate, ProjectNames } from '../../interface/ClientView';
import { BoardApiService } from 'src/app/core/services/api/board-api/board-api.service';
import { PermissionService } from 'src/app/core/services/permission/permission.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { AuthDataService } from 'src/app/core/services/data/auth-data/auth-data.service';
import { WebsocketService } from 'src/app/core/services/websocket/websocket.service';


@Component({
  selector: 'ah-time-log',
  templateUrl: './time-log.component.html',
  styleUrls: ['./time-log.component.scss']
})
export class TimeLogComponent implements OnInit {

  @Input() startDate: string;
  @Input() endDate: string;
  @Input() projectId: string = '';
  @Input() taskId: string = '';
  @Input() modal;
  @Input() routeParamType: string = null;
  subscriptions: Subscription[] = [];
  selectedStartDate: Date | moment.Moment; // this variable might have moment object
  selectedEndDate: Date | moment.Moment; // this variable might have moment object
  limit = 25;
  keyword: string = null;
  displayedColumns: string[] = ['project_title', 'Task_name', 'description', 'date', 'start_time', 'end_time', 'time_log', 'action'];
  dataSource: MatTableDataSource<Timesheet>;
  requestInProgress: boolean = false;
  timerState: Timesheet;
  private sort: MatSort;
  pageNumber: number = 1;
  totalTasks: number;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  lastPage: boolean;
  pageNumbers: number;
  maxDate: Date;
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    // this.setDataSourceAttributes();
    this.sortingBackend();
  }
  timeLogList: Timesheet[] = [];
  totalDuration: string = '0';
  pageSizeOptions: number[] = [25, 50, 100];
  sortArray = [];
  noDataPlaceholder : NoDataPlaceholder = {
    title: "No Time Log's to Show",
    description:"Looks like you haven't added any time log for this date",
    isShowButton: true,
    buttonText: "+ Add Time Log"
  }

  showDatePicker: boolean = false;
  selectedDate: TimeLogDate = { startDate: null, endDate: null, label: null };
  timer$: Observable<any> = this.commonUtil.timer$;
  appDateFormat = "DD-MM-YYYY";
  clientlist: Client [] = [];
  projectNames: ProjectNames [] = [];
  combinedList: CombinedList[] = [];
  payloadToUpdate: PayloadToUpdate;
  hasPermission: boolean = false;

  constructor(
    private timeLogApiService: TimelogApiService,
    private storage: CommonStorageService,
    private boardState: BoardStateService,
    private dialog: MatDialog,
    private commonUtil: CommonUtilsService,
    private router: Router,
    private commonState: CommonDataService,
    private boardApiService: BoardApiService,
    private permissionService: PermissionService,
  ) {
  }

  ngOnInit(): void {
    this.getClientSummary();
    this.projectList();

    this.maxDate = new Date();
    const newTimeLog = this.commonState.select('currentData').subscribe(res => {
      if (res) {
        const timelogId = this.commonState.selectSnapshot('timelogId');
        const index =  this.timeLogList.findIndex(x=>x.details_id == timelogId);
        if(index > -1){
          this.getTimesheetList();
        }
        this.commonState.setTimeLogId(null);
        this.commonState.addTimeLog(false);
      }
    });
    this.setSubscription(newTimeLog);
    const timerState = this.boardState.select('currentTimerLog').subscribe(res => {
      this.timerState = res;
      if(!this.requestInProgress){
        this.getTimesheetList();
      }
    });
    this.setSubscription(timerState);
    this.changeFormating();

    if (this.taskId) {
      this.displayedColumns = ['description', 'date', 'start_time', 'end_time', 'time_log', 'action'];
    }

    const moduleEntries = Object.entries(this.permissionService.getPermission());
    this.hasPermission = moduleEntries.some(([key, value]) => {
      return key === 'clientView';
    });

    if(this.hasPermission){
      this.displayedColumns = ['project_title', 'description', 'date', 'start_time', 'end_time', 'time_log', 'action'];
    }

    const timeLogDate = this.commonState.select('currentTimeLogDate').subscribe(res => {
      if (res) {
        const newDate = new Date(res);
        if (!this.isCurrentDateInRange(newDate, this.selectedStartDate, this.selectedEndDate)) {
          this.selectedStartDate = newDate;
          this.selectedEndDate = newDate;
        }
        this.commonState.addTimeLogDate(null);
        this.dateChange({ 'startDate': this.momentConversion(this.selectedStartDate, this.appDateFormat).format(this.appDateFormat), 'endDate': this.momentConversion(this.selectedEndDate, this.appDateFormat).format(this.appDateFormat), 'label': null })
      }
    });
    this.setSubscription(timeLogDate);
  }


  getClientSummary() {
    const payload = {
      filters: {
        timesheet_filters: {
          start_date: '',
          end_date: '',
        },
        show_all: true,
      },
    };

    const clientList = this.boardApiService
      .getClientSummary(payload)
      .subscribe({
        next: (res) => {
          this.clientlist = res.data.clients.map((client) => ({
            id: client.id,
            name: client.name,
            type: 'CLIENTS',
          }));
        },
        error: (error) => {
          // console.error(error);
        },
      });
    this.setSubscription(clientList);
  }

  projectList() {
    let payload = {
      filters: {
        entity_type: 'TASK',
        show_all: true,
        order_by: 'updated_at',
        order_type: 'desc',
      },
    };
    const projectList = this.boardApiService.projectList(payload).subscribe({
      next: (res) => {
        this.projectNames = res.data.project.map((project) => ({
          id: project.id,
          name: project.name,
          // type: 'PROJECTS',
          type: project?.client_info ? project?.client_info?.name : 'untagged',
        }));
        this.combinedList = [...this.projectNames];

      },
      error: (error) => {
        // console.error(error);
      },
    });
    this.setSubscription(projectList);
  }

  selectedTagItem(event, details) {
    // console.log(event);
    // console.log(details);
    this.payloadToUpdate = {
      time_format: 'SECONDS',
      timesheets: [
        {
          entity_id: event.id,
          entity_type: 'PROJECT',
          description: details.description,
          category: {
            id: details.category_id,
          },
          start_date: details.start_date,
          end_date: details.end_date,
          start_time: details.start_time_sec,
          end_time: details.end_time_sec,
          duration: details.duration,
          label: details.label || null,
          scope: {
            id: details?.scope?.id,
          },
          task_id: details.task_id ? details.task_id : null,
          id: details.details_id,
        },
      ],
    };
    // console.log(this.payloadToUpdate);


    const createTimeLog = this.boardApiService.createTimeLog(this.payloadToUpdate).subscribe({
        next: (res) => {
          this.getTimesheetList();
          // this.commonUtil.getCurrentActiveTask();
        },
        error: (error) => {
      }});
    this.setSubscription(createTimeLog);
  }



  dateChange(event: TimeLogDate) {
    this.updateSelectedDates(event.startDate, event.endDate);
  }

  datePickerEmit(event: TimeLogDate) {
    this.updateSelectedDates(event.startDate, event.endDate);
  }

  updateSelectedDates(startDate: string, endDate: string) {
    this.showDatePicker = false;
    this.selectedDate = { startDate, endDate, label: this.commonUtil.calculateLabel(startDate, endDate) };
    this.selectedStartDate = this.momentConversion(startDate, this.appDateFormat);
    this.selectedEndDate = this.momentConversion(endDate, this.appDateFormat);
    this.pageNumber = 1;
    if(this.paginator) {
      this.paginator.pageIndex = 0;
    }

    this.getTimesheetList();
  }

  isCurrentDateInRange(currentDate, startDate, endDate): boolean {
    return currentDate >= startDate && currentDate <= endDate;
  }

  changeFormating() {
    const currentDate = new Date();
    this.selectedDate = {
      'startDate': this.changeDateFormat(currentDate, this.appDateFormat),
      'endDate': this.changeDateFormat(currentDate, this.appDateFormat),
    }
    this.selectedDate.label = this.commonUtil.calculateLabel(this.selectedDate.startDate, this.selectedDate.endDate);
    // console.log(moment(this.selectedDate.startDate, this.appDateFormat), moment(this.selectedDate.endDate, this.appDateFormat), this.selectedStartDate, this.selectedEndDate);
    this.selectedStartDate = this.momentConversion(this.selectedDate.startDate, this.appDateFormat);
    this.selectedEndDate = this.momentConversion(this.selectedDate.endDate, this.appDateFormat);
    this.getTimesheetList();
  }

  navigateToTimeLog(id: string, routeParamsType: string='TASK') {
    if(!id || !['PROJECT', 'TASK'].includes(routeParamsType) || this.projectId || (routeParamsType == 'PROJECT' && !this.hasPermission) || this.taskId){
      return;
    }
    this.router.navigate(['./dashboard/time-log/', { outlets: { 'ah-router-modal': ['viewLog', id] } }], {queryParams: { type: routeParamsType }});
  }

  setDataSourceAttributes() {
    if (this.sort) {
      this.dataSource.sort = this.sort;
      const dataSource = this.sort.sortChange.subscribe(() => {
        if (this.sort.active === 'project_title') {
          const data = this.timeLogList;
          const isAscending = this.sort.direction === 'asc';
          data.sort((firstItem, secondItem) => {
            return this.compareString(firstItem.entity_info?.name || '-', secondItem.entity_info?.name || '-', isAscending);
          });
        }
      });
      this.setSubscription(dataSource)
    }
  }

  sortingBackend() {
    if (!this.sort) {
      return;
    }

    const sortingBackend = this.sort.sortChange
      .pipe(
        filter(res => !!res.direction),
        map(res => {
          let sortBy = res.active.toLowerCase();
          if (sortBy === 'start_time' || sortBy === 'date') {
            sortBy = 'start_date';
          } else if (sortBy === 'end_time') {
            sortBy = 'end_date';
          } else if (sortBy === 'project_title') {
            sortBy = 'project';
          }

          return [{
            "sort_by": sortBy,
            "sort_type": res.direction.toUpperCase()
          }];
        })
      )
      .subscribe(sort => {
        this.sortArray = sort;
        this.getTimesheetList();
      });

    this.setSubscription(sortingBackend);
  }

  compareString(firstString: string, secondString: string, isAscending: boolean) {
      const order = isAscending ? 1 : -1;
      return firstString.localeCompare(secondString) * order;
  }

  pageChanged(event: PageEvent) {
    // console.log(event);

    if (event.pageIndex < event.previousPageIndex) {
      if (this.pageNumber > 0) {
        this.pageNumber = Number(this.pageNumber) - 1;
      }
    }
    if (event.pageIndex > event.previousPageIndex) {
      this.pageNumber = Number(this.pageNumber) + 1;
    }
    if (this.limit != event.pageSize) {
      this.pageNumber = 1;
    }
    this.limit = event.pageSize;
    this.getTimesheetList();
  }

  goToChange(input) {
    let pageNumber = (input as HTMLInputElement).value;
    this.pageNumber = Number(pageNumber);
    this.getTimesheetList();
  }

  getTimesheetList() {
    if(this.requestInProgress){
      return;
    }
    // console.log(this.startControl.value, this.endControl.value);
    // this.startControl.value > this.endControl.value ||
    if (!this.selectedStartDate || !this.selectedEndDate) {
      return;
    }
    const userInfo = this.storage.get('user_info');
    const user_id = userInfo ? userInfo.id : null;
    // this.selectedStartDate = this.startControl.value ? this.startControl.value : this.selectedStartDate;
    // this.selectedEndDate = this.endControl.value ? this.endControl.value : this.selectedEndDate;

    // console.log(this.pageNumber);
    const payload = {
      filters: {
        user_id,
        keyword: this.keyword,
        page_no: this.pageNumber,
        limit: this.limit,
        sort_type: 'desc',
        sort_option: this.sortArray
        // start_date: this.changeDateFormat(this.selectedStartDate),
        // end_date: this.changeDateFormat(this.selectedEndDate),
      }
    };


    if(this.projectId) {

      payload['filters']['projects'] = [{ id: this.projectId}];
      payload['filters']['start_date'] = this.startDate? this.changeDateFormat(this.startDate): null;
      payload['filters']['end_date'] =this.endDate? this.changeDateFormat(this.endDate): null;

    }

    else if (this.taskId) {
      payload['filters']['tasks'] = {
        id: this.taskId
      }
    }
    else {
      payload['filters']['start_date'] = this.changeDateFormat(this.selectedStartDate);
      payload['filters']['end_date'] = this.changeDateFormat(this.selectedEndDate);
    }

    // console.log("PAYLOAD", payload);

    this.requestInProgress = true;
    const TimeSheetAPI = this.timeLogApiService.timesheetList(payload).subscribe({
      next: (res) => {
        this.requestInProgress = false;
        let response = res.data;
        this.timesheetApiResponse(response);
      },
      error: (error) => {
        this.requestInProgress = false;
        // console.error(error);
      }
    });
    this.setSubscription(TimeSheetAPI);
  }

  timesheetApiResponse(res: any) {
    this.timeLogList = res.timesheets;
    // console.log("TIMESHEET LISTTT ",this.timeLogList);

    const duration = this.timeLogList.filter(x => x.duration_min > 0).map(x => x.duration_min) || [];
    this.totalDuration = this.commonUtil.convertToHourAndMinutes(res.summary.total_duration_in_minutes);
    this.timeLogList.sort((a, b) => new Date(b.date + ' ' + b.start_time + ':00').getTime() - new Date(a.date + ' ' + a.start_time + ':00').getTime());
    this.dataSource = new MatTableDataSource(this.timeLogList);
    this.dataSource.sort = this.sort;
    if (this.paginator) {
      this.paginator.length = res.summary.total_count;
    }
    this.totalTasks = res.summary.total_count;
    // this.lastPage = res.summary.end_of_marker;
    let page = Math.round(res.summary.total_count / this.limit);
    this.pageNumbers = (page > 0) ? page : 1;
  }

  editTimeLog(item) {
    // console.log(item);

    if (item.details_id == this.timerState.details_id) {
      return;
    }
    const dialogRef = this.dialog.open(TimelogCreatePopupComponent, {
      panelClass: 'ah-add-task',
      maxWidth: '500px',
      maxHeight: '700px',
      width: '100%',
      disableClose: true,
      autoFocus: false,
      data: {
        payload: item,
        isUpdate: true
      }
    });
    const isClosed = dialogRef.afterClosed().subscribe((value) => {
      if (value) {
        this.getTimesheetList();
      }
    });
    this.setSubscription(isClosed);
  }

  deleteTimeLog(item) {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      height: '220px',
      width: '450px',
      disableClose: true,
      data: {
        itemInfo: item
      }
    });
    const isClosed = dialogRef.afterClosed().subscribe((value) => {
      if (value) {
        this.closeDeleteConfirmation(item);
      }
    });
    this.setSubscription(isClosed);
  }

  checkSameDay() {
    const startDateObj = new Date(this.changeDateFormat(this.selectedStartDate));
    const endDateObj = new Date(this.changeDateFormat(this.selectedEndDate));

    return (
      startDateObj.getFullYear() === endDateObj.getFullYear() &&
      startDateObj.getMonth() === endDateObj.getMonth() &&
      startDateObj.getDate() === endDateObj.getDate()
    );
  }

  openDialog() {
    const data = this.projectId ? { project_id: this.projectId } : { task_id: this.taskId };
    const startDate = this.checkSameDay() ? this.selectedStartDate : moment(new Date());
      this.dialog.open(TimelogCreatePopupComponent, {
        panelClass: 'ah-add-task',
        maxWidth: '500px',
        maxHeight: '700px',
        width: '100%',
        disableClose: true,
        autoFocus: false,
        data: {...data, startDate: startDate, isTimelog: true}
      });
  }

  closeDeleteConfirmation(taskInfo) {
    let id = taskInfo.details_id
    this.requestInProgress = true;
    const timeLogDelete = this.timeLogApiService.deleteTimeSheet(id).subscribe(
      {
        next: (res) => {
          this.requestInProgress = false;
          this.commonUtil.getCurrentActiveTask();
          // this.getTimesheetList();
          this.commonState.addTimeLog(true);

          this.updatePageAfterDelete();
        },
        error: (error) => {

          this.requestInProgress = false;
        },
      }
    )
    this.setSubscription(timeLogDelete);
  }

  updatePageAfterDelete() {
    // if (this.pageNumber === 1) {
    //   this.paginator.length = this.paginator.length - 1;
    //   this.getTimesheetList();
    // }
   if (this.pageNumber > 1 && this.timeLogList.length === 1) {
      this.pageNumber = this.pageNumber - 1;
      this.paginator.pageIndex = this.pageNumber - 1;
    }
      this.paginator.length = this.paginator.length - 1;
      this.getTimesheetList();

  }

  close() {
    this.modal.close();
  }

  get ProjectInfo() {
    return this.timeLogList.find(x => x.task_id == this.taskId)
  }

  get clientName() {
    return this.timeLogList.find(x => x.entity_id === this.projectId)?.entity_info?.client_info?.name;
  }

  get projectName() {
    return this.timeLogList.find(x => x.entity_id === this.projectId)?.entity_info?.name;
  }


  changeDateFormat(inputDate, format = "YYYY-MM-DD") {
    return this.commonUtil.changeDateFormat(inputDate, format);
  }

  momentConversion(date, format){
    return moment(date, format);
  }

  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
