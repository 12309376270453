import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filenameEllipsis'
})
export class FilenameEllipsisPipe implements PipeTransform {

  transform(value: string, maxLength: number = 10): string {
    if (value.length <= maxLength) {
      return value;
    }

    const filename = value.split('.').slice(0, -1).join('.');
    const extension = value.split('.').pop();
    const truncatedFilename = filename.length > maxLength
      ? filename.substr(0, maxLength - 3) + '...'
      : filename;

    return truncatedFilename + '.' + extension;
  }

}
