<ng-template #ahModal>
  <div class="ah-modal__wrapper">
    <div *ngIf="!hideClose" class="close__btn">
      <img src="../../../../../assets/image/svg/modal/close.svg" (click)="close()" />
      <!-- <mat-icon (click)="close()">highlight_off</mat-icon> -->
    </div>

    <div mat-dialog-title>
      <ng-content select="[slot=modal-header]"></ng-content>
    </div>

    <div mat-dialog-content class="custom-scroll">
      <ng-content select="[slot=modal-body]"></ng-content>
    </div>

    <div mat-dialog-actions>
      <ng-content select="[slot=modal-footer]"></ng-content>
    </div>
  </div>
</ng-template>
