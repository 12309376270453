import { Injectable } from '@angular/core';
import { HttpClientService } from '../../http-client/http-client.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlanningApiService {

  constructor(private httpService: HttpClientService) { }

  planningList(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/sprint/list', payload);
  }

  estimationList(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/sprint/task/estimation/list', payload);
  }

  estimationListByTaskId(id: string): Observable<any> {
    return this.httpService.get(environment.BASE_URL + '/v1/sprint/task/estimation/view/' + id, null);
  }

  estimationListBySprintId(id: string): Observable<any> {
    return this.httpService.get(environment.BASE_URL + '/v1/sprint/estimation/' + id, null);
  }

  createEstimation(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/sprint/task/estimation/create', payload);
  }

  createSprint(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/sprint/save', payload);
  }

  sprintPartialUpdate(payload: any): Observable<any> {
    return this.httpService.put(environment.BASE_URL + '/v1/sprint/partial/update', payload);
  }

  timesheetList(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/timesheet/list', payload);
  }

  sprintTaskMapping(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/sprint/task/add', payload);
  }


  deleteSprint(id: any): Observable<any> {
    return this.httpService.delete(environment.BASE_URL + '/v1/sprint/delete/' + id, null);
  }

  deleteEstimation(id: any): Observable<any> {
    return this.httpService.delete(environment.BASE_URL + '/v1/sprint/task/estimation/delete/' + id, null);
  }

  listTaskBySprint(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/sprint/task/list', payload);
  }

  backlogList(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/planning/list', payload);
  }

  backlogGroupList(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/planning/group/list', payload);
  }

  backlogItemList(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/planning/group/item/list', payload);
  }

  createGroup(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/planning/group/save', payload);
  }

  updateGroup(payload: any, groupId): Observable<any> {
    return this.httpService.put(environment.BASE_URL + '/v1/planning/group/update/'+ groupId, payload);
  }

  createGroupItem(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/planning/group/item/save', payload);
  }

  deleteGroup(groupId: string): Observable<any> {
    return this.httpService.delete(environment.BASE_URL + '/v1/planning/group/delete/'+ groupId, null);
  }

  deleteGroupItem(itemId: string): Observable<any> {
    return this.httpService.delete(environment.BASE_URL + '/v1/planning/group/item/delete/'+itemId, null);
  }

  listGroupItem(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/planning/group/item/list', payload);
  }

  groupItemPartialUpdate(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/planning/group/item/update/partial', payload);
  } 

  groupItemComplete(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/planning/group/item/complete', payload);
  }
}
