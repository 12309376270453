import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ah-sprint-sidebar-header',
  templateUrl: './sprint-sidebar-header.component.html',
  styleUrls: ['./sprint-sidebar-header.component.scss']
})
export class SprintSidebarHeaderComponent implements OnInit {

  @Input() navList: any;
  @Input() currentViewMode: string = 'OVERVIEW';
  @Output() emitCurrentViewMode = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.emitCurrentViewMode.emit(this.currentViewMode);
  }

  selectCurrentSidebar(navCode){
    this.currentViewMode = navCode;
    this.emitCurrentViewMode.emit(navCode);
  }
}
