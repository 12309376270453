import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
	transform(array: any, field: string, type:string): any[] {
		if (!Array.isArray(array)) {
			return array;
		}
		array.sort((a: any, b: any) => {
			if (a[field] < b[field]) {
				return -1;
			} else if (a[field] > b[field]) {
				return 1;
			} else {
				return 0;
			}
		});
		if(type == 'desc'){
			array.sort((a: any, b: any) => {
				if (a[field] > b[field]) {
					return -1;
				} else if (a[field] < b[field]) {
					return 1;
				} else {
					return 0;
				}
			});
		}
		return array;
	}
}
