<div class="app-loader">
  <div [ngClass]="{'overlay': true}">
    <div class="bg-overlay"></div>
    <!-- <div class="loader"> -->
      <!-- <div class="nest"></div> -->
      <!-- <div class="spinner"></div> -->
      <!-- <div class="loading"></div> -->
      <div class="loader-animation">
        <img src="../../../../../assets/image/gif/Full-Clock.gif">
      </div>
    <!-- </div> -->
    <p class="spinner-message" *ngIf="text && text !== ''">
      {{text}}
    </p>
  </div>
</div>
