<div fxFlex="500px" fxLayout="column" fxLayoutAlign="start" class="side-nav">
  <div class="header" fxLayout="row" fxFlex="auto" fxLayoutAlign="space-between center" fxLayoutGap="30px">
    <div>{{!sprintId ? 'Create Sprint' : 'Update Sprint' }}</div>
    <div (click)="close()" class="cp"><img src="../../../assets/image/svg/close.svg" alt="" width="12px"></div>
  </div>
  <div class="body" fxFlex="auto" fxLayoutGap="15px">
    <form [formGroup]="sprintCreateForm" fxFlex="auto" fxLayout="column">
      <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px" class="mb-10">
        <div class="title">Sprint Name <span class="msg-error">*</span></div>
        <mat-form-field appearance="outline">
          <input formControlName="sprint_name" placeholder="Task Name" matInput [maxlength]="255">
        </mat-form-field>
        <div class="msg-error" *ngIf="f.sprint_name.touched && f.sprint_name.errors?.required">
          Sprint Name is Required
        </div>
        <div class="msg-error" *ngIf="f.sprint_name.touched && f.sprint_name.errors?.maxlength">
          Maximum allowed limit is 150 character.
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px" class="mb-10">
        <div class="title">Projects</div>
        <ng-select [items]="projectList" bindLabel="name" bindValue="id" placeholder="Select Project"
          formControlName="project_id">
        </ng-select>
        <div class="msg-error" *ngIf="f.project_id.touched && f.project_id.errors?.required ">
          Project is Required
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="10px" class="mb-10">
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxLayout="column" fxLayoutAlign="start start" fxFlex="220px" fxLayoutGap="8px">
            <div class="title">Start Date</div>
            <ah-date-picker fxFlexFill (dateChange)="datePickerChange($event, 'StartDate')"
              [config]="{placeholder: 'Start Date', currentValue: f.start_date?.value }"></ah-date-picker>
          </div>
          <div fxLayout="column" fxLayoutAlign="start start" fxFlex="220px" fxLayoutGap="8px">
            <div class="title">End date</div>
            <ah-date-picker fxFlexFill (dateChange)="datePickerChange($event, 'EndDate')"
              [config]="{placeholder: 'End Date', currentValue: f.due_date?.value}"></ah-date-picker>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px" class="mb-10">
        <div class="title">Description</div>
        <textarea formControlName="description" [maxlength]="201" ahCountdown></textarea>
        <div class="msg-error" *ngIf="f.description.touched && f.description.errors?.maxlength">
          Maximum allowed limit is 250 character.
        </div>
      </div>
    </form>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" class="single-row" fxFlex="auto" fxLayoutGap="8px">
    <a class="button fill" fxLayout="column" fxFlex="100px" fxLayoutAlign="center center" (click)="createPayload()">
      {{ !sprintId ? 'Save' : 'Update' }} </a>
  </div>
</div>
<ah-spinner *ngIf="requestInProgress" [config]="{fullscreen: true}"></ah-spinner>
