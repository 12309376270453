import { Buffer } from 'buffer/';

export const dataUrlToFile = (
	dataUrl: string,
	filename: string,
	toBlob: boolean = false
): File | Blob | undefined => {
	const arr = dataUrl.split(',');
	if (arr.length < 2) {
		return undefined;
	}
	const mimeArr = arr[0].match(/:(.*?);/);
	if (!mimeArr || mimeArr.length < 2) {
		return undefined;
	}
	const mime = mimeArr[1];
	const buff = Buffer.from(arr[1], 'base64');
	if (toBlob) {
		return new Blob([buff], { type: mime });
	} else {
		return new File([buff], filename, { type: mime });
	}
};
