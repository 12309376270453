import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../../http-client/http-client.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileApiService {

  constructor(private httpService: HttpClientService) { }

  getUserProfile(): Observable<any> {
    return this.httpService.get(environment.BASE_URL + '/user/profile/view', null);
  }

  getUserPermission(): Observable<any> {
    return this.httpService.get(environment.BASE_URL + '/v1/role/user/modules', null);
  }

  updateProfile(payload): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/user/profile/update', payload);
  }

  uploadAsset(payload): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/asset/upload', payload);
  }

  updateProfileImage(payload): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/user/profile/image/update', payload);
  }

}
