<div class="show-filter" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="clear small" fxLayout="row" fxLayoutAlign="start start" (click)="selectAll()">Select All</div>
        <div class="clear small" fxLayout="row" fxLayoutAlign="end start" (click)="clear()">Clear All</div>
    </div>
    <ng-select [items]="itemList | sortBy: bindLabelText : 'asc'" [isOpen]="true" [clearSearchOnAdd]="true" [(ngModel)]="selectedItems" [bindLabel]="bindLabelText" [bindValue]="bindValueText" [placeholder]="placeholder" [multiple]="true" [closeOnSelect]="false" appendTo=".child-filter"
        notFoundText="No Project" (close)="toggleSelection(false)" (change)="addSelection()">

        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> {{item.name}}
        </ng-template>
    </ng-select>
    <div class="child-filter custom-scroll-thin"></div>
    <!-- <div class="create" (click)="toggleSelection(false, 'PROJECT')">
Apply
</div> -->
</div>
