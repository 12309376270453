<section fxLayout="row" fxLayoutAlign="center center" class="alert">
  <div>
    <div mat-dialog-content fxLayout="row" fxLayoutAlign="center center" class="message">
      {{message}}
    </div>
    <div mat-dialog-actions fxLayout="row" class="mt-22" fxLayoutAlign="center center">
      <button mat-raised-button color="primary" (click)="onConfirmClick(true)" tabindex="1">{{confirmButtonText}}</button>
      <button mat-raised-button  (click)="onConfirmClick(false)" tabindex="-1">{{cancelButtonText}}</button>
      <!-- mat-dialog-close -->
    </div>
    <div fxLayout="row" class="mt-32" fxLayoutAlign="center center">
      <div>
        <input class="input-checkbox" id="styled-checkbox-1" type="checkbox" (change)="checkValue($event)" >
        <label for="styled-checkbox-1">Don't want to show again</label>
      </div>
    </div>
  </div>
</section>
