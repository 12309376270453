<div fxLayout="column" fxLayoutAlign="center center" fxFlex="500px" fxFlex.xs="100%" fxFlex.sm="100%"
  class="change-password">
  <div class="header" fxLayout="row" fxFlex="auto" fxLayoutAlign="space-between center" fxLayoutGap="30px">
    <div>Change Password</div>
    <div (click)="close()" class="cp">
      <img src="../../../../assets/image/svg/close.svg" alt="" width="12px">
    </div>
  </div>
  <div class="body" fxLayout="column" fxLayoutGap="15px">
    <form fxLayout="column" class="change-password-form" [formGroup]="changePasswordForm">
      <div fxLayout="column" fxLayoutAlign="start start" fxFlex="auto" fxLayoutGap="8px">
        <div class="title">Old Password <span class="msg-error">*</span></div>
        <mat-form-field appearance="outline">
          <input type="{{showOldPassword  ? 'text' : 'password'}}" matInput formControlName="oldPassword"
            placeholder="Enter old password">
          <mat-icon class="cp" matSuffix (click)="showOldPassword  = !showOldPassword">{{showOldPassword ? 'visibility'
            : 'visibility_off'}}
          </mat-icon>
        </mat-form-field>
        <div class="msg-error" *ngIf="f.oldPassword.touched && f.oldPassword.errors?.required">
          Old password is required
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="start start" fxFlex="auto" fxLayoutGap="8px">
        <div class="title">New Password <span class="msg-error">*</span></div>
        <mat-form-field appearance="outline">
          <input type="{{showNewPassword  ? 'text' : 'password'}}" formControlName="newPassword"
            placeholder="Enter new password" matInput (focus)="openPolicyMenu()" (blur)="closePolicyMenu()"
            [matMenuTriggerFor]="passwordPolicy" #menuTrigger="matMenuTrigger" />
          <mat-icon class="cp" matSuffix (click)="showNewPassword  = !showNewPassword">{{showNewPassword ? 'visibility'
            : 'visibility_off'}}
          </mat-icon>
        </mat-form-field>

      </div>
      <div fxLayout="column" class="mt-10 ">
        <div class="msg-error" *ngIf="f.newPassword.touched && f.newPassword.errors?.required">
          New password is required
        </div>
        <mat-menu #passwordPolicy="matMenu" class="menu-option-notification menu-password-policy instruction"
          [hasBackdrop]="false">
          <app-password-policy [passwordRules]="f.newPassword"></app-password-policy>
        </mat-menu>

      </div>
      <div fxLayout="column" fxLayoutAlign="start start" fxFlex="auto" fxLayoutGap="8px">
        <div class="title">Confirm Password <span class="msg-error">*</span></div>
        <mat-form-field appearance="outline">
          <input type="{{showPasswordConfirm ? 'text' : 'password'}}" formControlName="confirmPassword"
            placeholder="Confirm new password" matInput>
          <mat-icon class="cp" matSuffix (click)="showPasswordConfirm = !showPasswordConfirm">{{showPasswordConfirm ?
            'visibility' : 'visibility_off'}}
          </mat-icon>
        </mat-form-field>
        <div class="msg-error" *ngIf="f.confirmPassword.touched && f.confirmPassword.errors?.required">
          Confirm password is required
        </div>
        <div class="msg-error" *ngIf="f.confirmPassword.touched && f.confirmPassword?.errors?.confirmedValidator">
          Passwords must match to new password
        </div>
      </div>
    </form>
    <div fxLayout="column" fxLayoutAlign="start start" fxFlex="auto" fxLayoutGap="8px" class="single-row">
      <div fxLayout="row" fxFlex="auto" fxLayoutAlign="end center" class="single-row" fxLayoutGap="8px">
        <a class="button fill" fxLayout="column" fxFlex="150px" fxLayoutAlign="center center"
          (click)="changeUserPassword()"> Update Password </a>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->
<!-- <ah-spinner *ngIf="requestInProgress" [config]="{fullscreen: true}"></ah-spinner> -->
