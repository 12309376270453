import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { CommonStorageService } from '../storage/common-storage.service';
import { ReferenceName as refName } from '../../../shared/enum/reference-name.enum';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(
    private permissionsService: NgxPermissionsService,
    private commonStorage: CommonStorageService,
    ) {}

  permissionNameConvertor(name) {
		return name
			.toLowerCase()
			.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
	}

  loadPermissions(permission: any[]): void {
    const permissions = permission.map((perm) => this.permissionNameConvertor(perm.code));
    // console.log(permissions, permission);
		this.permissionsService.loadPermissions(permissions);
    this.setLocalStorage(permissions);
	}

  addPermission(perms: string[], overwrite?: boolean): void {
    let permissions = perms.map((perm) => this.permissionNameConvertor(perm));
    if (!overwrite) {
      const existingPermission = Object.keys(this.getPermission() || {});
      let _permissions = [...existingPermission];
      for (let permission of permissions) {
        if (!existingPermission.includes(permission)) {
          _permissions.push(permission);
        }
      }
      permissions = _permissions;
    }
    this.permissionsService.addPermission(permissions);
	}

  getPermission(): any {
    if(Object.entries(this.permissionsService.getPermissions())?.length > 0){
      return this.permissionsService.getPermissions();
    } else {
      return this.commonStorage.get(refName.PERMISSIONS);
    }
	}

  setLocalStorage(permissions): any {
    return this.commonStorage.set(refName.PERMISSIONS, this.permissionsService.getPermissions());
  }


  removePermission(perm: string): void {
		this.permissionsService.removePermission(
			this.permissionNameConvertor(perm)
		);
	}

	clearPermission(): void {
		this.permissionsService.flushPermissions();
	}
}
