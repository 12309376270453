import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[ahEstimateTimeFormat]'
})
export class EstimateTimeFormatDirective {

  constructor(private control: NgControl) { }

  // @HostListener('blur', ['$event']) onBlur(event: KeyboardEvent) {
  //   const input = event.target as HTMLInputElement;
  //   if (this.containsTimeUnit(input.value)) {
  //     input.value = this.convertToDisplay(this.convertToMinutes(input.value.replace(/(\d+)\s*([a-z])/ig, '$1$2')
  //     ));
  //   } else {
  //     input.value = this.convertToDisplay(Number(input.value.replace(/(\d+)\s*([a-z])/ig, '$1$2').replace(/[^\d]/g, '')));
  //   }
  //   // console.log(this.convertToTime(input.value));
  // };

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string) {
    let newValue = '';
    if (!value) {
      return;
    }
    if (this.containsTimeUnit(value)) {
      newValue = this.convertToDisplay(this.convertToMinutes(value.replace(/(\d+)\s*([a-z])/ig, '$1$2')
      ));
    } else {
      newValue = this.convertToDisplay(Number(value.replace(/(\d+)\s*([a-z])/ig, '$1$2').replace(/[^\d]/g, '')));
    }
    this.control.control.setValue(newValue);
  }

  convertToTime(time: string): string {
    const minutesPerDay = 8 * 60;
    const timeUnits = {
      m: 1,
      h: 60,
      d: minutesPerDay,
      w: minutesPerDay * 7
    };
    let totalMinutes = 0;
    const tokens = time.split(' ');
    for (const token of tokens) {
      const unit = token[token.length - 1];
      const value = parseInt(token.slice(0, -1));
      if (timeUnits[unit]) {
        totalMinutes += timeUnits[unit] * value;
      }
    }
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const hoursString = hours.toString().padStart(2, '0');
    const minutesString = minutes.toString().padStart(2, '0');
    return `${hoursString}:${minutesString}`;
  }

  containsTimeUnit(input: string): boolean {
    const regex = /[mhdw]/;
    return regex.test(input);
  }

  convertToMinutes(time: string | number): number {
    const minutesPerDay = 8 * 60;
    const timeUnits = {
      m: 1,
      h: 60,
      d: minutesPerDay,
      w: minutesPerDay * 7
    };
    let totalMinutes = 0;
    if (typeof time === 'number') {
      totalMinutes = time;
    } else if (typeof time === 'string') {
      const tokens = time.split(' ');
      for (const token of tokens) {
        const unit = token[token.length - 1];
        const value = parseInt(token.slice(0, -1));
        if (timeUnits[unit]) {
          totalMinutes += timeUnits[unit] * value;
        }
      }
    }
    return totalMinutes;
  }

  convertToDisplay(minutes: number): string {
    const minutesPerDay = 8 * 60; // 8 hours as 1 day
    const minutesPerWeek = minutesPerDay * 7;

    // console.log(minutes,minutesPerDay);
    let formattedTime = '';
    if (minutes < 60) {
      formattedTime = `${minutes}m`;
    } else if (minutes < minutesPerDay) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      formattedTime = `${hours}h ${remainingMinutes}m`;
    } else if (minutes < ( minutesPerDay * 7)) {
      const days = Math.floor(minutes / minutesPerDay);
      const remainingMinutes = minutes % minutesPerDay;
      const hours = Math.floor(remainingMinutes / 60);
      console.log(days,minutes);
      formattedTime = `${days}d ${hours}h ${remainingMinutes % 60}m`;
      // } else if (minutes < 43829) {
      //   const weeks = Math.floor(minutes / 10080);
      //   formattedTime = `${weeks}w`;
      // } else {
      //   const months = Math.floor(minutes / 43829);
      //   formattedTime = `${months}M`;
      // }
    } else {
      const weeks = Math.floor(minutes / minutesPerWeek);
      const remainingMinutes = minutes % minutesPerWeek;
      const days = Math.floor(remainingMinutes / minutesPerDay);
      const remainingHoursMinutes = remainingMinutes % minutesPerDay;
      const hours = Math.floor(remainingHoursMinutes / 60);
      const remainingMinutesOnly = remainingHoursMinutes % 60;
      formattedTime = `${weeks}w ${days}d ${hours}h ${remainingMinutesOnly}m`;
    }
    return formattedTime.trim();
  }


}
