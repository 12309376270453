import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'ah-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {

  @Output() searchWord = new EventEmitter<any>();
  @Input() isBorder: boolean = false;
  @Input() showSearchIcon: boolean = false;
  searchForm: FormGroup;
  keyword = null;
  subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      keyword: [''],
    });
		const search = this.searchForm.get('keyword').valueChanges.pipe(debounceTime(1000),distinctUntilChanged()).subscribe((val) => {
			this.keyword = val?.trim()?.toLowerCase();
      if (val?.length >= 3) {
				this.searchWord.emit(this.keyword);
      }
      if (val?.length == 0) {
        this.keyword = null;
				this.searchWord.emit(this.keyword);
      }
    });
    this.setSubscription(search);
  }

  clearSerach() {
    this.keyword = null;
    this.searchForm.get('keyword').setValue('');
    this.searchWord.emit(this.keyword);
  }

  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
