import { Component, Input, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subscription } from 'rxjs';
import { BoardApiService } from 'src/app/core/services/api/board-api/board-api.service';
import { PlanningApiService } from 'src/app/core/services/api/planning-api/planning-api.service';
import { PlanningStateService } from 'src/app/core/services/data/planning-state/planning-state.service';
import { CommonUtilsService } from 'src/app/core/services/helper/common-utils.service';
import { PermissionService } from 'src/app/core/services/permission/permission.service';
import { AppToastrService } from 'src/app/core/services/toastr/app-toastr.service';

@Component({
  selector: 'ah-estimation',
  templateUrl: './estimation.component.html',
  styleUrls: ['./estimation.component.scss']
})
export class EstimationComponent implements OnInit {

  @ViewChild('userMenuTrigger') userMenu: MatMenuTrigger;
  @ViewChild('categoryMenuTrigger') categoryMenu: MatMenuTrigger;
  @ViewChild('scopeMenuTrigger') scopeMenu: MatMenuTrigger;
  @ViewChild('deleteDialog') deleteDialog: TemplateRef<any>;
  @Input() taskId: string;
  @Input() sprintInfo: any;
  @Input() viewFrom: any;
  @Input() taskInfo: any;
  subscriptions: Subscription[] = [];
  requestInProgress: boolean = false;
  scopeList = [];
  taskList = [];
  categoryList = [];
  userList = [];
  estimationForm: FormGroup;
  estimationList = [];
  originalEstimationList = [];
  showTick = null;
  cloneInfo: any = {};
  hasPermission: boolean = false;
  // taskId = "bb330dfe-64cf-42c4-8702-edd3a54efd31";
  noDataPlaceholder = {
    title: "No Estimation's to Show",
    description:"Looks like you haven't added any estimation",
    isShowButton: false,
    buttonText: "+ Add Estimation"
  }

  constructor(
    private boardApi: BoardApiService,
    private planApi: PlanningApiService,
    private toast: AppToastrService,
    private fb: FormBuilder,
    private commonUtils: CommonUtilsService,
    public planningState: PlanningStateService,
    public dialog: MatDialog,
    private permissionService: PermissionService,
  ) { }

  ngOnInit(): void {
    const moduleEntries = Object.entries(this.permissionService.getPermission());
    // console.log(moduleEntries);
    this.hasPermission = moduleEntries.some(([key, value]) => {
      return key === 'sprintTaskEstimationWrite';
    });
    this.estimationForm = this.fb.group({
      estimations: this.fb.array([]),
      // scope_id: [null, [Validators.required]],
      // category_id: [null],
      // user_id: [null, [Validators.required]],
      // estimation: [''],
      // actual: ['']
    });
    // this.getEstimation();
    this.getScopeList();
    this.getCategoryList();
    // this.getUserList();
    const taskEstimation = this.planningState.select('sprintList').subscribe(res => {
      // console.log(res);
      if (!res) {
        return;
      }
      this.processTaskUserList();
    });
    this.setSubscription(taskEstimation);
    this.processTaskUserList();
  }

  processTaskUserList() {
    // console.log(this.viewFrom);
    const sprints = this.planningState.selectSnapshot('sprintList');
    const sprintIndex = sprints.findIndex(x => x.sprint_id == this.sprintInfo?.sprint_id);
    if (sprintIndex > -1) {
      const taskInfo = sprints[sprintIndex].task_info.find(y => y.id == this.taskId);
      // console.log(taskInfo, sprints[sprintIndex], this.taskId);
      this.userList = taskInfo.task_members.map(user => { return user.user });
      // console.log(taskInfo, this.taskInfo);
      this.planningState.setTaskUpdateFlag(false);
    }

    if(this.viewFrom == 'DASHBOARD'){
      // console.log(this.taskInfo);
      this.userList = this.taskInfo.members.map(user => user);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes,changes.taskId.currentValue);
    if (changes.taskId.currentValue) {
      this.taskId = changes.taskId.currentValue;
      this.estimationForm = this.fb.group({
        estimations: this.fb.array([]),
      });
      this.getEstimation();
    }
  }

  clickEdit(index) {
    console.log(this.hasPermission);
    if(this.hasPermission){
      this.showTick = index;
    }
  }

  closeMenu(menuTrigger: MatMenuTrigger) {
    if (menuTrigger) {
      menuTrigger.closeMenu();
    }
  }

  patchEstimationForm() {
    const estimation = this.estimationList;
    if (estimation && estimation.length > 0) {
      this.estimationForm = this.fb.group({
        estimations: this.fb.array([]),
      });
      estimation.forEach(e => {
        const scope = e.scope;
        const category = e.category;
        const userInfo = e.user_info;
        let estimationExist = this.fb.group({
          scope_id: scope?.id || null,
          category_id: category?.id || null,
          user_id: userInfo?.id || null,
          estimation: (e.original_estimation_in_min) ? this.commonUtils.convertToDisplay(e.original_estimation_in_min) : null,
          actual: (e.actual_time_in_min) ? this.commonUtils.convertToHourAndMinutes(e.actual_time_in_min) : '00:00',
          id: e.id,
        });
        let index = this.estimationArray.controls.findIndex(x => (x.value.id === e.id));
        if (index > -1) {
          this.estimationArray.controls[index] = estimationExist;
        } else {
          this.estimationArray.push(estimationExist);
        }
        // this.estimationArray.push(estimationExist);
      });
    } else if (this.estimationArray.length == 0) {
      // this.showTick = 0;
      this.addMoreEstimation();
    }
  }

  get f() {
    return this.estimationForm.controls;
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  addEstimation() {
    return this.fb.group({
      scope_id: [null, [Validators.required]],
      category_id: [null],
      user_id: [null, [Validators.required]],
      estimation: ['', [Validators.required]],
      actual: ['00:00'],
    });
  }

  addMoreEstimation() {
    if (this.estimationForm.invalid) {
      this.estimationForm.markAllAsTouched();
      // console.log(this.estimationForm);
      return;
    }
    // console.log(this.estimationArray.length - 1);
    if (this.estimationArray.length - 1 > -1) {
      const index = this.estimationArray.length - 1;
      if (!this.estimationArray.controls[index].value.id) {
        this.saveEstimation(index);
      }
    }
    const newEstimation = this.addEstimation(); // Assuming this function returns the newly added item
    this.estimationArray.insert(0, newEstimation);
    // this.showTick = 0;
    // this.estimationArray.push(this.addEstimation());
  }

  get estimationArray() {
    return (this.estimationForm.get('estimations') as FormArray);
  }

  displayName(entityType, entityId) {
    if (!entityId) {
      return null;
    }
    let displayName = null;
    switch (entityType.toLowerCase()) {
      case 'category':
        const category = this.categoryList.find(x => x.id == entityId);
        displayName = category?.name;
        break;
      case 'scope':
        const scope = this.scopeList.find(x => x.id == entityId);
        displayName = scope?.name;
        break;
      case 'user':
        const user = this.userList.find(x => x.id == entityId);
        displayName = user;
        break;
    }
    return displayName
  }

  getEstimation() {
    this.requestInProgress = true;
    const listScope = this.planApi.estimationListByTaskId(this.taskId).subscribe(
      {
        next: (res) => {
          this.requestInProgress = false;
          this.estimationForm = this.fb.group({
            estimations: this.fb.array([]),
          });
          this.estimationList = res.data.estimations || [];
          if (this.estimationList.length > 0) {
            this.estimationList = this.estimationList.map(item => {
              return { ...item, ...{ 'original_estimation_in_min_new': this.commonUtils.convertToDisplay(item.original_estimation_in_min), 'actual_time_in_min_new': item.actual_time_in_min ? this.commonUtils.convertToDisplay(item.actual_time_in_min) : '' } }
            })
            this.originalEstimationList = this.estimationList.map(estimation => ({ ...estimation }));
          } else {
            this.originalEstimationList = [];
          }
          // this.patchEstimationForm();
          // console.log(res.data, this.estimationList);
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(listScope);
  }

  calculateEstimation(estimation, type) {
    if (estimation?.length > 0) {
      const calulationType = type == 'original' ? 'original_estimation_in_min' : 'actual_time_in_min';
      const sum = estimation.reduce((acc, item) => acc + Number(item[calulationType]), 0);
      return this.commonUtils.convertToDisplay(sum);
    } else {
      return '00:00';
    }
  }

  getScopeList() {
    let payload = {
      "filters": {
        "entity_type": "TASK",
        "show_all": true
      }
    }
    const listScope = this.boardApi.listScope(payload).subscribe(
      {
        next: (res) => {
          this.requestInProgress = false;
          this.scopeList = res.data.scopes;
          // console.log(this.scopeList);
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(listScope);
  }

  getCategoryList() {
    let payload = {
      "filters": {
        "show_all": true
      }
    }
    const categoryList = this.boardApi.listCategory(payload).subscribe(
      {
        next: (res) => {
          // console.log(res);
          this.requestInProgress = false;
          this.categoryList = res.data.categories;
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(categoryList);
  }

  getUserList() {
    let payload = {
      "filters": {
        "show_all": true
      }
    }
    const listUser = this.boardApi.listUser(payload).subscribe(
      {
        next: (res) => {
          // console.log(res);
          this.requestInProgress = false;
          this.userList = res.data.users;
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(listUser);
  }

  getTaskList() {
    let payload = {
      "filters": {
        "entity_type": "TASK",
        "show_all": true
      }
    }
    const projectList = this.boardApi.projectList(payload).subscribe(
      {
        next: (res) => {
          // console.log(res);
          this.requestInProgress = false;
          this.taskList = res.data.project;
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(projectList);
  }

  updateEstimation(entityIndex) {
    // console.log(this.estimationList[entityIndex]);
    const estimation = this.estimationList[entityIndex];
    let payload = {
      "task_estimation": [
        {
          "user_id": estimation.user_info.id,
          "task_id": this.taskId,
          "estimation": this.convertToMinutes(this.commonUtils.convertToTime(estimation.original_estimation_in_min_new)),
          "scope_id": estimation.scope?.id,
          // "category_id": estimation.category?.id
        }
      ]
    }
    if (estimation.id) {
      payload['task_estimation'][0]['id'] = estimation.id;
    }

    // console.log(payload);
    const estimationSave = this.planApi.createEstimation(payload).subscribe(
      {
        next: (res) => {
          // console.log(res);
          this.requestInProgress = false;
          this.showTick = null;
          this.planningState.updateTaskEstimation(this.taskId, res.data[0]);
          this.toast.success('Estimation updated successfully.');
          this.getEstimation();
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(estimationSave);
  }

  saveEstimation(entityIndex) {
    // console.log(this.estimationArray.controls[entityIndex]);
    // if (this.estimationForm.invalid) {
    //   this.estimationForm.markAllAsTouched();
    //   // console.log(this.estimationForm);
    //   return;
    // }
    const specificEstimation = this.estimationArray.controls[entityIndex];

    // console.log(specificEstimation.invalid);
    if (specificEstimation && specificEstimation.invalid) {
      specificEstimation.markAllAsTouched();
      return;
    }
    let payload = {
      "task_estimation": [
        {
          "user_id": this.estimationArray.controls[entityIndex].value.user_id,
          "task_id": this.taskId,
          "estimation": this.convertToMinutes(this.commonUtils.convertToTime(this.estimationArray.controls[entityIndex].value.estimation)),
          "scope_id": this.estimationArray.controls[entityIndex].value.scope_id,
          "category_id": this.estimationArray.controls[entityIndex].value.category_id
        }
      ]
    }
    if (this.estimationArray.controls[entityIndex].value.id) {
      payload['task_estimation'][0]['id'] = this.estimationArray.controls[entityIndex].value.id;
    }
    // console.log(payload, this.convertToMinutes(this.commonUtils.convertToTime(this.estimationArray.controls[entityIndex].value.estimation)));
    // return;
    const estimationSave = this.planApi.createEstimation(payload).subscribe(
      {
        next: (res) => {
          // console.log(res);
          this.requestInProgress = false;
          this.showTick = null;
          this.planningState.updateTaskEstimation(this.taskId, res.data[0]);
          if (this.estimationArray.controls[entityIndex].value.id) {
            this.toast.success('Estimation updated successfully.');
          } else {
            this.toast.success('Estimation added successfully.');
          }
          this.getEstimation();
        },
        error: (error) => {
          this.requestInProgress = false;
          this.toast.error(error.message)
        },
      });
    this.setSubscription(estimationSave);
  }

  convertToMinutes(timeString: string) {
    const [hours, minutes] = timeString.split(':').map(Number);
    return ((hours * 60) + minutes);
  }

  deleteEstimation(entityIndex){
    const estimation = this.estimationList[entityIndex];
    const dialogRef = this.dialog.open(this.deleteDialog, {
      height: '220px',
      width: '450px',
      disableClose: true,
      data: {
        rowInfo: estimation
      }
    });
    const isClosed = dialogRef.afterClosed().subscribe((value) => {
      console.log(value);
    });
    this.setSubscription(isClosed);
  }

  closeDeleteConfirmation(rowInfo) {
    if (!rowInfo) {
      return;
    }
    let id = rowInfo.id;
    // console.log(rowInfo,id);
    // return;
    this.requestInProgress = true;
    const deleteEstimation = this.planApi.deleteEstimation(id).subscribe(
      {
        next: (res) => {
          this.requestInProgress = false;
          const index = this.estimationList.findIndex(x=>x.id == id);
          this.estimationList.splice(index, 1);
          this.originalEstimationList = this.estimationList.map(estimation => ({ ...estimation }));
          this.planningState.deleteTaskEstimation(this.taskId, id);
        },
        error: (error) => {
          this.requestInProgress = false;
        },
      }
    )
    this.setSubscription(deleteEstimation);
  }

  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
