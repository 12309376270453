<div class="estimation" fxLayout="row">
  <!-- fxLayoutGap="26px" -->
  <div class="content" fxLayout="column" fxLayoutAlign="start start">
    <ng-template [ngxPermissionsOnly]="['sprintTaskEstimationWrite']">
      <div fxLayout="row" class="add-row">
        <button class="add-new cp" (click)="addMoreEstimation()">+ Add New</button>
      </div>
    </ng-template>
    <div class="panel-body-bar border-bottom pb-5">
      <form [formGroup]="estimationForm" fxFlex="auto" fxLayout="column" fxLayoutGap="15px">
        <div formArrayName="estimations">
          <div fxLayout="row" class="input-field"  fxLayoutGap="16px"
            *ngFor="let estimation of estimationArray?.controls; let eId = index;" [formGroupName]="eId">
              <div fxLayout="column" fxFlex="auto" fxLayoutGap="8px">
                <!-- <div class="title">Assignee</div> -->
                <mat-menu #menu="matMenu" class="select-popup">
                  <div (click)="$event.stopPropagation()">
                    <ng-select [items]="userList" [isOpen]="true" bindLabel="name" bindValue="id"
                      formControlName="user_id" placeholder="Select Assignee" [closeOnSelect]="true"
                      (change)="closeMenu(userMenuTrigger)"></ng-select>
                  </div>
                </mat-menu>
                <div fxLayout="row" (click)="clickEdit(eId)" fxFlex="auto" fxLayoutGap="-10px" class="user-assignee" [matMenuTriggerFor]="menu" #userMenuTrigger="matMenuTrigger">
                  <ng-container *ngIf="!displayName('user', estimationArray?.controls[eId]['controls'].user_id.value); else showUser">
                    <!-- <div class="user-assignee-circle" [ngClass]="{'error-user': (estimationArray?.controls[eId]['controls'].user_id.touched && estimationArray?.controls[eId]['controls'].user_id.errors?.required)}" fxLayout="row" fxLayoutAlign="center center">
                      +
                    </div> -->
                    <div class="user-assignee-circle" [ngClass]="{'error-user': (estimationArray?.controls[eId]['controls'].user_id.touched && estimationArray?.controls[eId]['controls'].user_id.errors?.required)}" fxLayout="row" fxLayoutAlign="center center">
                      <img src="../../../../assets/image/svg/estimation-user.svg"/>
                    </div>
                  </ng-container>
                  <ng-template #showUser>
                    <ah-user [userInfo]="displayName('user', estimationArray?.controls[eId]['controls'].user_id.value)" class="w-32 h-32" [showCount]="false"></ah-user>
                  </ng-template>
                </div>
                <!-- <input placeholder="Select" [matMenuTriggerFor]="menu" [value]="displayName('user', estimationArray?.controls[eId]['controls'].user_id.value)"
                  #userMenuTrigger="matMenuTrigger" readOnly /> -->
              </div>
              <div fxLayout="column" fxFlex="auto" fxLayoutGap="8px">
                <!-- <div class="title">Scope</div> -->
                <mat-menu #menu2="matMenu" class="select-popup">
                  <div (click)="$event.stopPropagation()">
                    <ng-select [items]="scopeList" [isOpen]="true" bindLabel="name" bindValue="id"
                      formControlName="scope_id" placeholder="Select Scope"
                      (change)="closeMenu(scopeMenuTrigger)"></ng-select>
                  </div>
                </mat-menu>
                <div [ngClass]="{ 'error': (estimationArray?.controls[eId]['controls'].scope_id.touched && estimationArray?.controls[eId]['controls'].scope_id.errors?.required)}">
                  <input class="scope" (click)="clickEdit(eId)" placeholder="Select" [matMenuTriggerFor]="menu2" #scopeMenuTrigger="matMenuTrigger"
                  [value]="displayName('scope', estimationArray?.controls[eId]['controls'].scope_id.value)" readOnly />
                </div>
              </div>
              <!-- <div fxLayout="column" fxFlex="auto" fxLayoutGap="8px">
                <div class="title">Category</div>
                <mat-menu #menu1="matMenu" class="select-popup">
                  <div (click)="$event.stopPropagation()">
                    <ng-select [items]="categoryList" [isOpen]="true" bindLabel="name" bindValue="id"
                      formControlName="category_id" placeholder="Select Assignee"
                      (change)="closeMenu(categoryMenuTrigger)"></ng-select>
                  </div>
                </mat-menu>
                <input placeholder="Select" [matMenuTriggerFor]="menu1" #categoryMenuTrigger="matMenuTrigger"
                  [value]="displayName('category',estimationArray?.controls[eId]['controls'].category_id.value)" readOnly />
              </div> -->
              <div [ngClass]="{'error': (estimationArray?.controls[eId]['controls'].estimation.touched && estimationArray?.controls[eId]['controls'].estimation.errors?.required)}" fxLayout="column" fxFlex="auto" fxLayoutGap="8px">
                <!-- <div class="title">Estimation</div> -->
                <input placeholder="Select" (click)="clickEdit(eId)" placeholder="01h 00m" formControlName="estimation" ahEstimateTimeFormat>
              </div>
              <!-- <div [ngClass]="{'readonly' : showTick != eId, 'error': (estimationArray?.controls[eId]['controls'].actual.touched && estimationArray?.controls[eId]['controls'].actual.errors?.required)}" fxLayout="column" fxFlex="auto" fxLayoutGap="8px">
                <div class="title">Actual</div>
                <input class="actual" (click)="clickEdit(eId)" placeholder="Select" formControlName="actual" readonly>
              </div> -->
              <div fxLayout="column" fxFlex="auto" fxLayoutGap="8px" fxLayoutAlign="center center"  class="action">
                <img class="payment-img cp" src="../../../../assets/image/svg/checkmark.svg" alt="image"  (click)="saveEstimation(eId)"/>
              </div>
            <!-- </div> -->
          </div>
        </div>
      </form>
    </div>
    <div class="panel-body-bar estimation-list" *ngIf="estimationList.length > 0">
      <div fxLayout="row" class="input-field border-bottom" fxLayoutAlign="space-between start">
        <div fxLayout="column" fxFlex="45px" class="mr-16 mb-10" fxLayoutGap="8px">
          <div class="title">Assignee</div>
        </div>
        <div fxLayout="column" fxFlex="155px" class="mr-16 mb-10" fxLayoutGap="8px">
          <div class="title">Scope</div>
        </div>
        <div fxLayout="column" fxFlex="50px" class="mr-16 mb-10" fxLayoutGap="10px">
          <div class="title">Estimation</div>
        </div>
        <div fxLayout="column" fxFlex="50px" class="mr-16 mb-10" fxLayoutGap="8px">
          <div class="title">Actual</div>
        </div>
        <ng-template [ngxPermissionsOnly]="['sprintTaskEstimationWrite']">
          <div fxLayout="column" fxFlex="40px" class="mr-16" fxLayoutGap="8px">
            <div class="title">Action</div>
          </div>
        </ng-template>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between start" class="input-field border-bottom pb-10"   fxLayoutGap="16px" *ngFor="let estimation of estimationList; let eId = index;">
        <div fxLayout="column" fxFlex="45px" fxLayoutGap="8px">
          <mat-menu #menu="matMenu" class="select-popup">
            <div (click)="$event.stopPropagation()">
              <ng-select [items]="userList" [isOpen]="true" bindLabel="name" bindValue="id"
                [(ngModel)]="estimation.user_info.id" placeholder="Select Assignee" [closeOnSelect]="true"
                (change)="closeMenu(userMenuTrigger)"></ng-select>
            </div>
          </mat-menu>
          <div fxLayout="row" (click)="clickEdit(eId)" fxFlex="auto" fxLayoutGap="-10px" class="user-assignee" [matMenuTriggerFor]="hasPermission ? menu : null" #userMenuTrigger="matMenuTrigger">
            <ng-container *ngIf="!displayName('user', estimation.user_info.id); else showUser">
              <div class="user-assignee-circle"  fxLayout="row" fxLayoutAlign="center center">
                +
              </div>
            </ng-container>
            <ng-template #showUser>
              <ah-user [userInfo]="displayName('user', estimation.user_info.id)" class="w-32 h-32" [showCount]="false"></ah-user>
            </ng-template>
          </div>
        </div>
        <div fxLayout="column" fxFlex="155px" fxLayoutGap="8px">
          <mat-menu #menu2="matMenu" class="select-popup">
            <div (click)="$event.stopPropagation()">
              <ng-select [items]="scopeList" [isOpen]="true" bindLabel="name" bindValue="id"
              [(ngModel)]="estimation.scope.id" placeholder="Select Scope"
                (change)="closeMenu(scopeMenuTrigger)"></ng-select>
            </div>
          </mat-menu>
          <div [ngClass]="{'readonly' : showTick != eId}">
            <input class="scope list" (click)="clickEdit(eId)" placeholder="Select" [matMenuTriggerFor]="hasPermission ? menu2 : null" #scopeMenuTrigger="matMenuTrigger"
            [value]="displayName('scope', estimation.scope.id)" readOnly />
          </div>
        </div>
        <div fxLayout="column" fxFlex="50px" fxLayoutGap="10px" [ngClass]="{'readonly' : showTick != eId}">
          <input placeholder="Select" (click)="clickEdit(eId)" placeholder="01h 00m" [(ngModel)]="estimation.original_estimation_in_min_new" ahEstimateTimeFormat [readonly]="showTick != eId">
        </div>
        <div fxLayout="column" fxFlex="50px" fxLayoutGap="8px" [ngClass]="{'readonly' : showTick != eId}">
          <input placeholder="Select" class="no-border" (click)="clickEdit(eId)" placeholder="01h 00m" [(ngModel)]="estimation.actual_time_in_min_new" ahEstimateTimeFormat readonly>
        </div>
        <div fLayout="column" fxFlex="40px" fxLayoutGap="8px" fxLayoutAlign="start center"  class="action list">
          <ng-template [ngxPermissionsOnly]="['sprintTaskEstimationWrite']">
            <ng-container *ngIf="showTick !== eId; else editAction">
              <div fLayout="row" fxLayoutGap="8px">
                <img class="payment-img cp" src="../../../../assets/image/svg/pencil.svg" alt="Edit" (click)="clickEdit(eId)">
                <img class="dlt-img cp" src="../../../../assets/image/svg/delete.svg" alt="image" (click)="deleteEstimation(eId)" />
              </div>
            </ng-container>
            <ng-template #editAction>
              <img class="payment-img cp" src="../../../../assets/image/svg/checkmark.svg" alt="Save" (click)="updateEstimation(eId)">
            </ng-template>
          </ng-template>
            <!-- <img class="payment-img cp" src="../../../../assets/image/svg/checkmark.svg" alt="image" *ngIf="showTick == eId" (click)="saveEstimation(eId)"/>
            <img class="payment-img cp" src="../../../../assets/image/svg/pencil.svg" alt="image" *ngIf="showTick != eId" (click)="clickEdit(eId)"/> -->
        </div>
      </div>
    </div>
    <div fxLayout="row" class="add-row" fxLayoutAlign="end" *ngIf="originalEstimationList.length > 0">
      <!-- <div class="custom" fxLayout="column" fxFlex="50" fxLayoutAlign="start start" (click)="addMoreEstimation()">+ Add</div> -->
      <!-- <div class="custom" fxLayout="row" fxLayoutAlign="start start">+ Custom</div> -->
      <div class="total" fxLayout="column" fxFlex="50" fxLayoutAlign="end center">
        Total: {{calculateEstimation(originalEstimationList, 'original')}}
      </div>
    </div>
    <!-- <div class="total" fxLayout="row" fxLayoutAlign="end start">
      Actual Total: {{calculateEstimation(estimationList, 'actual')}}
    </div> -->
    <div fxLayout="row" fxLayoutAlign="start center" class="footer mt-1" *ngIf="estimationArray?.controls.length == 0 && estimationList.length == 0">
      <ah-no-data-view fxFlexFill [config]="noDataPlaceholder"></ah-no-data-view>
    </div>
  </div>
</div>

<ah-spinner *ngIf="requestInProgress" [config]="{fullscreen: false}"></ah-spinner>
<ng-template #deleteDialog let-data>
  <div class="timesheet" fxLayout="row" fxLayoutAlign="center center">
      <div class="timesheet-app">
          <mat-dialog-content>
              <div fxLayout="column" fxLayoutAlign="start start" class="text-center mt-1">
                  <p class="mt-2">
                      Are you sure you want to delete?
                  </p>
              </div>
          </mat-dialog-content>
          <mat-dialog-actions align="center">
              <div class="mt-3">
                  <button class="timesheet-btn solid blue-bg btn-color button create" matDialogClose (click)="closeDeleteConfirmation(data.rowInfo)">
          Yes
        </button> &nbsp;&nbsp;
                  <button class="timesheet-btn outline btn-color button create" matDialogClose (click)="closeDeleteConfirmation(null)">
          No
        </button>
              </div>
          </mat-dialog-actions>
      </div>
  </div>
</ng-template>
