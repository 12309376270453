import { BoardStateService } from 'src/app/core/services/data/board-state/board-state.service';
import { CommonStorageService } from './../storage/common-storage.service';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, switchMap } from 'rxjs';
import { TimerService } from '../timer-service/timer.service';
import { MatDialog } from '@angular/material/dialog';
import { TimelogCreatePopupComponent } from 'src/app/shared/components/timelog-create-popup/timelog-create-popup.component';
import { ReferenceName, ReferenceName as refName } from '../../../shared/enum/reference-name.enum';
import { BoardApiService } from '../api/board-api/board-api.service';
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { CommonApiService } from '../api/common-api/common-api.service';
import { dataUrlToFile } from 'src/app/plugins/utils/dataUrlToFile';
import { HttpEventType } from '@angular/common/http';
import moment from 'moment';
import { DOCUMENT } from '@angular/common';
import dayjs, { Dayjs } from 'dayjs/esm';
import { AuthService } from '../auth/auth.service';
import { CommonDataService } from '../data/common-data/common-data.service';
import { updateParams } from 'src/app/shared/interface/ClientView';

interface FileData {
  file_ref_id: string;
  file_type: string;
  file_format: string;
  file_data: File | string;
  file_name?: string;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class CommonUtilsService {

  timer$: Observable<any> = this.timerService.data$;

  private PREFIX_STR: string = 'appyhours';

  EmailIds = [
    "yogeswaaran@appyhub.com",
    "marimuthu@appyhub.com"
  ]

  constructor(
    public dialog: MatDialog,
    public timerService: TimerService,
    // private datepipe: DatePipe,
    private storage: CommonStorageService,
    private boardState: BoardStateService,
    private boardApi: BoardApiService,
    private commonAPI: CommonApiService,
    private auth: AuthService,
    @Inject(DOCUMENT) private document: Document,
    private commonState: CommonDataService
  ) { }

  processTimeLogData(timerSocketInfo) {
    const { timesheets, timelog_id, action_type } = timerSocketInfo;
    const detailsId = timelog_id || timesheets?.details_id;
    const { start_date } = timesheets || {};
    this.commonState.setTimeLogAction(action_type);
    this.commonState.setTimeLogId(detailsId);
  
    const immediateActions = ["discard", "delete", "start_timer", "save", "stop_timer"];
    // Handle update action type
    if (action_type === "update" && start_date) {
      this.commonState.addTimeLogDate(start_date);
      setTimeout(() => {
        this.commonState.addTimeLogDate(null);
      }, 1000);
    } else if (immediateActions.includes(action_type)) {
      this.commonState.addTimeLog(true);
    }
    this.processTimeLog({...timesheets, ...{timelog_id}});
  }
  
  processTimeLog(currentTask) {
    const currentTimer = this.getCurrentActiveTimer();
    if (currentTask && Object.keys(currentTask).length > 0 && !currentTask.end_time && currentTask?.details_id) {
      this.boardState.clearTimerLog();
      this.startTimer(currentTask);
    } else if(currentTask?.details_id ==  currentTimer?.details_id || currentTask?.timelog_id ==  currentTimer?.details_id) {
      this.boardState.clearTimerLog();
    }
  }


  changeDateFormat(inputDate, format = 'YYYY-MM-DD', isConvert = false) {
    if (format == 'DD-MM-YYYY') {
      return moment(new Date(inputDate)).format('DD-MM-YYYY');
    }
    return moment(inputDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
  }

  createTimeLog(item) {
    // console.log(item);
    if (!this.getCurrentActiveTimer()) {
      let userInfo = this.auth.getUserInfo();
      if (this.EmailIds.includes(userInfo.email)) {
        this.openCustomTimeLogDialog(item);
      } else {
        this.saveTimeLog(item);
      }
    } else {
      if (!this.hasStorageTrack(ReferenceName.IsLogConfirmationDialogShow, true)) {
        this.openCurrentLogClosePopup().subscribe((res) => {
          if (res) {
            this.openTimeLogDialog(this.getCurrentActiveTimer(), item);
          }
        });
      } else {
        this.openTimeLogDialog(this.getCurrentActiveTimer(), item);
      }
    }
  }

  openTimeLogDialog(currentTask, newTask) {
    let newPayload = {
      'title': newTask?.title,
      'task_id': newTask?.id,
      'entity_id': newTask?.entity_info?.id,
      'entity_type': newTask?.entity_type,
      // 'description': newTask?.description,
      'category': {
        'id': newTask?.category?.id
      },
      // 'start_date': moment(new Date()).format('YYYY-MM-DD'),
      // 'start_time': moment(new Date()).format('HH:mm'),
      'current_start_time': true
    }
    currentTask.current_end_time = true;
    currentTask.end_date = moment(new Date()).format('YYYY-MM-DD');
    currentTask.end_time = moment(new Date()).format('HH:mm');
    currentTask.end_time_sec = moment(new Date()).format('HH:mm:ss');
    const dialogRef = this.dialog.open(TimelogCreatePopupComponent, {
      panelClass: 'ah-add-task',
      maxWidth: '500px',
      maxHeight: '700px',
      width: '100%',
      disableClose: true,
      autoFocus: false,
      data: {
        payload: currentTask,
        newTask: newPayload,
      }
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value) {
        this.boardState.clearTimerLog();
        this.getCurrentActiveTask();
      }
    })
  }

  openCustomTimeLogDialog(item) {
    let payload = {
      'title': item?.title,
      'task_id': item?.id,
      'entity_id': item?.entity_info?.id,
      'entity_type': item?.entity_type,
      // 'description': item?.description,
      'category': {
        'id': item?.category?.id
      },
      'start_date': moment(new Date()).format('YYYY-MM-DD'),
      'start_time': moment(new Date()).format('HH:mm'),
      'end_time': null,
      'end_time_sec': null,
      'label': null,
      'scope': null,
      "current_start_time": true
    }
    const dialogRef = this.dialog.open(TimelogCreatePopupComponent, {
      panelClass: 'ah-add-task',
      maxWidth: '500px',
      maxHeight: '700px',
      width: '100%',
      disableClose: true,
      autoFocus: false,
      data: {
        payload,
        newTask: null
      }
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value) {
        this.boardState.clearTimerLog();
        this.getCurrentActiveTask();
      }
    })
  }

  stopRunningTimer(item?) {
    const timeLogObs = this.stopTimer().subscribe((res) => {
      // console.log(res, item);
      // this.commonUtility.getCurrentActiveTask();
      if (res && !!item) {
        this.boardState.clearTimerLog();
        this.saveTimeLog(item);
      } else {
        this.getCurrentActiveTask();
        // this.boardState.clearTimerLog();
      }
    });
    // this.setSubscription(timeLogObs);
  }

  saveTimeLog(item) {
    // console.log(item, this.currentLoaderStatus['_value']);
    // if (this.currentLoaderStatus) {
    //   return;
    // }
    let payload = {
      "time_format": "SECONDS",
      'timesheets': [
        {
          'title': item?.title,
          'task_id': item?.id,
          'entity_id': item?.entity_info?.id,
          'entity_type': item?.entity_type,
          // 'description': item?.description,
          'category': {
            'id': item?.category?.id
          },
          // 'date': moment(new Date()).format('YYYY-MM-DD'),
          // 'start_time': moment(new Date()).format('HH:mm'),
          'end_time': null,
          'label': null,
          'scope': null,
          "current_start_time": 1
        }
      ]
    }
    this.commonState.setLoaderStatus(true);
    // console.log(payload);
    const createTimeLog = this.boardApi.createTimeLog(payload).subscribe(
      {
        next: (res) => {
          // console.log(res);
          this.commonState.setLoaderStatus(false);
          this.startTimer(res.data.current_task);
        },
        error: (error) => {
          this.commonState.setLoaderStatus(false);
          // this.toast.error(error.message)
        },
      });
    // this.setSubscription(createTimeLog);
  }

  getCurrentActiveTimer() {
    return this.storage.get(refName.TASK_TIMER);
  }

  setStorageTrack(type, value): void {
    const storageData = this.storage.get(type);
    let storageValue = storageData || [];
    storageValue = [...storageValue, ...[value]]
    this.storage.set(type, storageValue);
  }

  hasStorageTrack(type, value): boolean {
    const storageData = this.storage.get(type);
    if (!storageData) return false;
    return storageData.includes(value);
  }

  getCurrentActiveTask() {
    const projectList = this.boardApi.currentTask().subscribe(
      {
        next: (res) => {
          // console.log(res);
          let currentTask = res.data.current_task;
          if (Object.keys(currentTask).length > 0) {
            this.boardState.clearTimerLog();
            this.startTimer(currentTask);
          } else {
            this.boardState.clearTimerLog();
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  startTimer(payload) {
    // const timestamp = new Date(payload.date + ' ' + payload.start_time + ':00').getTime();
    const timestamp = new Date(payload.date + ' ' + payload.start_time_sec).getTime();
    payload['timestamp'] = timestamp;
    this.timerService.start(payload);
    this.boardState.setTimerLog(payload);
  }

  stopTimer(updateParam?:updateParams) {
    return new Observable((observer) => {
      const timeLogObj = this.timerService.getStopTimer();

      let totalTimeSpent = timeLogObj.endTimestamp - timeLogObj.startTimestamp;
      timeLogObj['total_time_spend'] = totalTimeSpent;
      // timeLogObj['end_time'] = this.datepipe.transform((new Date(timeLogObj.endTimestamp)), 'HH:mm');
      timeLogObj['end_time'] = moment(new Date(timeLogObj.endTimestamp)).format('HH:mm');
      timeLogObj['end_time_sec'] = moment(new Date(timeLogObj.endTimestamp)).format('HH:mm:ss');
      timeLogObj['end_date'] = moment(new Date()).format('YYYY-MM-DD');
      timeLogObj['total_duration'] = this.calculateDuration(totalTimeSpent);
      timeLogObj['isUpdate'] = false;

      if(updateParam){

        if(updateParam.entity_type ){
          timeLogObj.payload['entity_type'] = updateParam.entity_type
       }

       if(updateParam.entity_id ){
         if(timeLogObj.payload['entity_id'] !== updateParam.entity_id  ){
          timeLogObj.payload['entity_info'] = null
        }
        timeLogObj.payload['entity_id'] = updateParam.entity_id
       }

       if(updateParam.description ){
         timeLogObj.payload['description'] = updateParam.description
        }

        if(updateParam.category ){
          timeLogObj.payload['category'] = updateParam.category
        }
      }


      const dialogRef = this.dialog.open(TimelogCreatePopupComponent, {
        panelClass: 'ah-add-task',
        maxWidth: '500px',
        maxHeight: '700px',
        width: '100%',
        disableClose: true,
        autoFocus: false,
        data: timeLogObj
      });
      dialogRef.afterClosed().subscribe((value) => {
        // if(value){
        //   this.boardState.clearTimerLog();
        // }
        observer.next(value);
        observer.complete();
      })
    });
  }

  openCurrentLogClosePopup() {
    return new Observable((observer) => {
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        panelClass: 'ah-add-task',
        maxWidth: '450px',
        maxHeight: '300px',
        width: '100%',
        disableClose: true,
        autoFocus: false,
        data: {
          // message: "Your running time-log will be stopped & saved. Did you want to start new time log?",
          message: "Do you want to complete the running timelog and start a new one?",
          buttonText: {
            ok: 'Yes',
            cancel: 'No'
          }

        }
      });
      dialogRef.afterClosed().subscribe((value) => {
        // if(value){
        //   this.boardState.clearTimerLog();
        // }
        observer.next(value);
        observer.complete();
      })
    });
  }

  convertToHourAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const hoursString = hours.toString().padStart(2, '0');
    const minutesString = minutes.toString().padStart(2, '0');
    return `${hoursString}:${minutesString}`;
  }

  calculateDuration(timeDiffernce) {
    const diffInMinutes = Math.floor(timeDiffernce / (1000 * 60));
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;
    const timeString = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    return timeString;
  }

  convertToTime(time: string): string {
    const minutesPerDay = 8 * 60;
    const timeUnits = {
      m: 1,
      h: 60,
      d: minutesPerDay,
      w: minutesPerDay * 7
    };
    let totalMinutes = 0;
    const tokens = time.split(' ');
    for (const token of tokens) {
      const unit = token[token.length - 1];
      const value = parseInt(token.slice(0, -1));
      if (timeUnits[unit]) {
        totalMinutes += timeUnits[unit] * value;
      }
    }
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const hoursString = hours.toString().padStart(2, '0');
    const minutesString = minutes.toString().padStart(2, '0');
    return `${hoursString}:${minutesString}`;
  }

  convertToMinutes(timeString: string) {
    const [hours, minutes] = timeString.split(':').map(Number);
    return this.convertToDisplay((hours * 60) + minutes);
  }

  convertToMinutesFormat(time: string | number): number {
    const minutesPerDay = 8 * 60;
    const timeUnits = {
      m: 1,
      h: 60,
      d: minutesPerDay,
      w: minutesPerDay * 7
    };
    let totalMinutes = 0;
    if (typeof time === 'number') {
      totalMinutes = time;
    } else if (typeof time === 'string') {
      const tokens = time.split(' ');
      for (const token of tokens) {
        const unit = token[token.length - 1];
        const value = parseInt(token.slice(0, -1));
        if (timeUnits[unit]) {
          totalMinutes += timeUnits[unit] * value;
        }
      }
    }
    return totalMinutes;
  }

  convertToDisplay(minutes: number): string {
    const minutesPerDay = 8 * 60;
    const minutesPerWeek = minutesPerDay * 7;

    let formattedTime = '';
    if (minutes < 60) {
      formattedTime = `${minutes}m`;
    } else if (minutes < minutesPerDay) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      formattedTime = `${hours}h ${remainingMinutes}m`;
    } else if (minutes < minutesPerDay * 7) {
      const days = Math.floor(minutes / minutesPerDay);
      const remainingMinutes = minutes % minutesPerDay;
      const hours = Math.floor(remainingMinutes / 60);
      formattedTime = `${days}d ${hours}h ${remainingMinutes % 60}m`;
      // } else if (minutes < 43829) {
      //   const weeks = Math.floor(minutes / 10080);
      //   formattedTime = `${weeks}w`;
      // } else {
      //   const months = Math.floor(minutes / 43829);
      //   formattedTime = `${months}M`;
      // }
    } else {
      const weeks = Math.floor(minutes / minutesPerWeek);
      const remainingMinutes = minutes % minutesPerWeek;
      const days = Math.floor(remainingMinutes / minutesPerDay);
      const remainingHoursMinutes = remainingMinutes % minutesPerDay;
      const hours = Math.floor(remainingHoursMinutes / 60);
      const remainingMinutesOnly = remainingHoursMinutes % 60;
      formattedTime = `${weeks}w ${days}d ${hours}h ${remainingMinutesOnly}m`;
    }
    return formattedTime.trim();
  }

  /**
   *
   * @param startDate
   * @param endDate
   */

 public calculateLabel(startDate, endDate, showMonthView = false) {

    let ranges = [
      { label: 'Today', range: [dayjs().startOf('day'), dayjs().endOf('day')] },
      { label: 'Yesterday', range: [dayjs().subtract(1, 'day').startOf('day'), dayjs().subtract(1, 'day').endOf('day')] },
      { label: 'Last 7 Days', range: [dayjs().subtract(6, 'day').startOf('day'), dayjs().endOf('day')] },
      { label: 'This Week', range: [dayjs().startOf('week'), dayjs().endOf('week')] },
      { label: 'Last 30 Days', range: [dayjs().subtract(29, 'day').startOf('day'), dayjs().endOf('day')] },
      { label: [this.getMonthYearKey(2)], range: [dayjs().subtract(2, 'months').startOf('month'), dayjs().subtract(2, 'months').endOf('month')] },
      // { label: 'Q1', range: [dayjs().startOf('year'), dayjs().startOf('year').add(2, 'months').endOf('month')] },
      // { label: 'Q2', range: [dayjs().startOf('year').add(3, 'months'), dayjs().startOf('year').add(5, 'months').endOf('month')] },
      // { label: 'Q3', range: [dayjs().startOf('year').add(6, 'months'), dayjs().startOf('year').add(8, 'months').endOf('month')] },
      // { label: 'Q4', range: [dayjs().startOf('year').add(9, 'months'), dayjs().startOf('year').add(11, 'months').endOf('month')] },
    ];
    if (!showMonthView) {
      ranges.push(
        { label: 'This Month', range: [dayjs().startOf('month'), dayjs().endOf('month')] },
        { label: 'Last Month', range: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')] }
      );
    } else {
      ranges.push(
        { label: [this.getMonthYearKey()], range: [dayjs().startOf('month'), dayjs().endOf('month')] },
        { label: [this.getMonthYearKey(1)], range: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')] },
      );
    }

    const quatarRanges = this.generateQuarterRanges();

    const finalQuatatRanges = Object.keys(quatarRanges).map(key => ({
      label: key,
      range: quatarRanges[key]
    }));

    ranges = [...ranges, ...finalQuatatRanges];

    // const inputStartDate = startDate;
    // const inputEndDate = endDate;
    const inputStartDate = dayjs(startDate, 'DD-MM-YYYY');
    const inputEndDate = dayjs(endDate, 'DD-MM-YYYY');


    let matchedLabel: any;

    for (const { label, range } of ranges) {
      const [rangeStartDate, rangeEndDate] = range;

      if (inputStartDate.isSame(rangeStartDate, 'day') && inputEndDate.isSame(rangeEndDate, 'day')) {
        matchedLabel = label;
        break;
      }
    }

    if (matchedLabel) {
      return matchedLabel;
    } else {
      return `${startDate} to ${endDate}`;
    }

  }

  generateQuarterRanges() {
    const currentDate = dayjs();
    const currentYear = currentDate.year();
    const currentMonth = currentDate.month() + 1; // Note: dayjs months are 0-based

    // Calculate the current quarter based on the month
    let currentQuarter: number;
    if (currentMonth >= 1 && currentMonth <= 3) {
      currentQuarter = 1;
    } else if (currentMonth >= 4 && currentMonth <= 6) {
      currentQuarter = 2;
    } else if (currentMonth >= 7 && currentMonth <= 9) {
      currentQuarter = 3;
    } else {
      currentQuarter = 4;
    }

    const previousQuarter = currentQuarter === 1 ? 4 : currentQuarter - 1;
    const previousYear = currentQuarter === 1 ? currentYear - 1 : currentYear;

    return {
      [this.getQuarterKey(currentYear, currentQuarter)]: [
        dayjs(`${currentYear}-${(currentQuarter - 1) * 3 + 1}-01`).startOf('month'),
        dayjs(`${currentYear}-${currentQuarter * 3}-01`).endOf('month')
      ],
      [this.getQuarterKey(previousYear, previousQuarter)]: [
        dayjs(`${previousYear}-${(previousQuarter - 1) * 3 + 1}-01`).startOf('month'),
        dayjs(`${previousYear}-${previousQuarter * 3}-01`).endOf('month')
      ],
      [`${' '+currentYear}`]: [dayjs().startOf('year'), dayjs().endOf('year')],
    }
  }

  getQuarterKey(year: number, quarter: number): string {
    return `Q${quarter}-${year}`;
  }

  getMonthYearKey(monthOffset: number = 0): string {
    return dayjs().subtract(monthOffset, 'month').format('MMMM YYYY');
  }



  /** Asset upload logic*/

  generateFilename(custom_name?: string): string {
    const _date = new Date();
    return custom_name ? custom_name + "-" + Date.now() :
      (
        this.PREFIX_STR + "-" +
        _date.getDate() + "-" +
        _date.getMonth() + "-" +
        _date.getFullYear() + "-" +
        Date.now()
      );
  }

  downloadAsset(url: string, extension = 'jpeg', name: string = 'appyhours', getBlob: boolean = false): Observable<any> {
    const blobObserver = this.commonAPI.getBlob(url).pipe(
      switchMap((response: any) => this.readFile(response))
    )

    if (getBlob) return blobObserver;

    blobObserver.subscribe(blob => {

      if (/iPad|iPhone|iPod/.test(navigator.platform) && (!window as any).MSStream) {
        const _blob = dataUrlToFile(blob, null, true);
        const URL = window.URL || window.webkitURL;
        const blobUrl = URL.createObjectURL(_blob);
        window.open(blobUrl, '_blank')
      } else {
        const headlessAnchor = this.document.createElement('a');
        headlessAnchor.download = name.replace(' ', '_') + '_' + new Date().getTime() + '.' + extension;
        headlessAnchor.href = blob;
        headlessAnchor.click();
      }
    }, (err) => {
      console.error(err);
    });
  }

  private readFile(blob: Blob): Observable<string> {
    return Observable.create(obs => {
      const reader = new FileReader();

      reader.onerror = err => obs.error(err);
      reader.onabort = err => obs.error(err);
      reader.onload = () => obs.next(reader.result);
      reader.onloadend = () => obs.complete();

      return reader.readAsDataURL(blob);
    });
  }

  signedUpload(entity: string, data: FileData[], extra: object = {}): Observable<any> {

    /**
     * ======================================================
     *                     Request
     * ======================================================
     *
     * entity: String - upload entity type
     * data: file upload data
     *  - file_type: string - "FILE" or "BASE64"
     *  - file_format: string - "PNG", "JPG", "JPEG"
     *  - file_data: (string | <FileObject>)
     *    - RAW File object for file_type as FILE.
     *    - Base64 string with header for file_type as BASE64.
     *  - file_name: string (Optional)
     * extra: Object - extra meta to add in all object present in data
     */

    /**
     * ======================================================
     *                     Response
     * ======================================================
     *
     * (For reference) - "obsResponse" function.
     *
     * ref_id (string) - unique random string to tag request and response in observer
     *
     * -- If upload in progress --
     * ---------------------------
     * progress (Integer) - Upload progress value,
     *
     * -- If upload success but asset mapping not completed --
     * -------------------------------------------------------
     * progress (Integer) - 100,
     * is_uploaded (Boolean): true,
     * success (Boolean): false,
     * is_completed (Boolean): false,
     *
     * -- If upload success and asset mapping completed --
     * ---------------------------------------------------
     * is_uploaded (Boolean): true,
     * success (Boolean): true,
     * is_completed (Boolean): false,
     * data (Object / Array): <RESPONSE>,
     *
     *
     * -- If upload failed or upload is success but asset mapping failed --
     * ---------------------------------------------------
     * failed (Boolean): true,
     * error (Object): <RESPONSE>
     *
     *
     * -- If ALL upload request process done --
     * ----------------------------------------
     * is_completed (Boolean): true,
     *
     *
     */

    return new Observable((observer) => {
      let CREATE_SIGN_OBS: Subscription,
        MAP_UPLOAD_SIGN_OBS: Subscription[] = [],
        UPLOAD_SIGN_OBS: Subscription[] = [];

      // Destroy API subscription
      let unsub = () => {
        if (CREATE_SIGN_OBS) CREATE_SIGN_OBS.unsubscribe();
        MAP_UPLOAD_SIGN_OBS.forEach(sub => sub.unsubscribe());
        UPLOAD_SIGN_OBS.forEach(sub => sub.unsubscribe());
      }

      const localTracker = [];

      const popTracker = (id, data?) => {
        const index = localTracker.indexOf(id, 0);
        if (index > -1) localTracker.splice(index, 1);
        if (localTracker.length == 0) {
          observer.next(obsResponse({ is_completed: true, data }))
          observer.complete();
          unsub();
        }
      }

      // Observer response base struct
      let obsResponse = (input = {}) => {
        let base = {
          ref_id: null,
          is_completed: false,
          is_uploaded: false,
          failed: false,
          success: false,
          progress: null,
          data: {},
          error: null
        }

        return { ...base, ...input }
      }

      // S3 SignedURL upload
      let upload = (entity: string, data: FileData, urls, extra?): Observable<any> => {
        return new Observable((observer) => {
          try {
            // const uploadedData = [];

            for (let i = 0; i < data.length; i++) {
              const _data = data[i];

              localTracker.push(_data.file_ref_id);

              const { attributes: { action }, inputs, path } = urls[i];
              const file_name = ("file_name" in _data && !!_data["file_name"]) ?
                this.generateFilename(_data["file_name"]) :
                this.generateFilename();
              const key = path + "/" + file_name + "." + _data.file_format.toLowerCase();
              const headers = {}, objectHeader = {};

              let mediaType = {
                jpg: 'image/jpeg',
                jpeg: 'image/jpeg',
                png: 'image/png',
                gif: 'image/gif',
                bmp: 'image/bmp',
                mp4: 'video/mp4'
              }
              let fileFormat = _data.file_format.toLowerCase();

              if (fileFormat in mediaType) {
                objectHeader['type'] = mediaType[fileFormat]
              }

              let imageData = {
                ...inputs,
                file: _data.file_data,
                key
              };

              let input = new FormData();

              Object.keys(imageData).forEach(key => {
                input.append(key, imageData[key]);
              });


              // Overwrite the file form-data with buffer data when file type is base64

              if (_data.file_type.toUpperCase() == "BASE64") {
                // headers['Content-Type'] = 'multipart/form-data';
                // headers['Content-Encoding'] = 'base64';
                // const _headers = new HttpHeaders(headers)
                // const data = imageData.file.replace(/^data:image\/\w+;base64,/, '');
                // const buff = new Buffer(data, 'base64');

                let fileKeys = imageData["key"].split("/");
                let file = dataUrlToFile(imageData.file, fileKeys.slice(-1, fileKeys.length)[0]);

                input.set('file', file, fileKeys.slice(-1, fileKeys.length)[0]);
              }

              const signedAssetUploadObs = this.commonAPI.signedAssetUpload(action, input)
                .subscribe(res => {
                  if (res.type === HttpEventType.UploadProgress) {
                    observer.next(obsResponse({ ref_id: _data.file_ref_id, progress: Math.round(100 * res.loaded / res.total) }));
                  } else if (res.type === HttpEventType.Response) {
                    if (res.ok === true) {
                      let preData = {
                        file_path: key,
                        file_name,
                        entity
                      };
                      // uploadedData.push({ ...preData, ...extra});
                      observer.next(obsResponse({
                        ref_id: _data.file_ref_id, is_uploaded: true, data: [{ ...preData, ...extra }]
                      }));
                    } else {
                      observer.next(obsResponse({
                        ref_id: _data.file_ref_id, error: 'Upload failed'
                      }));
                      popTracker(_data.file_ref_id);
                    }

                    // hasObserverEnded(observer);
                  }
                }, (err) => {
                  observer.next(obsResponse({
                    ref_id: _data.file_ref_id, failed: true, error: err
                  }));
                  popTracker(_data.file_ref_id);
                });

              UPLOAD_SIGN_OBS.push(signedAssetUploadObs);
            }
          } catch (e) {
            observer.error(obsResponse({
              ref_id: null, failed: true, error: e.message
            }));
            observer.complete();
          }
        });
      }

      // S3 signedURL asset mapping
      let mapAsset = (asset_list, ref_id) => {
        return new Promise((resolve, reject) => {
          let payload = {
            assets: asset_list
          }
          const mapAssetObs = this.commonAPI.mapSignedAsset(payload)
            .subscribe(res => {
              resolve({ ref_id, res });
            }, (err) => {
              reject({ ref_id, err });
            });
          MAP_UPLOAD_SIGN_OBS.push(mapAssetObs);
        });
      }

      try {

        if (!entity) {
          throw new Error("entity is required.");
        }

        if (!Array.isArray(data) || data.length == 0) {
          throw new Error("data must be an array or data cannot be empty.");
        }

        if (
          data.findIndex(i => (
            i.file_ref_id == null ||
            i.file_ref_id == undefined ||
            !!(i.file_ref_id.toString().trim()) === false)
          ) > -1
        ) {
          throw new Error("file_ref_id is required.");
        }

        const COUNT = data.length;
        let payload;
        payload = data;

        // Create SignURL asset link
        CREATE_SIGN_OBS = this.commonAPI.createSignedAsset(COUNT, entity)
          .subscribe(async (res: any) => {
            const { data: { urls } } = res
            try {
              upload(entity, payload, urls, extra).subscribe(async (res) => {
                const { is_uploaded, failed, error, data, ref_id } = res;
                if (is_uploaded) {
                  observer.next(obsResponse({
                    ref_id, is_uploaded: true
                  }));

                  let mapResp: any = await mapAsset(data, ref_id);
                  observer.next(obsResponse({
                    ref_id, data: mapResp.res, is_uploaded: true, success: true
                  }));

                  popTracker(ref_id, mapResp.res);

                } else {
                  observer.next(res);
                }
              })
            } catch (e) {
              popTracker(e.ref_id);
              throw e.err || 'Unknown error';
            }
          }, (err) => {
            unsub();
            throw err;
          });

      } catch (e) {
        unsub();
        observer.error({ message: e.message, data: null });
        observer.complete();
        return
      }
    });
  }
}
