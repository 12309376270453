import { Component, Input, OnInit, SimpleChanges, EventEmitter, Output, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { PlanningApiService } from 'src/app/core/services/api/planning-api/planning-api.service';
import { AuthDataService } from 'src/app/core/services/data/auth-data/auth-data.service';
import { CommonUtilsService } from 'src/app/core/services/helper/common-utils.service';
import { TimelogCreatePopupComponent } from '../timelog-create-popup/timelog-create-popup.component';
import { BoardStateService } from 'src/app/core/services/data/board-state/board-state.service';
import { MatDialog } from '@angular/material/dialog';
import { TimelogApiService } from 'src/app/core/services/api/timelog-api/timelog-api.service';
import { CommonDataService } from 'src/app/core/services/data/common-data/common-data.service';

interface GroupedData {
  [date: string]: any[]; // Adjust this type based on your actual data structure
}

@Component({
  selector: 'ah-planning-time-log-view',
  templateUrl: './planning-time-log-view.component.html',
  styleUrls: ['./planning-time-log-view.component.scss']
})
export class PlanningTimeLogViewComponent implements OnInit, OnDestroy {

  @ViewChild('deleteDialog') deleteDialog: TemplateRef<any>;
  @Output() TotalDurationEmit = new EventEmitter();
  @Output() emitTimeLogOpen = new EventEmitter();
  subscriptions: Subscription[] = [];
  requestInProgress: boolean = false;
  @Input() taskId: string = null;
  @Input() viewFrom: any;
  pageNumbers: number;
  pageNumber: number = 1;
  limit = 25;
  totalReport = 0;
  totalDuration = '0';
  reportList = [];
  isLastPage: boolean = false;
  groupedData: GroupedData = {};
  objectKeys = Object.keys;
  @Input() timeLogOpen: boolean = false;
  timer$: Observable<any> = this.commonUtil.timer$;

  noDataPlaceholder = {
    title: "No Time Log's to Show",
    description: "Looks like you haven't added any time log for this date",
    isShowButton: false,
    buttonText: "+ Add Time Log"
  }
  timerState: any;

  constructor(
    private planningApi: PlanningApiService,
    private commonUtil: CommonUtilsService,
    private authState: AuthDataService,
    private boardState: BoardStateService,
    private dialog: MatDialog,
    private timeLogApiService: TimelogApiService,
    private commonState: CommonDataService
  ) { }

  ngOnInit(): void {
    this.getTimeLog();
    const timerState = this.boardState.select('currentTimerLog').subscribe(res => {
      this.timerState = res;
      this.resetTimeLog();
    });
    this.setSubscription(timerState);
    const newTimeLog = this.commonState.select('currentData').subscribe(res => {
      if (res) {
       this.resetTimeLog();
      }
    });
    this.setSubscription(newTimeLog);
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes,changes.taskId?.currentValue);
    if (changes.taskId && changes.taskId?.currentValue) {
      this.taskId = changes.taskId.currentValue;
      // this.isLastPage = false;
      // this.pageNumber = 1;
      // this.getTimeLog();
      this.resetTimeLog();
    }

    if(changes.timeLogOpen && changes.timeLogOpen?.currentValue){
      this.openDialog();
      this.emitTimeLogOpen.emit(false);
    }
  }

  getTimeLog() {
    if (!this.taskId || this.requestInProgress || this.isLastPage) {
      return;
    }
    let payload = {
      "filters": {
        "page_no": this.pageNumber,
        "limit": this.limit,
        "sort_type": "desc",
        "sort_options": [{ "sort_by": "start_date", "sort_type": "DESC" }],
        "tasks": [
          {
            "id": this.taskId
          }
        ]
      }
    }
    if (this.viewFrom == 'PLANNING') {
      payload['filters']["show_all_users"] = true;
      payload['filters']["show_only_completed"] = true;

    }
    if (this.viewFrom == 'DASHBOARD') {
      const userInfo = this.authState.getUserProfileInfo();
      payload['filters']['user_id'] = userInfo.id;
    }

    this.requestInProgress = true;
    const TimeSheetAPI = this.planningApi.timesheetList(payload).subscribe({
      next: (res) => {
        this.requestInProgress = false;
        let response = res.data;
        this.processTimesheetReport(response);
      },
      error: (error) => {
        this.requestInProgress = false;
        // console.error(error);
      }
    });
    this.setSubscription(TimeSheetAPI);
  }

  processTimesheetReport(res: any) {
    if (this.pageNumber == 1) {
      this.reportList = res.timesheets;
    } else {
      this.reportList = [...this.reportList, ...res.timesheets];
    }
    // console.log(res, this.reportList);
    // this.reportList = [...this.reportList, ...res.timesheets];
    // this.reportList = Array.from(new Set([...this.reportList, ...res.timesheets]));
    // for (const newItem of res.timesheets) {
    //   let isDuplicate = false;
    //   for (const existingItem of this.reportList) {
    //     if (existingItem.details_id === newItem.details_id) {
    //       isDuplicate = true;
    //       break;
    //     }
    //   }
    //   if (!isDuplicate) {
    //     this.reportList.push(newItem);
    //   }
    // }
    // console.log(this.reportList);
    const duration = this.reportList.filter(x => x.duration_min > 0).map(x => x.duration_min) || [];
    this.totalDuration = this.commonUtil.convertToHourAndMinutes(res.summary.total_duration_in_minutes);
    this.reportList.sort((a, b) => new Date(b.date + ' ' + b.start_time + ':00').getTime() - new Date(a.date + ' ' + a.start_time + ':00').getTime());
    this.totalReport = res.summary.total_count;
    this.isLastPage = res.summary.end_of_marker;
    this.pageNumber = this.pageNumber + 1;
    this.TotalDurationEmit.emit(this.totalDuration);
    this.groupDataByDate();
    if(this.reportList.length == 0){
      this.groupedData = {};
    }
  }

  // groupDataByDate() {
  //   this.reportList.forEach(item => {
  //     const date = item.date;
  //     if (!this.groupedData[date]) {
  //       this.groupedData[date] = [];
  //     }
  //     this.groupedData[date].push(item);
  //   });
  //   console.log(this.groupedData);
  // }

  groupDataByDate() {
    this.reportList.forEach(item => {
      const date = item.date;
      if (!this.groupedData[date]) {
        this.groupedData[date] = [];
      }

      // Check for duplicates before adding the item
      // const isDuplicate = this.groupedData[date].some(existingItem => existingItem.details_id === item.details_id);
      // if (!isDuplicate) {
      //   this.groupedData[date].push(item);
      // }
      const existingIndex = this.groupedData[date].findIndex(existingItem => existingItem.details_id === item.details_id);

      if (existingIndex === -1) {
        this.groupedData[date].push(item);
      } else {
        this.groupedData[date][existingIndex] = {
          ...this.groupedData[date][existingIndex],
          item,
        };
      }
    });
    // console.log(this.groupedData);
  }

  sortKeys(a: { key: string }, b: { key: string }): number {
    // return a.key.localeCompare(b.key);
    return b.key.localeCompare(a.key);;
  }

  editTimeLog(item) {
    if (item.details_id == this.timerState.details_id) {
      return;
    }
    // console.log(item);
    const dialogRef = this.dialog.open(TimelogCreatePopupComponent, {
      panelClass: 'ah-add-task',
      maxWidth: '500px',
      maxHeight: '700px',
      width: '100%',
      disableClose: true,
      autoFocus: false,
      data: {
        payload: item,
        isUpdate: true
      }
    });
    const isClosed = dialogRef.afterClosed().subscribe((value) => {
      if (value) {
        this.resetTimeLog();
      }
    });
    this.setSubscription(isClosed);
  }

  deleteTimeLog(item) {
    const dialogRef = this.dialog.open(this.deleteDialog, {
      height: '220px',
      width: '450px',
      disableClose: true,
      data: {
        taskInfo: item
      }
    });
    const isClosed = dialogRef.afterClosed().subscribe((value) => {
      if (value) {
        this.resetTimeLog();
      }
    });
    this.setSubscription(isClosed);
  }

  openDialog() {
    this.dialog.open(TimelogCreatePopupComponent, {
      panelClass: 'ah-add-task',
      maxWidth: '500px',
      maxHeight: '700px',
      width: '100%',
      disableClose: true,
      autoFocus: false,
      data: {
        task_id: this.taskId,
        // startDate: this.selectedStartDate,
        isTimelog: true
      }
    });
  }
  closeDeleteConfirmation(taskInfo) {
    if (!taskInfo) {
      return;
    }
    let id = taskInfo.details_id
    this.requestInProgress = true;
    const timeLogDelete = this.timeLogApiService.deleteTimeSheet(id).subscribe(
      {
        next: (res) => {
          this.requestInProgress = false;
          this.commonUtil.getCurrentActiveTask();
          this.resetTimeLog();
        },
        error: (error) => {

          this.requestInProgress = false;
        },
      }
    )
    this.setSubscription(timeLogDelete);
  }

  resetTimeLog() {
    this.groupedData = {};
    this.isLastPage = false;
    this.pageNumber = 1;
    this.getTimeLog();
  }

  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
