import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  private socket: WebSocket;
  private callbackFn: Function;
  private reconnectInterval: number = 10000; // 10 seconds
  private heartbeatInterval: number = 30000; // 30 seconds
  private url: string = null;
  private reconnectTimeout: any;
  private heartbeatTimeout: any;

  constructor() {
    const cluster = environment.PUSHER_CLUSTER;
    const key = environment.PUSHER_KEY;
    this.url = `wss://ws-${cluster}.pusher.com/app/${key}?protocol=7`;
  }

  connect(url?: string): void {
    if (url) {
      this.url = url;
    }
    this.socket = new WebSocket(this.url);
    this.socket.onopen = () => {
      this.startHeartbeat();
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    this.socket.onmessage = (message) => {
      this.resetHeartbeat();
      if (this.callbackFn) {
        const { event, data } = JSON.parse(message.data);
        if (!event.includes('pusher')) {
          this.callbackFn(JSON.parse(data));
        }
      }
    };

    this.socket.onclose = () => {
      this.clearHeartbeat();
      this.clearReconnectTimeout();
      this.reconnectTimeout = setTimeout(() => {
        this.connect(this.url);
      }, this.reconnectInterval);
    };
  }

  disconnect(): void {
    if (this.socket) {
      this.socket.close();
    }
    this.clearHeartbeat();
    this.clearReconnectTimeout();
  }

  private startHeartbeat(): void {
    this.heartbeatTimeout = setTimeout(() => {
      if (this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ event: 'pusher:ping' }));
        this.startHeartbeat();
      }
    }, this.heartbeatInterval);
  }

  private resetHeartbeat(): void {
    this.clearHeartbeat();
    this.startHeartbeat();
  }

  private clearHeartbeat(): void {
    if (this.heartbeatTimeout) {
      clearTimeout(this.heartbeatTimeout);
    }
  }

  private clearReconnectTimeout(): void {
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
    }
  }

  subscribe(channelName: string, eventName: string, callback: Function): void {
    const subscribeMessage = {
      event: 'pusher:subscribe',
      data: {
        channel: channelName
      }
    };

    const sendSubscribeMessage = () => {
      this.socket.send(JSON.stringify(subscribeMessage));
      this.callbackFn = callback;
    };

    if (this.socket.readyState === WebSocket.OPEN) {
      sendSubscribeMessage();
    } else {
      this.socket.onopen = () => {
        sendSubscribeMessage();
      };
    }
  }

  unsubscribe(channelName: string, eventName: string): void {
    const unsubscribeMessage = {
      event: 'pusher:unsubscribe',
      data: {
        channel: channelName
      }
    };
    if (this.socket.readyState !== WebSocket.CLOSED) {
      this.socket.send(JSON.stringify(unsubscribeMessage));
    }
  }
}
