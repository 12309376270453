import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstletter'
})
export class FirstletterPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }

    const words = value.split(' ');
    const firstLetters = words.slice(0, 1).map((word, index) => {
      if (index === 0 || word === '') {
        return word[0].toUpperCase();
      } else {
        return word[0].toUpperCase();
      }
    });
    // console.log(firstLetters);
    return firstLetters.join('');
  }

}
