import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute, PRIMARY_OUTLET } from '@angular/router';

@Component({
  selector: 'ah-router-modal',
  exportAs: 'ahModal',
  templateUrl: './router-modal.component.html',
  styleUrls: ['./router-modal.component.scss']
})
export class RouterModalComponent implements OnInit {

  @ViewChild('ahModal') template: TemplateRef<any>;
	@Input('ahOutlet') outlet: string = 'ah-router-modal';
	@Input('parentClass') parentClass?: string = null;
	@Input('hideClose') hideClose: Boolean = false;
	@Output() modalClose: EventEmitter<any> = new EventEmitter<any>();

	dialogRef;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {}

	ngAfterViewInit() {
		this.dialogRef = this.dialog.open(this.template, {
			height: '100vh',
			width: '100vw',
			maxWidth: '100vw',
			panelClass: 'ah-router-modal',
			backdropClass:this.parentClass
		});
		this.dialogRef.afterClosed().subscribe(() => {
			const urlWithoutAuxiliaryRoute = this.router
				.createUrlTree(['.'], { relativeTo: this.route })
				.root.children[PRIMARY_OUTLET].toString();

			this.router.navigate([urlWithoutAuxiliaryRoute], { replaceUrl: true });
		});
	}

	close(event?) {
		this.router.navigate([{ outlets: { outlet: null } }], {
			replaceUrl: true,
			relativeTo: this.route,
		});
		this.modalClose.next(event);
		this.dialogRef.close();
	}
}
