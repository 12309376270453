<div class="box" fxLayout="column">
  <div class="box-header" fxLayout="column" *ngIf="!isView">
    <div class="title">Attach files</div>
    <div class="format">Supported formats: DOC,PNG,JPEG,XLXS,CSV,PDF.</div>
  </div>
  <div class="box-header" fxLayout="column" *ngIf="isView">
    <div class="title">Attachements {{uploadedAttachments.length}}</div>
  </div>
  <div class="box-body" fxLayout="column">
    <div class="upload" fxLayout="column" fxLayoutAlign="center center" *ngIf="!showAttachmentProgress"
      (click)="browse()" ahDropAsset (dropped)="onDrop($event)">
      <img src="../../../../assets/image/svg/upload.svg" height="35px">
      <div fxLayout="row" class="upload-text" fxLayoutGap="6px">
        <a> Click to upload </a>
        <div class="drag">or drag and drop</div>
      </div>
    </div>
    <div class="files" fxLayout="column" fxLayoutGap="10px"
      *ngIf="uploadedAttachments.length > 0 && showAttachmentProgress">
      <div *ngFor="let file of  uploadedAttachments; let i=index;" fxLayout="column" fxLayoutGap="8px">
        <div class="files-sec" *ngIf="!isAlreadyupload(file)">
          <div fxLayout="row" fxLayoutAlign="space-between start">
            <div fxLayout="row" fxLayoutGap="8px">
              <div class="files-box" fxLayout="column" fxLayoutAlign="center center">
                <img [src]="'../../../../assets/image/svg/'+file.placeholderImage">
              </div>
              <div fxLayout="column">
                <div class="files-name">
                  <div *ngIf="renameId !== file.id" (click)="showRename($event,file.id)">
                    {{file.file_name | filenameEllipsis:20}}
                  </div>
                  <div *ngIf="renameId == file.id">
                    <input [(ngModel)]="file.file_name" (blur)="upload()" />
                  </div>
                </div>
                <div class="files-size">{{file.size}} MB</div>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="end start" (click)="deleteImage($event, file.attachment_id)">
              <img class="cp" src="../../../../assets/image/svg/cross.svg" height="6px">
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="file.completed != 100">
            <div class="progress">
              <mat-progress-bar mode="determinate" value="{{file.completed}}"></mat-progress-bar>
            </div>
            <!-- <progress id="file" value="{{file.progress}}" max="100"> 32% </progress> -->
            <div class="files-progress">{{file.completed}} %</div>
          </div>
        </div>
        <div class="report" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" *ngIf="isAlreadyupload(file)">
          <div fxLayout="column" fxFlex="10" fxLayoutAlign="start start">
            <img [src]="'../../../../assets/image/svg/' + file.placeholderImage" height="14px">
          </div>
          <div fxLayout="column" fxFlex="70" fxLayoutAlign="start start" (click)="openFileInNewTab(file.asset_info.full_url)">
            <div class="report-name">{{file.file_name | filenameEllipsis:25}}</div>
          </div>
          <div fxLayout="column" fxFlex="10" fxLayoutAlign="start end" (click)="deleteImage($event, file.attachment_id)">
            <img class="cp" src="../../../../assets/image/svg/cross.svg" height="6px">
          </div>
        </div>

      </div>
    </div>
  </div>
  <div fxLayout="row" class="add-more cp" (click)="browse()" *ngIf="uploadedAttachments.length > 0">+ Add more files</div>
  <div class="box-footer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px" *ngIf="isView && showCloseButton">
    <button class="ah-btn outline" (click)="close()">Close</button>
  </div>
  <div class="box-footer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px" *ngIf="!isView || !showCloseButton">
    <!-- *ngIf="uploadedAttachments.length == 0" -->
    <button class="ah-btn outline" (click)="close()">Cancel</button>
    <button class="ah-btn solid" [ngClass]="{'disable' : !isCompleted}" [disabled]="!isCompleted"
      (click)="close(true)">Attach files</button>
  </div>

</div>

<input hidden #file type="file" (change)="onSelectFile($event)" multiple>
